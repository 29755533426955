/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Hacks from "./Hacks";
import { connect, useDispatch } from 'react-redux';
import {
  fetchHacks
} from "../../redux/audit/Actions";

function Hackerboard(props) {
  const [state, setState] = useState({
    year: "year",
    month: "month",
    category: "category",
    blockchain: "blockchain",
  });
  const [cpage, setPage] = useState(1);
  const [pagev, setPageV] = useState(0);
  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    const filteredQueries = Object.entries(state).reduce((acc, [key, value]) => {
      if (key != value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    if (isActive) {
      filteredQueries.deep_analysis = true;
    }
    filteredQueries.page = cpage;

    dispatch(fetchHacks(filteredQueries));
  }, [state, cpage, isActive]);
  useEffect(() => {
    if (props.Hacks && props.Hacks.data !== undefined) {
      setData(props.Hacks.data);
      setTotalPages(props.Hacks.total_record)
    }
    else {
      setData([]);
    }
  }, [props.Hacks]);

  const years = ["year", "2023", "2022", "2021", "2020"];
  const months = ["month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const blockchains = ["blockchain", "Algorand", "Arbitrum", "Avalanche C Chain", "BNB Chain", "Celo", "Cosmos", "Cronos", "DogeChain", "EOS", "Elrond", "Ethereum", "Fantom", "Fuse network", "Gnosis Chain", "Harmony", "ICON", "NEAR", "Optimism", "Optimistic Ethereum L2", "Polkadot", "Polygon", "Solana", "Terra"];
  const categories = ["category", "(Unknown Yet)", "Access Control Bugs", "Accidentally", "Discord Hack", "Domain hijacking", "Exit Scam", "Exploit", "Flash Loan Attacks", "Flash Loans Attack", "Governance Attack", "Logic Exploit", "Malicious DAO Proposal", "Misuse of third party Protocols", "Offchain Bug", "Orcale Service suspension", "Others", "Phishing", "Price/Oracle  Manipulation", "Price/Oracle Manipulation", "Private Key Compromised", "Re-entrancy", "Rug pull", "Seed Phrase Compromise", "Slippage", "Smart Contracts Vulnerability", "Twitter Hack", "Wallet drain", "Website Vuln", "Website Vulnerability"]

  const handleQueryChange = (event) => {
    const { name, value } = event.target;
    setPageV(0);
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
    setPage(1);
  };

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="text-center h3">HackerBoard</h1>
          <div className="form-check form-switch" style={{ marginLeft: "50px !important" }}>
            <label className="form-check-label mr-10" htmlFor="my-switch">
              Deep Analysis
            </label>
            <input
              className="form-check-input mr-5"
              type="checkbox"
              id="my-switch"
              checked={isActive}
              onChange={toggle}
            />
          </div>
        </div>
        <div class="row">
          <div class="col-3 form-inline">
            <label className="mr-1" htmlFor="year-select">
              Year:
            </label>
            <select
              name="year"
              className="form-control mr-2"
              id="year-select"
              value={state.year}
              onChange={handleQueryChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div class="col-3 form-inline">
            <label className="mr-1" htmlFor="month-select">
              month:
            </label>
            <select
              name="month"
              className="form-control mr-2"
              id="month-select"
              value={state.month}
              onChange={handleQueryChange}
            >
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3 form-inline">
            <label className="mr-1" htmlFor="blockchain-select">
              Blockchain:
            </label>
            <select
              name="blockchain"
              className="form-control"
              id="blockchain-select"
              value={state.blockchain}
              onChange={handleQueryChange}
            >
              {blockchains.map((blockchain) => (
                <option key={blockchain} value={blockchain}>
                  {blockchain}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3 form-inline">
            <label className="mr-1" htmlFor="category-select">
              Category:
            </label>
            <select
              name="category"
              className="form-control"
              id="category-select"
              value={state.category}
              onChange={handleQueryChange}

            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <style jsx>{`
              select::-ms-expand {
                display: none;
              }

              select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image: none;
              }


          `}</style>
        </div>
      </div >
      <hr />
      <div className="app">
        {!data ? (
          <div>Loading...</div>
        ) : (
          <>
            {data && data.map((el, index) => {
              return (
                <div key={el._id}>
                  <Hacks data={el} index={index} />
                  <hr />
                </div>
              );
            })}
          </>
        )}
      </div>
      <div>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item   ${pagev == 0 ? "disabled" : ""}`}>
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() => setPageV(0)}
              >
                First
              </a>
            </li>

            <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() => setPageV((pr) => pr - 1)}
              >
                Previous
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => setPage(pagev + 1)}
              >
                {pagev + 1}
              </a>
            </li>
            {totalPages && totalPages > 10 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={() => setPage(pagev + 2)}
                >
                  {pagev + 2}
                </a>
              </li>
            )}
            {totalPages && totalPages > 20 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={() => setPage(pagev + 3)}
                >
                  {pagev + 3}
                </a>
              </li>
            )}

            <li
              className={`page-item ${Math.ceil(totalPages && totalPages / 10) == pagev + 3
                ? "disabled"
                : totalPages && totalPages < 20
                  ? "disabled"
                  : ""
                }`}
            >
              <a
                className={`page-link  `}
                href="#"
                onClick={() => setPageV((pr) => pr + 1)}
              >
                Next
              </a>
            </li>

            <li
              className={`page-item  ${Math.ceil(totalPages && totalPages / 10) === pagev + 3
                ? "disabled"
                : totalPages && totalPages < 20
                  ? "disabled"
                  : ""
                }`}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() =>
                  setPageV(Math.ceil(totalPages && totalPages / 10) - 3)
                }
              >
                Last
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>

  );
}
const mapStateToProps = (state) => ({
  Hacks: state.audit.Hacks,
});

export default connect(mapStateToProps, { fetchHacks })(Hackerboard);