import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import './SuccessToast.scss'
export const SuccessToast=({message})=>{
    return(
        <div className='toast-cont ml-3'>
            <div><i className="far fa-check-circle fa-2x color-success"></i></div>
       <div className='ml-3'>{message}</div>
        </div>
    )
}



export const ErrorToast=({message})=>{
    return(
        <div className='toast-cont ml-3'>
            <div><i className="far fa-times-circle fa-2x color-error"></i></div>
       <div className='ml-3'>{message}</div>
        </div>
    )
}


export const SuccessMessage=({message})=>{
    return(
       <div className='success_modal_body custom-shadow-box'>
            <div className='success_error_notification_image'>
            <i className="far fa-check-circle fa-7x success_icon"></i>
            </div>
            <div className='success_notification_body'>
              {message}
            </div>
          </div>
     
           
    )
}