import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import SingleEventCard from "../../components/SingleEventCard/SingleEventCard";
import EventForm from "../../components/EventForm/EventForm";
import Loader from "../../components/LoadingSpinner/Loader";
import Header from "../../components/Header/Header";

const base_url = process.env.REACT_APP_BACKEND_URL;

const EventPage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  // to set selectedEvent to a particular event help in editing a event.
  const handleEditFunctionality = (event) => {
    setSelectedEvent(event);
  };

  const scrollUpToTop = ()=>{
    document.getElementById("eventTopSection").scrollIntoView();
  }

  const {
    isLoading,
    isError,
    data: eventData,
  } = useQuery({
    queryKey: ["allEvents"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${base_url}/api/v1/erp_audit/admin/events/allEvents`
      );
      return data;
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <h1>Something went wrong...</h1>;
  }
  return (
    <section id="eventTopSection">
      <Header
        title="QuillAcademy Events"
        searchbar={false}
      />
      <EventForm
        selectedEvent={selectedEvent}
        setSelectedEventToNull={handleEditFunctionality}
      />
      {eventData.map((event) => {
        return (
          <SingleEventCard
            key={event._id}
            {...event}
            handleEditFunctionality={handleEditFunctionality}
            scrollUpToTop = {scrollUpToTop}
          />
        );
      })}
    </section>
  );
};
export default EventPage;
