import React, { useState, useEffect } from "react";
import Popup from "../../components/Popup/Popup";
import { useSelector, useDispatch } from "react-redux";
import NewRole from "./NewRole";
import { getRoleStart, deleteRoleStart } from "../../redux/audit/Actions";

function ViewAllRoles() {
    const [data, setData] = useState([]);
    const [viewPopup, setViewPopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [selectedRole, setSelectedRole] = useState('')
    const [roleData, setRoleData] = useState([])
    const [viewData, setViewData] = useState([])

    const audit = useSelector(state => state.audit);
    const { roles } = audit;
    const dispatch = useDispatch();

    useEffect(() => { dispatch(getRoleStart("")) }, []);
    useEffect(() => {
        if (roles) {
            setRoleData(roles.data);
        }
    }, [roles]);

    useEffect(() => {
        const retriveRoles = () => {
            let roles = [];
            if (roleData) {
                roleData.forEach((item) => {
                    roles.push(item.name);
                })
            }
            setData(roles);
        };
        if (roleData) retriveRoles();
    }, [roleData])

    const handleSelection = (item) => { setSelectedRole(item); setViewData(roleData.filter((data) => data.name === item)); }


    const handleView = () => { setViewPopup(!viewPopup); };
    const handleEdit = () => { setEditPopup(!editPopup); };
    const handleDelete = (item) => {
        setData(data.filter((data) => data !== item));
        const _id = roleData.filter((data) => data.name === item)[0]._id;
        dispatch(deleteRoleStart(_id));
    };

    return (
        <div className="card my-3 mx-3 py-2 px-3">
            <p className="text-xl font-bold text-center">All Roles</p>
            <ul className="pt-6">
                {data.map((item, index) => (
                    <li key={index} className='py-1 px-4'>
                        <div className="flex ">
                            <p className="text-lg mx-auto">{item}</p>
                            <div className="mx-auto">
                                <button className="btn btn-outline-primary btn-100 ml-3" onClick={() => { handleView(); handleSelection(item) }}>View</button>
                                <button className="btn btn-outline-primary btn-100 ml-3" onClick={() => { handleEdit(); handleSelection(item) }}>Edit</button>
                                <button className="btn btn-outline-danger btn-100 ml-3" onClick={() => { handleDelete(item); handleSelection(item) }}>Delete</button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {viewPopup &&
                <Popup size={"xxl"} onclose={handleView}>
                    <div>
                        <p className="text-xl font-bold text-center">View Role</p>
                        <div className="justify-center">
                            <div className="flex my-3 mx-3 py-2 px-3">
                                <p className="text-lg font-bold px-2">Role Name</p>
                                <p className="text-lg">{selectedRole}</p>
                            </div>
                            <div>
                                {viewData && <div className="card my-3 mx-3 py-1 px-3">
                                    <p className="text-lg font-bold px-2 pb-1">Permissions</p>
                                    <table>
                                        <tr>
                                            <td className="font-bold">Name</td>
                                            <td className="font-bold">Read</td>
                                            <td className="font-bold">Write</td>
                                            <td className="font-bold">Delete</td>
                                        </tr>
                                        {Object.keys(viewData[0].permissions).map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}) {item}</td>
                                                    <td>{viewData[0].permissions[item].read.toString()}</td>
                                                    <td>{viewData[0].permissions[item].write.toString()}</td>
                                                    <td>{viewData[0].permissions[item].delete.toString()}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </Popup>
            }
            {editPopup &&
                <Popup size={"xxl"} onclose={handleEdit}>
                    <div className="card">
                        <p className="text-xl font-bold text-center">Edit Role</p>
                        <div className="card flex justify-center">
                            <div className="flex">
                                <p className="text-lg font-bold pl-3 pr-2">Role Name: </p>
                                <p className="text-lg">{selectedRole}</p>
                            </div>
                            <NewRole editRole rollname={selectedRole} rollID={roleData.filter((data) => data.name === selectedRole)[0]._id} permissions={roleData.filter((data) => data.name === selectedRole)[0].permissions} />
                        </div>
                    </div>
                </Popup>
            }
        </div>
    );
}

export default ViewAllRoles;