import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import StaticDataCard from "../../components/StaticDataCard/StaticDataCard";


function StaticData() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);


  const toggleNewStaticData = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Static Data Management"
        btntitle="New Asset"
        newbtnclick={toggleNewStaticData}
        isnew={isNew}
        type={'static'}
        searchbar={false}
      />
      <div className="content-scroll">
        {/* {isNew && (
          <StaticDataCard isnew={isNew} onclose={toggleNewStaticData} />
        )} */}
        {Array.from({ length: 1 }).map((item, i) => (
          <StaticDataCard key={i} onedit={handleEdit} />
        ))}
      </div>
      {/* {showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <StaticDataCard onedit={handleEdit} showpopup={showPopup} />
        </Popup>
      )} */}
    </>
  );
}

export default StaticData;
