import React from 'react'
import {connect, useDispatch} from 'react-redux'
import Footer from "./components/Footer/Footer";
import {useLocation} from 'react-router-dom'
import Sidebar from "./components/Sidebar/Sidebar";
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify';
import Routes from "./routes/Routes";
import {logout} from './redux/audit/Actions'
import {SuccessToast} from './components/ToastMessage/SuccessToast';

function App(props) {
  const {auditloading} = props.audit;
  let location = useLocation();
  const dispatch = useDispatch();
  ///axios interceptors-----------for handlings error---
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {

      //Unable to get access token.
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data &&
        ((error.response.data.error &&
            error.response.data.error === 'invalid_token') ||
          (error.response.data.message === 'Unauthorised user.' ||
            error.response.data.message === 'Unknown device.' || error.response.data.message === 'Invalid or expired token')
        )
      ) {
        // localStorage.getItem('access_token') && history.replace('/exchange/signin')
        localStorage.getItem('token') &&
        toast.success(<SuccessToast message={'Logout Successfully.'}/>);
        localStorage.clear()
      }

      return Promise.reject(error);
    },
  );

  //to pass loading as a props add all loading here----
  const loadingprops = {
    auditloading
  }
  return (
    <div className="app-container">
      <ToastContainer limit={2}/>
      {
        localStorage.getItem('token') && !location.pathname.includes('certificate') && <div className="sidebar">
          <Sidebar/>
        </div>
      }
      <div className="tab-content">
        <div className="content">
          <Routes {...loadingprops} />
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  audit: state.audit
})
export default connect(mapStateToProps)(App);
