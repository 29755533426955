import { takeLatest, put, all, call } from "redux-saga/effects";
import { AuditApis } from "../Api";
import { auditActionTypes } from "./Types";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
import {
  loginSuccess,
  loginFailure,
  fetchAuditSuccess,
  fetchAuditFailure,
  addAuditSuccess,
  addAuditFailure,
  deleteAuditSuccess,
  deleteAuditFailure,
  updateAuditSuccess,
  updateAuditFailure,
  uploadToIPFSSuccess,
  uploadToIPFSFailure,
  uploadLogoSuccess,
  uploadLogoFailure,
  uploadCertificateSuccess,
  uploadCertificateFailure,
  uploadStaticSuccess,
  uploadHackImgSuccess,
  UploadHackImgFailue,
  uploadStaticFailure,
  viewSubadminSuccess,
  viewSubadminFailure,
  subadminPermissionFailure,
  subadminPermissionSuccess,
  createSubadminFailure,
  createSubadminSuccess,
  searchAuditSuccess,
  logoutFailure,
  logoutSuccess,
  searchAuditFailure,
  deleteSubAdminFailure,
  deleteSubAdminSuccess,
  quoteListingSuccess,
  quoteListingFailure,
  auditListingSuccess,
  auditListingFailure,
  deleteAuditQuoteSuccess,
  deleteAuditQuoteFailure,
  getApikeySuccess,
  getApikeyFailure,
  getPublicApikeySuccess,
  getPublicApikeyFailure,
  generateApikeySuccess,
  generateApikeyFailure,
  generatePublicApikeySuccess,
  generatePublicApikeyFailure,
  updateApikeySuccess,
  updateApikeyFailure,
  updatePublicApikeySuccess,
  updatePublicApikeyFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  deleteQuoteSuccess,
  deleteQuoteFailure,
  countLinesLinkSuccess,
  countLinesLinkFailure,
  countLinesInFileSuccess,
  countLinesInFileFailure,
  countLinesTextSuccess,
  countLinesTextFailure,
  createRoleSuccess,
  createRoleFailure,
  getRoleSuccess,
  getRoleFailure,
  deleteRoleSuccess,
  deleteRoleFailure,
  fetchHacksSuccess,
  fetchHacksFailure,
} from "../audit/Actions";

// login---
export function* loginStart({ payload }) {
  try {
    const response = yield axios({
      method: "post",
      url: AuditApis.LOGIN,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data && data.authToken !== undefined) {
      localStorage.setItem("token", data.authToken);
      const { email, adminType, permissions } = data.admin_details;
      console.log("data", data);
      localStorage.setItem("adminName", email);
      localStorage.setItem("adminType", adminType);
      localStorage.setItem("permissions", JSON.stringify(permissions));
      yield toast.success(<SuccessToast message={"Login Successful"} />);
      yield put(loginSuccess(data));
    } else {
      yield toast.error(<ErrorToast message={"Unauthorized access blocked"} />);
      yield put(
        loginFailure({
          response: { data: "No token received: Unauthorised access blocked" },
        })
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(loginFailure(error));
  }
}

export function* login() {
  yield takeLatest(auditActionTypes.LOGIN_START, loginStart);
}

// logout-------
export function* logoutStart() {
  try {
    const response = yield axios({
      method: "get",
      url: AuditApis.LOGOUT,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;
    if (data) {
      localStorage.clear();
      yield toast.success(<SuccessToast message={"Logout Successful"} />);
      yield put(logoutSuccess(data));
    }
    window.location.reload();
  } catch (error) {
    localStorage.clear();
    yield toast.success(<SuccessToast message={"Logout Successful"} />);
    //  if (error && error.response && error.response.data) {
    //    yield toast.error(<ErrorToast message={error.response.data.message} />);
    //  }
    yield put(logoutFailure(error));
  }
}

export function* logout() {
  yield takeLatest(auditActionTypes.LOGOUT, logoutStart);
}

//fetch-----
export function* fetchAuditStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: AuditApis.GET_AUDITS,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(
        <SuccessToast message={"Data Fetched Successfully"} />
      );
      yield put(fetchAuditSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(fetchAuditFailure(error));
  }
}

export function* fetchAudit() {
  yield takeLatest(auditActionTypes.FETCH_AUDIT_START, fetchAuditStart);
}

//add---------
export function* addAuditStart({ payload }) {
  try {
    const response = yield axios({
      method: "POST",
      url: AuditApis.ADD_AUDITS,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });

    const { data } = response;
    if (data) {
      yield fetchAuditStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(addAuditSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(addAuditFailure(error));
  }
}

export function* addAudit() {
  yield takeLatest(auditActionTypes.ADD_AUDIT_START, addAuditStart);
}

//delete----
export function* deleteAuditStart({ payload }) {
  try {
    const response = yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_AUDITS}/${payload}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });

    const { data } = response;
    if (data) {
      yield fetchAuditStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(deleteAuditSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(deleteAuditFailure(error));
  }
}

export function* deleteAudit() {
  yield takeLatest(auditActionTypes.DELETE_AUDIT_START, deleteAuditStart);
}

//update----
export function* updateAuditStart({ payload }) {
  try {
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPDATE_AUDITS}/${payload.id}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;
    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(updateAuditSuccess(data));
      yield fetchAuditStart({});
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(updateAuditFailure(error));
  }
}

export function* updateAudit() {
  yield takeLatest(auditActionTypes.UPDATE_AUDIT_START, updateAuditStart);
}

export function* uploadToIPFSStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.UPLOAD_TO_IPFS}?id=${payload.id}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;
    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(uploadToIPFSSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(uploadToIPFSFailure(error));
  }
}

export function* uploadToIPFS() {
  yield takeLatest(auditActionTypes.UPLOAD_TO_IPFS_START, uploadToIPFSStart);
}

//upload logo----
export function* uploadLogoStart({ payload }) {
  try {
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPLOAD_LOGO}/${payload.id}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;
    if (data) {
      yield fetchAuditStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(uploadLogoSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(uploadLogoFailure(error));
  }
}

export function* uploadLogo() {
  yield takeLatest(auditActionTypes.UPLOAD_LOGO_START, uploadLogoStart);
}

//upload certificate----
export function* uploadCertificateStart({ payload }) {
  try {
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPLOAD_CERTIFICATE}/${payload.id}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;
    if (data) {
      yield fetchAuditStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(uploadCertificateSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(uploadCertificateFailure(error));
  }
}

export function* uploadCertificate() {
  yield takeLatest(
    auditActionTypes.UPLOAD_CERTIFICATE_START,
    uploadCertificateStart
  );
}

//upload static data----
export function* uploadStaticStart({ payload }) {
  console.log(payload);
  try {
    const response = yield axios({
      method: "put",
      url: `${payload.type == "audit"
        ? AuditApis.STATIC_UPLOAD_AUDIT
        : AuditApis.STATIC_UPLOAD_QUILL
        }`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;
    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(uploadStaticSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(uploadStaticFailure(error));
  }
}

export function* uploadStatic() {
  yield takeLatest(auditActionTypes.UPLOAD_STATIC_START, uploadStaticStart);
}

export function* uploadHackImg({ payload }) {
  try {
    const response = yield axios({
      method: "put",
      url: `${payload.type == "audit"
        ? AuditApis.STATIC_UPLOAD_AUDIT
        : AuditApis.STATIC_UPLOAD_QUILL
        }`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;
    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(uploadHackImgSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(UploadHackImgFailue(error));
  }
}

export function* watchHackImg() {
  yield takeLatest(auditActionTypes.UPLOAD_HACK_IMG, uploadHackImg);
}



//create admin---
export function* createSubadminStart({ payload }) {
  try {
    const response = yield axios({
      method: "post",
      url: `${AuditApis.CREATE_SUBADMIN}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data) {
      yield viewSubadminStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(createSubadminSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(createSubadminFailure(error));
  }
}

export function* createSubadmin() {
  yield takeLatest(auditActionTypes.CREATE_SUBADMIN_START, createSubadminStart);
}

//create admin---
export function* deleteSubadminStart({ payload }) {
  try {
    const response = yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_SUBADMIN}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data) {
      yield viewSubadminStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(deleteSubAdminSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(deleteSubAdminFailure(error));
  }
}

export function* deleteSubadmin() {
  yield takeLatest(auditActionTypes.DELETE_SUBADMIN_START, deleteSubadminStart);
}

//upload subadminPermission ---
export function* subadminPermissionStart({ payload }) {
  try {
    const response = yield axios({
      method: "post",
      url: `${AuditApis.SUBADMIN_PERMISSION}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data) {
      yield viewSubadminStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(subadminPermissionSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(subadminPermissionFailure(error));
  }
}

export function* subadminPermission() {
  yield takeLatest(
    auditActionTypes.SUBADMIN_PERMISSION_START,
    subadminPermissionStart
  );
}

//view admin data----
export function* viewSubadminStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.VIEW_SUBADMIN}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;
    if (data) {
      yield put(viewSubadminSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(viewSubadminFailure(error));
  }
}

export function* viewSubadmin() {
  yield takeLatest(auditActionTypes.VIEW_SUBADMIN_START, viewSubadminStart);
}

//searchAudit data----
export function* searchAuditStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.SEARCH_AUDIT}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;
    if (data) {
      yield put(searchAuditSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(searchAuditFailure(error));
  }
}

export function* searchAudit() {
  yield takeLatest(auditActionTypes.SEARCH_AUDIT_START, searchAuditStart);
}

//searchAudit data----
export function* quoteListingStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.QUOTE_LISTING}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;
    if (data) {
      yield put(quoteListingSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(quoteListingFailure(error));
  }
}

export function* quoteListing() {
  yield takeLatest(auditActionTypes.QUOTE_LISTING_START, quoteListingStart);
}

//delete----
export function* deleteQuoteStart({ payload }) {
  try {
    const response = yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_QUOTE_LISTING}${payload}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });

    const { data } = response;
    if (data) {
      yield quoteListingStart({});
      yield toast.success(<SuccessToast message={data.message} />);
      yield put(deleteQuoteSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(deleteQuoteFailure(error));
  }
}

export function* deleteQuote() {
  yield takeLatest(auditActionTypes.DELETE_QUOTE_START, deleteQuoteStart);
}

//audit Lising data----
export function* auditListingStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.AUDIT_LISTING}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;
    if (data) {
      yield put(auditListingSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(auditListingFailure(error));
  }
}

export function* auditListing() {
  yield takeLatest(auditActionTypes.AUDIT_LISTING_START, auditListingStart);
}

//delete audit data----
export function* deleteAuditQuoteStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_AUDIT_LISTING}${payload}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);

      yield put(deleteAuditQuoteSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(deleteAuditQuoteFailure(error));
  }
}

export function* deleteAuditQuote() {
  yield takeLatest(
    auditActionTypes.DELETE_AUDIT_QUOTE_START,
    deleteAuditQuoteStart
  );
}

export function* getApiKeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "get",
      url: `${AuditApis.GET_API_KEYS}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;

    if (data) {
      yield put(getApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(getApikeyFailure(error));
  }
}

export function* getApiKey() {
  yield takeLatest(auditActionTypes.GET_API_KEY_START, getApiKeyStart);
}

export function* generateApiKeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "post",
      url: `${AuditApis.GENERATE_API_KEY}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);

      yield put(generateApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(generateApikeyFailure(error));
  }
}

export function* generateApiKey() {
  yield takeLatest(
    auditActionTypes.GENERATE_API_KEY_START,
    generateApiKeyStart
  );
}

export function* updateApikeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPDATE_API_KEY}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);

      yield put(updateApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(updateApikeyFailure(error));
  }
}

export function* updateApikey() {
  yield takeLatest(auditActionTypes.UPDATE_API_KEY_START, updateApikeyStart);
}

export function* getPublicApiKeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "get",
      url: `${AuditApis.GET_PUBLIC_API_KEYS}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: payload,
    });
    const { data } = response;

    if (data) {
      yield put(getPublicApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(getPublicApikeyFailure(error));
  }
}

export function* getPublicApiKey() {
  yield takeLatest(
    auditActionTypes.GET_PUBLIC_API_KEY_START,
    getPublicApiKeyStart
  );
}

export function* generatePublicApiKeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "post",
      url: `${AuditApis.GENERATE_PUBLIC_API_KEY}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);

      yield put(generatePublicApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(generatePublicApikeyFailure(error));
  }
}

export function* generatePublicApiKey() {
  yield takeLatest(
    auditActionTypes.GENERATE_PUBLIC_API_KEY_START,
    generatePublicApiKeyStart
  );
}

export function* updatePublicApikeyStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPDATE_PUBLIC_API_KEY}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);

      yield put(updatePublicApikeySuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(updatePublicApikeyFailure(error));
  }
}

export function* updatePublicApikey() {
  yield takeLatest(
    auditActionTypes.UPDATE_PUBLIC_API_KEY_START,
    updatePublicApikeyStart
  );
}

export function* countLinesLinkStart({ payload }) {
  try {
    const response = yield axios({
      method: "post",
      url: AuditApis.COUNT_LINES_LINK,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data) {
      yield toast.success(
        <SuccessToast message={"Data Fetched Successfully"} />
      );
      yield put(countLinesLinkSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(countLinesLinkFailure(error));
  }
}

export function* countLinesLink() {
  yield takeLatest(
    auditActionTypes.COUNT_LINES_LINK_START,
    countLinesLinkStart
  );
}

export function* countLinesInFileStart({ payload }) {
  try {
    var formData = new FormData();
    for (var temp of payload) {
      formData.append("upload", temp);
    }
    const response = yield axios.post(AuditApis.COUNT_LINES_IN_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { data } = response;

    if (data) {
      yield toast.success(
        <SuccessToast message={"Data Fetched Successfully"} />
      );
      yield put(countLinesInFileSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(countLinesInFileFailure(error));
  }
}

export function* countLinesInFile() {
  yield takeLatest(
    auditActionTypes.COUNT_LINES_IN_FILE_START,
    countLinesInFileStart
  );
}

export function* countLinesTextStart({ payload }) {
  try {
    const response = yield axios({
      method: "post",
      url: AuditApis.UPLOAD_CODE_COUNT_LINE,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(
        <SuccessToast message={"Data Fetched Successfully"} />
      );
      yield put(countLinesTextSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(countLinesTextFailure(error));
  }
}

export function* countLinesText() {
  yield takeLatest(
    auditActionTypes.COUNT_LINES_TEXT_START,
    countLinesTextStart
  );
}

export function* getUserDetailsStart({ }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "get",
      url: `${AuditApis.GET_DETAILS}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;

    if (data) {
      yield put(getUserDetailsSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(getUserDetailsFailure(error));
  }
}

export function* getUserDetails() {
  yield takeLatest(
    auditActionTypes.GET_USER_DETAILS_START,
    getUserDetailsStart
  );
}

export function* updatePasswordStart({ payload }) {
  try {
    // yield console.log("--------here-----", payload);
    const response = yield axios({
      method: "put",
      url: `${AuditApis.UPDATE_PASSWORD}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data) {
      yield toast.success(<SuccessToast message={data.message} />);
      yield logoutStart();
      yield put(updatePasswordSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(updatePasswordFailure(error));
  }
}

export function* updatePassword() {
  yield takeLatest(auditActionTypes.UPDATE_PASSWORD_START, updatePasswordStart);
}

//Create Role

export function* createRoleStart(extendedPayload) {
  const { rollID, ...payload } = extendedPayload.payload;
  console.log("payload", payload, rollID);
  if (rollID)
    yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_ROLE}${rollID}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: rollID,
    });
  try {
    const response = yield axios({
      method: "post",
      url: `${AuditApis.CREATE_ROLE}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    console.log("data", data);
    if (data) {
      // yield toast.success(<SuccessToast message={"Role Created Successfully"} />);
      yield put(createRoleSuccess(data));
    }
    window.location.reload();
  } catch (error) {
    console.log("error", error);
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(createRoleFailure(error));
  }
}

export function* createRole() {
  yield takeLatest(auditActionTypes.CREATE_ROLE_START, createRoleStart);
}

export function* getRoleStart({ payload }) {
  try {
    const response = yield axios({
      method: "get",
      url: `${AuditApis.GET_ROLE}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data) {
      // yield toast.success(<SuccessToast message={"Role Data Obtained Successfully"} />);
      yield put(getRoleSuccess(data));
    }
  } catch (error) {
    console.log("error", error);
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(getRoleFailure(error));
  }
}

export function* getRole() {
  yield takeLatest(auditActionTypes.GET_ROLE_START, getRoleStart);
}

export function* deleteRoleStart({ payload }) {
  try {
    const response = yield axios({
      method: "delete",
      url: `${AuditApis.DELETE_ROLE}${payload}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    console.log("data", data);
    if (data) {
      // yield toast.success(<SuccessToast message={"Role Deleted Successfully"} />);
      yield put(deleteRoleSuccess(data));
    }
  } catch (error) {
    console.log("error", error);
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(deleteRoleFailure(error));
  }
}

export function* deleteRole() {
  yield takeLatest(auditActionTypes.DELETE_ROLE_START, deleteRoleStart);
}

export function* fetchHacks({ payload }) {
  try {
    const response = yield axios.get('https://erp-api.quillhash.com/api/v1/erp_audit/hackerboard/allHacks', {
      params: payload.queries,
    });
    const { data } = response;
    if (data) {
      yield toast.success(
        <SuccessToast message={"Data Fetched Successfully"} />
      );
      yield put(fetchHacksSuccess(data));
    }
  } catch (error) {
    if (error && error.response && error.response.data) {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(fetchHacksFailure(error));
  }
}
export function* watchHacksFetch() {
  yield takeLatest(auditActionTypes.FETCH_HACKS, fetchHacks);
}


export function* auditSaga() {
  yield all([
    call(login),
    call(logout),
    call(updateAudit),
    call(uploadToIPFS),
    call(addAudit),
    call(fetchAudit),
    call(deleteAudit),
    call(uploadCertificate),
    call(uploadLogo),
    call(uploadStatic),
    call(subadminPermission),
    call(createSubadmin),
    call(deleteSubadmin),
    call(viewSubadmin),
    call(searchAudit),
    call(quoteListing),
    call(auditListing),
    call(deleteAuditQuote),
    call(updateApikey),
    call(generateApiKey),
    call(getApiKey),
    call(getPublicApiKey),
    call(generatePublicApiKey),
    call(updatePublicApikey),
    call(updatePassword),
    call(getUserDetails),
    call(deleteQuote),
    call(countLinesLink),
    call(countLinesInFile),
    call(countLinesText),
    call(createRole),
    call(getRole),
    call(deleteRole),
    call(watchHacksFetch),
    call(watchHackImg),
  ]);
}
