import React, { Fragment, useRef, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import IndividualContractContainer from "../components/ContractPriceEstimator/IndividualContractContainer";
import { useImmer } from "use-immer";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function isJSONString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function Auditor() {
    const fileUploadRef = useRef(null);
    const [blockExplorerUrl, setBlockExplorerUrl] = useState("");
    const [price, setPrice] = useState("12");
    const [githubSource, setGithubSourcePath] = useImmer({ url: "", folderPath: "contracts/", branch: "" });

    const [activeFile, setActiveFile] = useState(0);
    const [fileNameCounter, setFileNameCounter] = useState(2);
    const folderUploadRef = useRef(null);
    const [language, setLanguage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState("");
    const [openFiles, setOpenFiles] = useImmer([{
        name: 'Contract-1.sol',
        content: ''
    }]);
    const [contractPrices, setContractPrices] = useImmer([0]);
    const [contractPricesNeeded, setContractPricesNeeded] = useImmer([0]);

    const totalPrice = contractPrices.reduce((a, b) => a + b, 0);

    const languageChoices = ["Solidity", "Rust"];

    const closeTab = (index) => {
        setActiveFile(Math.min(activeFile, openFiles.length - 2));
        setOpenFiles(_openFiles => {
            _openFiles.splice(index, 1);
        });
        setContractPrices(_contractPrices => {
            _contractPrices.splice(index, 1);
        });
        setContractPricesNeeded(_contractPricesNeeded => {
            _contractPricesNeeded.splice(index, 1);
        });
    }


    return (
        <div className="m-5 max-w-7xl mx-auto w-100 grid grid-cols-12 gap-x-5 gap-y-2">
            <h1 className="text-5xl mx-auto w-100 font-bold mb-2 col-span-8 flex">
                Audit price estimator
                <OverlayTrigger placement="right"
                    overlay={
                        <Tooltip
                            className="text-xs">Indexed libraries: OpenZepplin (v3.0.0, v3.1.0, v3.2.0, v3.3, v3.4,
                            v4.0, v4.1, v4.2, v4.3, v4.4,
                            v4.5, v4.6, v4.7, v4.8), Uniswap-v2-core, Uniswap-v3-core, PancakeSwap</Tooltip>
                    }>
                    <AiOutlineInfoCircle className="text-sm" />
                </OverlayTrigger>
            </h1>
            <div className="col-span-4 flex flex-row-reverse">
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2
                                                                                                                                                                                                                                                                                                                                                                                                                                              text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2
                                                                                                                                                                                                                                                                                                                                                                                                                                              focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            {
                                languageChoices[language]
                            }
                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 mt-2 px-3 origin-top-right rounded-md bg-white shadow-lg ring-1
                                                                                                                                                                                                                                                                                                                                                                                                                                              ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                {
                                    languageChoices.map((choice, index) => (
                                        <Menu.Item key={index}>
                                            {
                                                ({ active }) => (
                                                    <button onClick={
                                                        () => setLanguage(index)
                                                    }
                                                        className={
                                                            classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full')
                                                        }>
                                                        {choice} </button>
                                                )
                                            } </Menu.Item>
                                    ))
                                } </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <Modal show={
                openModal === "import"
            }
                onHide={
                    () => setOpenModal("")
                }>
                <Modal.Header closeButton>
                    <Modal.Title>Advanced import options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center">
                        <input type="file" className="hidden"
                            onChange={
                                async (e) => {
                                    const _openFiles = [...openFiles];
                                    for (let i = 0; i < e.target.files.length; i++) {
                                        if (e.target.files[i].name.split('.').pop() !== 'sol')
                                            continue;



                                        const file = e.target.files[i];
                                        _openFiles.push({ name: file.name, content: await file.text() })
                                    }
                                    setOpenFiles(_openFiles);
                                    setActiveFile(_openFiles.length - 1);
                                    setOpenModal("");
                                }
                            }
                            directory=""
                            webkitdirectory=""
                            mozdirectory=""
                            ref={folderUploadRef} />
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={
                                () => {
                                    folderUploadRef.current.click()
                                }
                            }>
                            Upload folder with solidity code
                        </button>
                    </div>
                    <div className="w-100 flex my-2">
                        <span className="mx-auto">
                            OR
                        </span>
                    </div>

                    <div className="flex justify-center">
                        <input type="file" className="hidden"
                            onChange={
                                async (e) => {
                                    const text = await e.target.files[0].text();
                                    setOpenFiles((_openFiles) => {
                                        _openFiles.push({ name: e.target.files[0].name, content: text });
                                        setOpenModal("");
                                        setActiveFile(_openFiles.length - 1);
                                    })
                                }
                            }
                            ref={fileUploadRef} />
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={
                                () => {
                                    fileUploadRef.current.click()
                                }
                            }>
                            Upload file with solidity code
                        </button>

                    </div>
                    <div className="w-100 flex my-2">
                        <span className="mx-auto">
                            OR
                        </span>
                    </div>
                    <div className="flex justify-center flex-wrap gap-4">
                        <input type="text" className="w-full border rounded" placeholder="Enter block explorer URL... (https://etherscan.io/address/0x...)"
                            value={blockExplorerUrl}
                            onChange={
                                e => setBlockExplorerUrl(e.target.value)
                            } /> {
                            blockExplorerUrl !== "" ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap"
                                onClick={
                                    () => {
                                        const etherscanAddress = blockExplorerUrl.split('/').pop().split('#')[0];
                                        if (!blockExplorerUrl.includes('/address/')) {
                                            window.alert('Please enter the block explorer URL of a valid contract');
                                            return;
                                        }
                                        let apiUrl = "https://api.etherscan.io/";
                                        let apiKey = "6ZNJK4KCDE2TCQBK9Q9BXHX1UFBC1M2FFU";

                                        if (blockExplorerUrl.includes("goerli")) {
                                            apiUrl = "https://api-goerli.etherscan.io/";
                                        } else if (blockExplorerUrl.includes("kovan")) {
                                            apiUrl = "https://api-kovan.etherscan.io/";
                                        } else if (blockExplorerUrl.includes("rinkeby")) {
                                            apiUrl = "https://api-rinkeby.etherscan.io/";
                                        } else if (blockExplorerUrl.includes("ropsten")) {
                                            apiUrl = "https://api-ropsten.etherscan.io/";
                                        } else if (blockExplorerUrl.includes("sepolia")) {
                                            apiUrl = "https://api-sepolia.etherscan.io/";
                                        } else if (blockExplorerUrl.includes("testnet.bscscan.com")) {
                                            apiUrl = "https://api-testnet.bscscan.com/";
                                            apiKey = "YNWU5J666X9J13DJBUIM4KFUJDRKH5H5SK";
                                        } else if (blockExplorerUrl.includes("bscscan.com")) {
                                            apiUrl = "https://api.bscscan.com/";
                                            apiKey = "YNWU5J666X9J13DJBUIM4KFUJDRKH5H5SK";
                                        } else if (blockExplorerUrl.includes("mumbai.polygonscan.com")) {
                                            apiUrl = "https://api-testnet.polygonscan.com/";
                                            apiKey = "CPACRKPW8JVFN7WN32A4287T2WUKG8YXCP";
                                        } else if (blockExplorerUrl.includes("polygonscan.com")) {
                                            apiUrl = "https://api.polygonscan.com/";
                                            apiKey = "CPACRKPW8JVFN7WN32A4287T2WUKG8YXCP";
                                        }
                                        fetch(apiUrl + `api?module=contract&action=getsourcecode&address=${etherscanAddress}&apikey=${apiKey}`).then((response) => response.json()).then((data) => {
                                            const _openFiles = [...openFiles];
                                            if (isJSONString(data.result[0].SourceCode.slice(1, data.result[0].SourceCode.length - 1))) {
                                                const sourceCode = JSON.parse(data.result[0].SourceCode.slice(1, data.result[0].SourceCode.length - 1));
                                                for (const sourceName of Object.keys(sourceCode.sources)) {
                                                    _openFiles.push({ name: sourceName, content: sourceCode.sources[sourceName].content });
                                                }



                                            } else if (isJSONString(data.result[0].SourceCode)) {
                                                const sourceCode = JSON.parse(data.result[0].SourceCode);
                                                for (const sourceName of Object.keys(sourceCode)) {
                                                    _openFiles.push({ name: sourceName, content: sourceCode[sourceName].content });
                                                }
                                            }
                                            else
                                                _openFiles.push({
                                                    name: `${data.result[0].ContractName
                                                        }.sol`,
                                                    content: data.result[0].SourceCode
                                                })





                                            setOpenFiles(_openFiles);
                                            setActiveFile(_openFiles.length - 1);
                                            setFileNameCounter(fileNameCounter + 1);
                                            setOpenModal("");
                                        });
                                    }
                                }>
                                Load contract
                            </button> : null
                        }

                        <div className="w-100 flex">
                            <span className="mx-auto">
                                OR
                            </span>
                        </div>

                        <input type="text" className="w-full border rounded" placeholder="Enter .git address..."
                            value={
                                githubSource.url
                            }
                            onChange={
                                e => setGithubSourcePath(_githubSourcePath => {
                                    _githubSourcePath.url = e.target.value;
                                })
                            } />
                        <input type="text" className="w-full border rounded" placeholder="Enter contracts folder path..."
                            value={
                                githubSource.folderPath
                            }
                            onChange={
                                e => setGithubSourcePath(_githubSourcePath => {
                                    _githubSourcePath.folderPath = e.target.value;
                                })
                            } />
                        <input type="text" className="w-full border rounded" placeholder="Enter custom branch name (if needed)..."
                            value={
                                githubSource.branch
                            }
                            onChange={
                                e => setGithubSourcePath(_githubSourcePath => {
                                    _githubSourcePath.branch = e.target.value;
                                })
                            } /> {
                            githubSource.url !== "" ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap"
                                onClick={
                                    () => {
                                        let gitUrl = githubSource.url;
                                        if (!gitUrl.endsWith(".git")) {
                                            if (gitUrl.endsWith("/"))
                                                gitUrl = gitUrl.slice(0, gitUrl.length - 1);



                                            gitUrl += ".git";
                                        }
                                        const bodyFormData = new FormData();
                                        bodyFormData.append('gitUrl', gitUrl);
                                        bodyFormData.append('branch', githubSource.branch);
                                        bodyFormData.append('folderPath', githubSource.folderPath);
                                        setLoading(true);
                                        fetch(`${process.env.REACT_APP_BACKEND_URL
                                            }/api/v1/erp_audit/get-contracts-from-github`, {
                                            method: 'POST',
                                            body: bodyFormData
                                        }).then((response) => response.json()).then((data) => {
                                            setLoading(false);
                                            setOpenFiles(_openFiles => {
                                                Object.keys(data.result).forEach((contract) => {
                                                    _openFiles.push({ name: contract, content: data.result[contract] })
                                                })
                                            });
                                            setOpenModal("");
                                        }).catch((error) => {
                                            setLoading(false);
                                            console.log(error);
                                            window.alert("Error while fetching contracts from github");
                                        });
                                    }
                                }>
                                Load contract
                            </button> : null
                        } </div>
                </Modal.Body>
            </Modal>
            {
                language === 0 ? (
                    <>
                        <ul className="col-span-12 flex flex-wrap text-sm font-medium text-center text-gray-500">
                            {
                                openFiles.map((item, index) => <li className="mr-2">
                                    <button className={
                                        "h-full pl-3 pr-1 inline-block rounded-lg flex flex-wrap " + (
                                            index === activeFile ? "text-white bg-blue-600 active" : "hover:text-gray-900 hover:bg-gray-100"
                                        )
                                    }
                                        aria-current="page"
                                        onClick={
                                            () => setActiveFile(index)
                                        }>
                                        <div className="flex my-3 mr-2">
                                            {
                                                item.name
                                            } </div>
                                        {
                                            openFiles.length > 1 ? <OverlayTrigger placement="right"
                                                overlay={
                                                    <Tooltip
                                                        className="text-xs">Click here to close this code window</Tooltip>
                                                }>
                                                <button className="flex relative right-0 border-2 rounded px-1 top-0 h-fit mt-1 text-xs
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                hover:bg-red-600 hover:text-white hover:border-red-500"
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            closeTab(index);
                                                        }
                                                    }>
                                                    X
                                                </button>
                                            </OverlayTrigger> : null
                                        } </button>
                                </li>)
                            }
                            <li className="mr-2">
                                <OverlayTrigger placement="right"
                                    overlay={
                                        <Tooltip
                                            className="text-xs">Click here to open new code window</Tooltip>
                                    }>
                                    <button className="h-full inline-block py-1 px-4 rounded-lg hover:text-gray-900 hover:bg-gray-100 text-2xl" aria-current="page"
                                        onClick={
                                            () => {
                                                setOpenFiles(_openFiles => {
                                                    _openFiles.push({ name: `Contract-${fileNameCounter}.sol`, content: '' })
                                                    setActiveFile(_openFiles.length - 1);
                                                });
                                                setFileNameCounter(_fileNameCounter => _fileNameCounter + 1);
                                            }
                                        }>
                                        +
                                    </button>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        {
                            loading ? <div className="col-span-8 bg-blue-600 rounded p-2 text-white">
                                Loading, please wait...
                            </div> : null
                        }
                        <div className="col-span-8 col-start-1">
                            {
                                Array.from(Array(openFiles.length).keys()).map((e) => <div className={
                                    e === activeFile ? "" : "hidden"
                                }>
                                    <IndividualContractContainer openFiles={openFiles}
                                        setOpenFiles={setOpenFiles}
                                        fileIndex={e}
                                        setLoading={setLoading}
                                        key={e}
                                        setContractPrices={setContractPrices}
                                        setContractPricesNeeded={setContractPricesNeeded}
                                        contractPricesNeeded={contractPricesNeeded}
                                        price={price} />
                                </div>)
                            } </div>
                        <div className="col-span-4 col-start-9 row-span-1 h-full">

                            <div className="mb-5">
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip
                                            className="text-xs">Click here to add new contracts</Tooltip>
                                    }>
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          w-full"
                                        onClick={
                                            () => setOpenModal("import")
                                        }>
                                        Import/Upload
                                    </button>
                                </OverlayTrigger>
                                <div className="relative my-5">
                                    <input onChange={
                                        e => setPrice(e.target.value)
                                    }
                                        value={price}
                                        type="number"
                                        id="price"
                                        className="block px-2.5 pb-2.5 pt-4 w-full text-gray-900 rounded-lg border-1
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    peer border"
                                        placeholder=" " />
                                    <label htmlFor="price" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         peer-focus:-translate-y-4 left-1">
                                        Base price (USD)
                                    </label>
                                    <OverlayTrigger placement="right"
                                        overlay={
                                            <Tooltip
                                                className="text-xs">This is the price($) for each line of code audited</Tooltip>
                                        }>
                                        <AiOutlineInfoCircle className="text-sm absolute left-[-1rem] top-[-1rem]" />
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="my-5">
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip
                                            className="text-xs">Click here to get final price of all contracts</Tooltip>
                                    }>
                                    <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                                        onClick={
                                            () => {
                                                setContractPricesNeeded(Array(openFiles.length).fill(1));
                                            }
                                        }>
                                        Final price
                                    </button>
                                </OverlayTrigger>
                                {totalPrice}
                                USD
                            </div>
                        </div>
                    </>
                ) : <div className="col-span-12">
                    This tool is still under development for {
                        languageChoices[language]
                    }.
                </div>
            } </div>
    );
}

export default Auditor;
