import React, { useState, useEffect, useRef } from 'react';
import List from './List';

function MediaHighlights() {
    const [items, setItems] = useState([]);
    const [inputValues, setInputValues] = useState({
        title: '',
        date: '',
        name: '',
        link: '',
        image: '',
        description: '',
    });
    const [open, setOpen] = useState(false);
  
    const handleCheckboxChange = () => {
        setOpen(!open);
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setInputValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const fetchItems = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/media_highlights');
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddItem = async () => {
        try {
            const careersData = inputValues;
            careersData.open = open;
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/media_highlights/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": localStorage.getItem("token"),
                },
                body: JSON.stringify(careersData),
            });
            setInputValues({
                title: '',
                date: '',
                name: '',
                link: '',
                image: '',
                description: '',
            });
            window.location.reload();
            // Update the items state after adding or updating an item
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    return (
        <div className="input-list-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="text-center h3" style={{ marginTop: '20px'}}>Add Media Highlights To The List</h1>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ margin: '4px' }}>
                        <label htmlFor="title-input">Title:</label>
                        <input id="type-input" type="text" name="title" value={inputValues.title} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ margin: '4px' }}>
                        <label htmlFor="type-input">Date:</label>
                        <input id="type-input"  type="text" name="date" value={inputValues.date} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ margin: '4px' }}>
                        <label htmlFor="type-input">Name:</label>
                        <input id="type-input"  type="text" name="name" value={inputValues.name} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ margin: '4px' }}>
                        <label htmlFor="type-input">Link:</label>
                        <input id="type-input"  type="text" name="link" value={inputValues.link} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ margin: '4px' }}>
                        <label htmlFor="type-input">Image:</label>
                        <input id="type-input"  type="text" name="image" value={inputValues.image} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ margint: '4px' }}>
                        <label htmlFor="type-input">Description:</label>
                        <input id="type-input"  type="text" name="description" value={inputValues.description} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{margin:'4px'}}>
                        <input
                        type="checkbox"
                        checked={open}
                        onChange={handleCheckboxChange}
                        />&nbsp;
                        Open To Display
                    </div>
                    <button className="add-button" onClick={handleAddItem} style={{ margin:'4px', padding: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Add</button>
                </div>
            </div>
        <div>
        </div>
            <div className="list-container" style={{ marginTop: '20px' }}>
                <h2 className="text-center h3">Items in List</h2>
                {items.length > 0 ? (
                    <ul className="item-list">
                        {items.map((item, index) => (
                            <li key={index} className="item">
                                <List title={item.title} date={item.date} name={item.name} link={item.link} image = {item.image} description={item.description} open={item.open} _id={item._id} />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div >
                        No item in the media highlights
                    </div>
                )}
            </div>
        </div>
    );


}

export default MediaHighlights;
