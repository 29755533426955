import React, { useState, useRef } from 'react';

function List({ title: intitialTitle, type: initialType, skills: initialSkills,
    link: initialLink, open: intitialOpen, _id: initialId }) {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({ title: intitialTitle, type: initialType, skills: initialSkills, link: initialLink, open: intitialOpen });
    const inputRef = useRef(null);
    const [open, setOpen] = useState(intitialOpen);
  
    const handleCheckboxChange = () => {
        setOpen(!open);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleUpdateClick = async () => {
        try {
            const updateData = formData;
            updateData.open = open;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/careers/update/${initialId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": localStorage.getItem("token"),
                },
                body: JSON.stringify(updateData)
            });
            const updatedHeading = await response.json();
            console.log(updatedHeading)
            // Do something with the updated heading data
        } catch (error) {
            console.error(error);
        }
        setIsEditing(false);
        window.location.reload();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleDeleteClick = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/careers/delete/${initialId}`, {
                method: 'DELETE',
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                  },
            });
            if (response.status === 204) {
                // Do something after successful deletion
            }
        } catch (error) {
            console.error(error);
        }
        window.location.reload();
    };

    return (
        <div style={styles.listContainer}>
            <div style={styles.listItem}>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Title:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input }} // dynamically set width to auto
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.message}>{formData.title}</div>
                    )}
                </div>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Type:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input}} // dynamically set width to auto
                            name="type"
                            value={formData.type}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.message}>{formData.type}</div>
                    )}
                </div>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Skills:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input}} // dynamically set width to auto
                            name="skills"
                            value={formData.skills}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.message}>{formData.skills}</div>
                    )}
                </div>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Link:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input }} // dynamically set width to auto
                            name="link"
                            value={formData.link}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.message}>{formData.link}</div>
                    )}
                </div>

                {isEditing ? (
                    <div style={styles.inputContainer}>
                        <input
                            type="checkbox"
                            checked={open}
                            onChange={handleCheckboxChange}
                        />&nbsp;Open
                        <button style={styles.button} onClick={handleUpdateClick}>
                            Update
                        </button>
                    </div>
                ) : (
                    <div style={styles.inputContainer}>
                        <input type="checkbox" checked={open} readOnly/>&nbsp;Open
                        <button style={styles.button} onClick={handleEditClick}>
                            Edit
                        </button>
                        <button style={styles.button} onClick={handleDeleteClick}>
                            Delete
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = {
    listKeys: {
        fontWeight: 'bold'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    listItem: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
        padding: '2px',
        width: '90%',
        margin: '10px',
        boxSizing: 'border-box',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '40%',
        padding:'4px'
    },
    label: {
        fontWeight: 'bold',
        marginRight: '10px',
        flex: '1',
    },
    input: {
        flex: '2',
        marginRight: '10px',
        width: '80%',
    },
    message: {
        flex: '1',
        width: '60%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        marginLeft: '10px',
        padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' 
    },
};

export default List;
