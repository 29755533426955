import React from "react";

function NewsCard({ isnew = false, onclose, onedit, showpopup }) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-5">
              <div className="form-row">
                <div className="col-auto">
                  <div className="blog-img">
                    {/* <img className="img-fluid" src="/images/quillhash.png" alt="" /> */}
                  </div>
                </div>
                <div className="col d-flex flex-column align-items-start justify-content-center">
                  {isnew ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Event Name"
                    />
                  ) : (
                    <h6 className="h6 mb-0 ">Title of News</h6>
                  )}
                  <button className="btn btn-primary mt-4">
                    QuillAudits
                    <svg viewBox="0 0 16 16" height="12" className="ml-2">
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2 5l6 6 6-6"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row align-items-end mb-1">
                <div className="col-md-5">
                  <select name="" id="" className="custom-select">
                    <option value="">Product Service Page</option>
                  </select>
                </div>
                <div className="col-md-5">
                  <label htmlFor="auditreport" className="form-label">
                    Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="auditreport"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="auditreport" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="auditreport"
                  />
                </div>
              </div>
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default NewsCard;
