import React from 'react';
import { toast } from "react-toastify";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";

function List({ firstName, lastName, apiKey, projectName, projectDescription, email, socialMedia, apiUseCase, estimatedUsage, id }) {
    const generateApikey = async()=>{
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/v1/quillmonitor/apikey/generate?id=${id}`,{
                method: 'GET',
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            });
            window.location.reload();
            toast.success(<SuccessToast message="Api Key Generated Successfully" />);
        } catch (error) {
            toast.error(<ErrorToast message="Error Generating Api Key" />);
        }
    }
    const deleteApiKey = async()=>{
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/v1/quillmonitor/apikey/${id}`,{
                method: 'DELETE',
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            });
            window.location.reload();
            toast.success(<SuccessToast message="Api Key User Deleted Successfully" />);
        } catch (error) {
            toast.error(<ErrorToast message="Error Deleting Api Key" />);
        }
    }
    return (
        <div style={styles.listContainer}>
            <div style={styles.listItem}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="custom-form col-md-3">
                    <label htmlFor="firstName" className="form-label">
                        firstName
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="firstName"
                        maxLength="25"
                        value={`${firstName} ${lastName}`}
                        disabled={true}
                    />
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="ApiKey" className="form-label">
                    ApiKey
                    </label>
                    {apiKey?
                    <input
                        type="text"
                        className={`form-control`}
                        name="ApiKey"
                        maxLength="25"
                        value={apiKey}
                        disabled={true}
                    />:<button className="form-control"  maxLength="25" onClick={generateApikey} style={{ width: '240px', borderRadius: '3px', background: '#008CBA', color: 'white', }}>Generate</button>
                }
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="projectName" className="form-label">
                    projectName
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="projectName"
                        maxLength="25"
                        value={projectName}
                        disabled={true}
                    />
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="projectDescription" className="form-label">
                    projectDescription
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="projectDescription"
                        maxLength="25"
                        value={projectDescription}
                        disabled={true}
                    />
                </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="custom-form col-md-3">
                    <label htmlFor="socialMedia" className="form-label">
                    socialMedia
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="socialMedia"
                        maxLength="25"
                        value={socialMedia}
                        disabled={true}
                    />
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="email" className="form-label">
                    email
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="email"
                        maxLength="25"
                        value={email}
                        disabled={true}
                    />
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="estimatedUsage" className="form-label">
                    estimatedUsage
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="estimatedUsage"
                        maxLength="25"
                        value={estimatedUsage}
                        disabled={true}
                    />
                </div>
                <div className="custom-form col-md-3">
                    <label htmlFor="apiUseCase" className="form-label">
                    apiUseCase
                    </label>
                    <input
                        type="text"
                        className={`form-control`}
                        name="apiUseCase"
                        maxLength="25"
                        value={apiUseCase}
                        disabled={true}
                    />
                </div>

                </div>
                <button  maxLength="25" onClick={deleteApiKey} style ={{ width: '200px', marginTop: '10px', padding:"8px", borderRadius: '3px', background: '#008CBA', color: 'white', }}>Delete</button>

            </div>
        </div>
    );
}

const styles = {
    listKeys: {
        // fontWeight: 'bold',
        // paddingRight: '2px'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // width: '100%',
            boxSizing: 'border-box',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.3)',
        padding: '12px',
        // width: '100%',
        // marginTop: '5px',
        margin:"20px",
        boxSizing: 'border-box',
    },
    inputContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        // width: '100%',
        // padding:'8px'
    },
    label: {
        fontWeight: 'bold',
        marginRight: '10px',
        flex: '1',
    },
    input: {
        flex: '2',
        marginRight: '10px',
        width: '80%',
    },
    message: {
        flex: '1',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        marginLeft: '10px',
        padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' 
    },
};

export default List;
