import React from 'react'
import image from '../../assets/images/meme.png'
// /home/mayank/Desktop/mywork/work/new_erp_ui/src/assets/images/meme.png
const ImageComponent=()=>{
return(

    <img src={image} className='imag' alt="image logo"/>
)
}

export default ImageComponent
