import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { logout } from "../../redux/audit/Actions";

function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  // const logindata = useSelector((state) => state.audit.logindata);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const adminType = localStorage.getItem("adminType");

  // useEffect(() => {
  //   console.log("permissions", permissions);
  // }, []);

  const email = localStorage.getItem("adminName")
    ? localStorage.getItem("adminName")
    : "";
  const admintype = localStorage.getItem("adminType")
    ? localStorage.getItem("adminType")
    : "";

  return (
    <>
      <NavLink to="/" className="logo text-center mb-3">
        <img src="/images/logo_blue.png" alt="Logo" />
      </NavLink>
      <div className="user mb-2">
        <img src="/images/user.svg" alt="user" />
        <div>{email}</div>
        <div className="text-primary">{admintype}</div>
      </div>
      <div className="border-bottom mx-3 mb-2"></div>
      <div className="mb-2 pl-3 letter-space">MENU</div>
      <div className="nav flex-column nav-pills">
        {/* <NavLink
          to="/smartcontracts"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/at-sign.svg" alt="at" />
          Smart Contracts
        </NavLink> */}
        {permissions["audit"]?.view && <NavLink
          to="/auditreports"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/send.svg" alt="send" />
          QuillAudit Reports
        </NavLink>}

        {permissions["smart_contract"]?.view && <NavLink
          to="/analyze-contract"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/at-sign.svg" alt="at" />
          Analyze Smart Contract
        </NavLink>}

        {permissions["relay"]?.view && <NavLink
          to="/auditcontract"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/edit.svg" alt="edit" />
          Quill Relay
        </NavLink>}
        {/* <NavLink
          to="/testimonials"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/edit.svg" alt="edit" />
          Testimonials
        </NavLink>
        <NavLink to="/blogs" className="nav-link" activeClassName="active">
          <img src="/images/bin.svg" alt="bin" />
          Blogs
        </NavLink>
        <NavLink to="/clients" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="star" />
          Clients and Partners
        </NavLink> */}
        {permissions["static_data"]?.view && <NavLink to="/staticdata" className="nav-link" activeClassName="active">
          <img src="/images/chart.svg" alt="chart" />
          Static Data
        </NavLink>}

        {permissions["security"]?.view && <NavLink
          to="/security-check"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/chart.svg" alt="chart" />
          Security Check
        </NavLink>}
        {permissions["listing"]?.view && <NavLink
          to="/quotelisting"
          className="nav-link"
          activeClassName="active"
        >
          <img src="/images/bin.svg" alt="bin" />
          Quote Listing
        </NavLink>}
        {permissions["API"]?.view && <NavLink to="/apikey" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          API Key
        </NavLink>}

        {permissions["hackerboard"]?.view && <NavLink to="/hackerboard" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Hackerboard
        </NavLink>}
        {permissions["headings"]?.view && <NavLink to="/headings" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Headings
        </NavLink>}
        {permissions["media_highlights"]?.view && <NavLink to="/media" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Media Highlights
        </NavLink>}
        {
        permissions["careers"]?.view && 
        <NavLink to="/careers" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Careers
        </NavLink>
        }
        {
        permissions["docs"]?.view && 
        <NavLink to="/docs" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Qa Docs
        </NavLink>
        }
        {
        permissions["quillmonitor"]?.view && 
        <NavLink to="/quillmonitor" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          QuillMonitor
        </NavLink>
        }

        {permissions["audit_widget"]?.view && <NavLink to="/auditwidget" className="nav-link" activeClassName="active">
          <img src="/images/star.svg" alt="chart" />
          Audit Widget
        </NavLink>}
        {permissions["events"]?.view && <NavLink to="/eventpage" className="nav-link" activeClassName="active">
          <img src="/images/add-file.svg" alt="chart" />
          Events
        </NavLink>}

        {/* <NavLink to="/profile" className="nav-link" activeClassName="active">
          <img src="/images/chart.svg" alt="chart" />
          Profile
        </NavLink> */}
        {/* <NavLink to="/jobs" className="nav-link" activeClassName="active">
          <img src="/images/chart.svg" alt="chart" />
          Job Openings
        </NavLink>
        <NavLink to="/events" className="nav-link" activeClassName="active">
          <img src="/images/chart.svg" alt="chart" />
          Events
        </NavLink>
        <NavLink to="/news" className="nav-link" activeClassName="active">
          <img src="/images/chart.svg" alt="chart" />
          News
        </NavLink> */}
        <div className="px-3">
          {adminType === "SuperAdmin" ? <button
            className="btn btn-primary btn-block"
            onClick={() => history.push("/manageroles")}
          >
            Manage Roles
          </button> : null}
          <button
            className="btn btn-light btn-block"
            onClick={() => dispatch(logout())}
          >
            Log Out
          </button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
