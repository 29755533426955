import React, { useCallback } from "react";
import { statusList } from "../../utils/dropdowndata";
import { debounceApi } from "../../utils/common";

function Header({
  title,
  btntitle,
  statusSelect,
  newbtnclick,
  isnew,
  setSearch,
  search,
  filter,
  setFilter,
  apicall,
  type,
  searchbar = true,
}) {
  const delayQuery = useCallback(
    debounceApi(800, (e) => apicall && apicall(e)),
    []
  );
  const Searchfunction = (e) => {
    setSearch && setSearch(e.target.value);
    delayQuery(e.target.value);
  };

  return (
    <header className="header border-bottom">
      <div className="col-12">
        <div className="row mb-2">
          <h1 className="h3">{title}</h1>
          {
            !isnew && type !== "static" /*&& (
            <button className="btn btn-primary ml-auto" onClick={newbtnclick}>
              + {btntitle}
            </button>
          )*/
          }
        </div>
        <div className="row">
          {searchbar && (
            <div className="search">
              <input
                type="text"
                className="form-control w-auto"
                placeholder={`Search ${title}`}
                value={search}
                onChange={(e) => Searchfunction(e)}
              />
              <img
                src="/images/search.svg"
                className="form-control-icon"
                alt="search"
              />
            </div>
          )}
          {/* {statusSelect && (
            <div className="ml-3">
              <select
                class="form-control rounded"
                name="status"
                id="status"
                style={{ height: "38px", width: "100%" }}
                onChange={(e) => SearchStatusfunction(e)}
              >
                <option value="" selected>
                  Select Status
                </option>
                {statusList.map((el, i) => {
                  return <option value={`${el}`}>{el}</option>;
                })}
              </select>
            </div>
          )} */}

          {setFilter && (
            <button
              className="btn btn-filter ml-auto p-0"
              onClick={() => setFilter && setFilter(true)}
            >
              <img src="/images/filter.png" alt="filter" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
