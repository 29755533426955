import React from "react";
import { LinkElement } from "../LinkElements/LinkElement";
import { LinkText } from "../LinkElements/LinkText";

function Webinars({
  isnew = false,
  onclose,
  onedit,
  showpopup,
  title = "Title",
}) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-6">
              <div className="form-row">
                <div className="col-auto">
                  <div className="blog-img">
                    {/* <img className="img-fluid" src="/images/quillhash.png" alt="" /> */}
                  </div>
                </div>
              <div className="col d-flex flex-column justify-content-around">
           {/* 1st row---- */}
                        <div className='row'>
                        <div className="col-md-6">
                        {isnew ? (
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Event Name"
                                />
                            ) : (
                                <h6 className="h6 mb-0 ">{title}</h6>
                            )}
                        </div>
                        <div className="col-md-6">
                        <select name="" id="" className="custom-select">
                            <option value="">Start Date</option>
                        </select>
                        </div>
                        </div>
            {/* 2nd row---- */}
                        <div className='row'>
                        <div className="col-md-6">
                            <select name="" id="" className="custom-select">
                                <option value="">Type</option>
                            </select>
                            </div> 
                            <div className="col-md-6">
                            <select name="" id="" className="custom-select">
                                <option value="">End Date</option>
                            </select>
                            </div>
                        </div>
        </div>
         </div>
            </div>
            <div className="col-md-6">
              <div className='row'>
              <div className="col-md-5">
              <label htmlFor="eventtext" className="form-label">
                            Lorem Ipsum
                          </label>
              {Array.from({ length: 3 }).map((item, i) => (
               <LinkText key={i}  />
              ))}
              </div>
              <div className="col-md-7">
              <label htmlFor="eventtext" className="form-label">
                Lorem Ipsum
               </label>
              {Array.from({ length: 3 }).map((item, i) => (
               <LinkElement key={i}  />
              ))}
              </div>
              </div>
          
       
         
 </div>
 </div>
          <div className="form-group row align-items-end">
          <div className="col-md-12">
              <label htmlFor="auditreport" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
                rows="4"
              />
            </div>
           </div>
          
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default Webinars;
