import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../components/Popup/Popup";
import { updatePasswordStart } from "../../redux/audit/Actions";

export const ChangePassword = ({ handleClose }) => {
  const [newpassword, setNewPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const dispatch = useDispatch();
  const audit = useSelector((state) => state.audit);
  const { updatepasswordloading } = audit;
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { oldPassword: oldpassword, newPassword: newpassword };

    dispatch(updatePasswordStart(data));
  };

  return (
    <Popup size={"md"} onclose={handleClose}>
      <form onSubmit={handleSubmit} className="my-3">
        <h2 className="text-center mb-3">Change Password</h2>
        <div className="form-group row align-items-end justify-content-center my-3">
          <div className="col-md-8">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Old Password
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                required
                value={oldpassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end justify-content-center">
          <div className="col-md-8">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                New Password
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                required
                value={newpassword}
                onChange={(e) => setNewPassword(e.target.value)}
                // value={data.local && data.local.email}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            className="btn btn-outline-primary btn-100"
            onClick={handleClose}
          >
            Discard
          </button>
          <button className="btn btn-primary ml-3 btn-100" type="submit">
            {!updatepasswordloading ? (
              "Save"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </div>
      </form>
    </Popup>
  );
};
