export const columns = [
  {
    Header: "Created For",
    accessor: "createdFor",
  },
  // {
  //   Header: "Key Value",
  //   accessor: "keyValue",
  // },
  {
    Header: "API Key",
    accessor: "prefix",
  },
  {
    Header: "Generated By",
    accessor: "createdBy",
  },
  {
    Header: "Read Permission",
    accessor: "read",
  },
  {
    Header: "Write Permission",
    accessor: "write",
  },
  {
    Header: "Delete Permission",
    accessor: "delete",
  },
  {
    Header: "Generated On",
    accessor: "created_at",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const publicColumns = [
  {
    Header: "Created For",
    accessor: "created_for",
  },
  // {
  //   Header: "Key Value",
  //   accessor: "keyValue",
  // },
  {
    Header: "API Key",
    accessor: "api_key",
  },
  {
    Header: "Generated By",
    accessor: "created_by",
  },
  {
    Header: "Fields Visible",
    accessor: "permissions",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];