import React from "react";
import { useTable, usePagination } from "react-table";
import BTable from "react-bootstrap/Table";

import "./Table.scss";
import Loader from "../LoadingSpinner/Loader";

function Table({
  columns,
  data,
  loading,
  pageCount: controlledPageCount,
  fetchData,
  totalRecord,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <BTable striped bordered hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan="10000" style={{ height: "500px" }}>
                  <Loader />
                </td>
              ) : totalRecord ? (
                <td colSpan="10000" className="text-right">
                  Showing {page.length} of ~{totalRecord} results
                </td>
              ) : (
                <td colSpan="10000" className="text-center">
                  No Data Found
                </td>
              )}
            </tr>
          </tbody>
        </BTable>
      </div>
      <div className="pagination ">
        <button
          className="table-btn"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"First"}
        </button>{" "}
        <button
          className="table-btn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"Previous"}
        </button>{" "}
        <button
          className="table-btn"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next"}
        </button>{" "}
        <button
          className="table-btn"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {"Last"}
        </button>{" "}
        <span className="mx-2 ">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        {/* <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            min={1}
            max={pageOptions.length}
            style={{ width: "100px" }}
          />
        </span>{" "} */}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

function CustomTable({
  columns,
  data,
  fetchData,
  loading,
  pageCount,
  totalRecord,
}) {
  return (
    <Table
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      totalRecord={totalRecord}
    />
  );
}

export default CustomTable;
