import React from 'react'
import {useParams} from 'react-router-dom'
import './Certificate.scss'

const Certificate=()=>{
    let {imageurl} = useParams();
   return(
       <a href={`${decodeURIComponent(imageurl)}`} target='_blank'>
<img className='certificate' src={`${decodeURIComponent(imageurl)}`} />
       </a>
                      
    )
}

export default Certificate