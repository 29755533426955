export const auditActionTypes = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  ADD_AUDIT_START: "ADD_AUDIT_START",
  ADD_AUDIT_SUCCESS: "ADD_AUDIT_SUCCESS",
  ADD_AUDIT_FAILURE: "ADD_AUDIT_FAILURE",

  UPDATE_AUDIT_START: "UPDATE_AUDIT_START",
  UPDATE_AUDIT_SUCCESS: "UPDATE_AUDIT_SUCCESS",
  UPDATE_AUDIT_FAILURE: "UPDATE_AUDIT_FAILURE",

  UPLOAD_TO_IPFS_START: "UPLOAD_TO_IPFS_START",
  UPLOAD_TO_IPFS_SUCCESS: "UPLOAD_TO_IPFS_SUCCESS",
  UPLOAD_TO_IPFS_FAILURE: "UPLOAD_TO_IPFS_FAILURE",

  DELETE_AUDIT_START: "DELETE_AUDIT_START",
  DELETE_AUDIT_SUCCESS: "DELETE_AUDIT_SUCCESS",
  DELETE_AUDIT_FAILURE: "DELETE_AUDIT_FAILURE",

  FETCH_AUDIT_START: "FETCH_AUDIT_START",
  FETCH_AUDIT_SUCCESS: "FETCH_AUDIT_SUCCESS",
  FETCH_AUDIT_FAILURE: "FETCH_AUDIT_FAILURE",

  UPLOAD_LOGO_START: "UPLOAD_LOGO_START",
  UPLOAD_LOGO_SUCCESS: "UPLOAD_LOGO_SUCCESS",
  UPLOAD_LOGO_FAILURE: "UPLOAD_LOGO_FAILURE",

  UPLOAD_CERTIFICATE_START: "UPLOAD_CERTIFICATE_START",
  UPLOAD_CERTIFICATE_SUCCESS: "UPLOAD_CERTIFICATE_SUCCESS",
  UPLOAD_CERTIFICATE_FAILURE: "UPLOAD_CERTIFICATE_FAILURE",

  UPLOAD_STATIC_START: "UPLOAD_STATIC_START",
  UPLOAD_STATIC_SUCCESS: "UPLOAD_STATIC_SUCCESS",
  UPLOAD_STATIC_FAILURE: "UPLOAD_STATIC_FAILURE",

  CREATE_SUBADMIN_START: "CREATE_SUBADMIN_START",
  CREATE_SUBADMIN_SUCCESS: "CREATE_SUBADMIN_SUCCESS",
  CREATE_SUBADMIN_FAILURE: "CREATE_SUBADMIN_FAILURE",

  SUBADMIN_PERMISSION_START: "SUBADMIN_PERMISSION_START",
  SUBADMIN_PERMISSION_SUCCESS: "SUBADMIN_PERMISSION_SUCCESS",
  SUBADMIN_PERMISSION_FAILURE: "SUBADMIN_PERMISSION_FAILURE",

  VIEW_SUBADMIN_START: "VIEW_SUBADMIN_START",
  VIEW_SUBADMIN_SUCCESS: "VIEW_SUBADMIN_SUCCESS",
  VIEW_SUBADMIN_FAILURE: "VIEW_SUBADMIN_FAILURE",

  SEARCH_AUDIT_START: "SEARCH_AUDIT_START",
  SEARCH_AUDIT_SUCCESS: "SEARCH_AUDIT_SUCCESS",
  SEARCH_AUDIT_FAILURE: "SEARCH_AUDIT_FAILURE",

  DELETE_SUBADMIN_START: "DELETE_SUBADMIN_START",
  DELETE_SUBADMIN_SUCCESS: "DELETE_SUBADMIN_SUCCESS",
  DELETE_SUBADMIN_FAILURE: "DELETE_SUBADMIN_FAILURE",

  QUOTE_LISTING_START: "QUOTE_LISTING_START",
  QUOTE_LISTING_SUCCESS: "QUOTE_LISTING_SUCCESS",
  QUOTE_LISTING_FAILURE: "QUOTE_LISTING_FAILURE",

  AUDIT_LISTING_START: "AUDIT_LISTING_START",
  AUDIT_LISTING_SUCCESS: "AUDIT_LISTING_SUCCESS",
  AUDIT_LISTING_FAILURE: "AUDIT_LISTING_FAILURE",

  DELETE_QUOTE_START: "DELETE_QUOTE_START",
  DELETE_QUOTE_SUCCESS: "DELETE_QUOTE_SUCCESS",
  DELETE_QUOTE_FAILURE: "DELETE_QUOTE_FAILURE",

  DELETE_AUDIT_QUOTE_START: "DELETE_AUDIT_QUOTE_START",
  DELETE_AUDIT_QUOTE_SUCCESS: "DELETE_AUDIT_QUOTE_SUCCESS",
  DELETE_AUDIT_QUOTE_FAILURE: "DELETE_AUDIT_QUOTE_FAILURE",

  GET_API_KEY_START: "GET_API_KEY_START",
  GET_API_KEY_SUCCESS: "GET_API_KEY_SUCCESS",
  GET_API_KEY_FAILURE: "GET_API_KEY_FAILURE",

  UPDATE_API_KEY_START: "UPDATE_API_KEY_START",
  UPDATE_API_KEY_SUCCESS: "UPDATE_API_KEY_SUCCESS",
  UPDATE_API_KEY_FAILURE: "UPDATE_API_KEY_FAILURE",

  GENERATE_API_KEY_START: "GENERATE_API_KEY_START",
  GENERATE_API_KEY_SUCCESS: "GENERATE_API_KEY_SUCCESS",
  GENERATE_API_KEY_FAILURE: "GENERATE_API_KEY_FAILURE",

  GET_PUBLIC_API_KEY_START: "GET_PUBLIC_API_KEY_START",
  GET_PUBLIC_API_KEY_SUCCESS: "GET_PUBLIC_API_KEY_SUCCESS",
  GET_PUBLIC_API_KEY_FAILURE: "GET_PUBLIC_API_KEY_FAILURE",

  GENERATE_PUBLIC_API_KEY_START: "GENERATE_PUBLIC_API_KEY_START",
  GENERATE_PUBLIC_API_KEY_SUCCESS: "GENERATE_PUBLIC_API_KEY_SUCCESS",
  GENERATE_PUBLIC_API_KEY_FAILURE: "GENERATE_PUBLIC_API_KEY_FAILURE",

  UPDATE_PUBLIC_API_KEY_START: "UPDATE_PUBLIC_API_KEY_START",
  UPDATE_PUBLIC_API_KEY_SUCCESS: "UPDATE_PUBLIC_API_KEY_SUCCESS",
  UPDATE_PUBLIC_API_KEY_FAILURE: "UPDATE_PUBLIC_API_KEY_FAILURE",

  GET_USER_DETAILS_START: "GET_USER_DETAILS_START",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  UPDATE_PASSWORD_START: "UPDATE_PASSWORD_START",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  CLEAR_STATE_DATA: "CLEAR_STATE_DATA",

  COUNT_LINES_LINK_START: "COUNT_LINES_LINK_START",
  COUNT_LINES_LINK_SUCCESS: "COUNT_LINES_LINK_SUCCESS",
  COUNT_LINES_LINK_FAILURE: "COUNT_LINES_LINK_FAILURE",

  COUNT_LINES_IN_FILE_START: "COUNT_LINES_IN_FILE_START",
  COUNT_LINES_IN_FILE_SUCCESS: "COUNT_LINES_IN_FILE_SUCCESS",
  COUNT_LINES_IN_FILE_FAILURE: "COUNT_LINES_IN_FILE_FAILURE",

  COUNT_LINES_TEXT_START: "COUNT_LINES_TEXT_START",
  COUNT_LINES_TEXT_SUCCESS: "COUNT_LINES_TEXT_SUCCESS",
  COUNT_LINES_TEXT_FAILURE: "COUNT_LINES_TEXT_FAILURE",

  CREATE_ROLE_START: "CREATE_ROLE_START",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",

  GET_ROLE_START: "GET_ROLE_START",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

  DELETE_ROLE_START: "DELETE_ROLE_START",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",

  SET_USER_ROLE_START: "SET_USER_ROLE_START",
  SET_USER_ROLE_SUCCESS: "SET_USER_ROLE_SUCCESS",
  SET_USER_ROLE_FAILURE: "SET_USER_ROLE_FAILURE",

  FETCH_HACKS: 'FETCH_HACKS',
  FETCH_HACKS_SUCCESS: 'FETCH_HACKS_SUCCESS',
  FETCH_HACKS_FAILURE: 'FETCH_HACKS_FAILURE',

  UPLOAD_HACK_IMG: 'UPLOAD_HACK_IMG',
  UPLOAD_HACK_IMG_SUCCESS: 'UPLOAD_HACK_IMG_SUCCESS',
  UPLOAD_HACK_IMG_FAILURE: 'UPLOAD_HACK_IMG_FAILURE',
};
