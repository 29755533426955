import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { AiFillCaretDown, AiFillCaretUp, AiOutlineInfoCircle } from "react-icons/ai";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function IndividualContractContainer({
  setLoading, openFiles, setOpenFiles,
  fileIndex, setContractPrices,
  contractPricesNeeded, setContractPricesNeeded,
  price
}) {
  const [resp, setResp] = useState('');
  const [multiplierIndex, setMultiplierIndex] = useState(-1);
  const [multiplier, setMultiplier] = useState(1);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [ignoredFunctions, setIgnoredFunctions] = useState(new Set());

  const fetchPrice = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('code', openFiles[fileIndex].content);
    bodyFormData.append('price', price);
    bodyFormData.append('multiplier', multiplier);
    bodyFormData.append('ignoredFunctions', JSON.stringify(Array.from(ignoredFunctions)));
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/get-solidity-code-analysis`, {
      method: 'POST',
      body: bodyFormData
    }).then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setResp(data);
          setContractPrices(_contractPrices => {
            _contractPrices[fileIndex] = data.price
          });
          setLoading(false);
        }
        else {
          window.alert(data.message);
          setLoading(false);
        }
      }).catch((err) => {
        console.log("err is", err);
        window.alert("Error in fetching data");
      });
  }

  useEffect(() => {
    const fetchPrice = () => {
      const bodyFormData = new FormData();
      bodyFormData.append('code', openFiles[fileIndex].content);
      bodyFormData.append('price', price);
      bodyFormData.append('multiplier', multiplier);
      bodyFormData.append('ignoredFunctions', JSON.stringify(Array.from(ignoredFunctions)));
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/get-solidity-code-analysis`, {
        method: 'POST',
        body: bodyFormData
      }).then((response) => response.json())
        .then((data) => {
          setResp(data);
          setContractPrices(_contractPrices => {
            _contractPrices[fileIndex] = data.price
          });
        }).catch(() => window.alert("Error in fetching data"));
    }


    if (contractPricesNeeded[fileIndex] === 1) {
      setContractPricesNeeded(_contractPricesNeeded => {
        _contractPricesNeeded[fileIndex] = 0
      });
      fetchPrice();
    }
  }, [fileIndex, contractPricesNeeded, ignoredFunctions, multiplier,
    openFiles, price, setContractPrices, setContractPricesNeeded, setLoading]);

  const signatureMatches = new Set();

  if (resp !== '')
    resp.signatureMatches.forEach(signatureMatch => {
      signatureMatches.add(signatureMatch.name);
    });


  return (
    <div className="col-span-8 col-start-1 grid grid-cols-12 gap-x-5 gap-y-2">
      <div className="col-span-12">
        <textarea className="w-full h-[25rem] border rounded" placeholder="Write solidity code here..."
          onChange={e => {
            setOpenFiles(_openFiles => {
              _openFiles[fileIndex].content = e.target.value
            })
          }}
          value={openFiles[fileIndex].content}
        />
      </div>

      <div className="col-span-12">

      </div>

      <div className="col-span-8 flex gap-7">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={fetchPrice}
        >Submit
        </button>

        {(resp !== '' && resp.price !== undefined) ?
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip className="text-xs">
              This is the audit price for given contract only
            </Tooltip>}
          >
            <div className="flex flex-row items-center">
              Contract price: {resp.price} USD
            </div>
          </OverlayTrigger>
          : null
        }

      </div>
      <div
        className={classNames("col-span-12 flex cursor-pointer", !showAdvanced ? "" : "hidden")}
        onClick={() => setShowAdvanced(_showAdvanced => !_showAdvanced)}
      >
        Show advanced options <AiFillCaretDown className="text-lg mt-auto" />
      </div>
      <div
        className={classNames("col-span-12 flex cursor-pointer", showAdvanced ? "" : "hidden")}
        onClick={() => setShowAdvanced(_showAdvanced => !_showAdvanced)}
      >
        Hide advanced options <AiFillCaretUp className="text-lg mt-auto" />
      </div>

      {showAdvanced ?
        <>
          <div className="col-span-6 col-start-1 flex flex-row-reverse">
            <Menu as="div" className={"relative inline-block text-left " + (resp === '' ? "hidden" : "")}>
              <div>
                <Menu.Button
                  className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2
            text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2
            focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  {multiplierIndex === -1 ? "Custom..." : `${resp.to_show.matches[resp.potentialMultipliers[multiplierIndex]
                    .match_index].source}||${resp.to_show.matches[resp.potentialMultipliers[multiplierIndex].match_index]
                      .contract} (${resp.potentialMultipliers[multiplierIndex].match_tier.slug})`}
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 z-10 mt-2 px-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {resp.potentialMultipliers?.map((choice, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setMultiplierIndex(index)
                              setMultiplier(choice.match_tier.multiplier)
                              console.log()
                            }}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 w-full'
                            )}
                          >
                            {resp.to_show.matches[choice.match_index].source}||{resp.to_show.matches[choice.match_index].contract}
                            ({choice.match_tier.slug})
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setMultiplierIndex(-1)}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700 w-full'
                          )}
                        >
                          Custom...
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="col-span-6 relative">
            <div className="relative">
              <input
                onChange={e => {
                  setMultiplier(e.target.value)
                  setMultiplierIndex(-1)
                }}
                value={multiplier}
                type="number"
                id="price_multiplier"
                className="block px-2.5 pb-2.5 pt-4 w-full text-gray-900 rounded-lg border-1
               border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer border"
                placeholder=" " />
              <label htmlFor="price_multiplier"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4
               scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600
               peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2
               peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
               left-1">
                Price multiplier
              </label>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip className="text-xs">
                  Multiply price of current contract by given factor depending on complexity
                </Tooltip>}
              >
                <AiOutlineInfoCircle className="text-sm absolute right-[-1rem] top-[-1rem]" />
              </OverlayTrigger>
            </div>
          </div>
          <hr className="col-span-12" />
        </>
        : null}


      {showAdvanced && resp !== '' ?
        <div className="mt-4 col-span-12">
          <h4 className="text-xl font-bold">Mark functions to be explicitly ignored</h4>
          <p className="text-xs text-gray-700 font-bold flex"><AiOutlineInfoCircle />
            Bold function name implies function name and parameter match with standard contract
          </p>
          {resp.functionList.map((func, index) => {
            const checked = ignoredFunctions.has(func);
            return (
              <div key={index}>
                <input id="default-checkbox" type="checkbox" checked={checked}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500
                   dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                   dark:border-gray-600"
                  onClick={() => {
                    if (checked) {
                      const newIgnored = new Set(ignoredFunctions);
                      newIgnored.delete(func);
                      setIgnoredFunctions(newIgnored);
                    } else {
                      setIgnoredFunctions(new Set([...ignoredFunctions, func]));
                    }
                  }
                  }
                />
                <label htmlFor="default-checkbox"
                  className={"ml-2 " + (signatureMatches.has(func) ? "font-bold" : "")}>
                  {func}
                </label>
              </div>
            )
          })}
          <hr className="col-span-12" />

        </div> : null}

      {(resp !== '' && showAdvanced) ? <div className={"col-span-12"}>
        <h4 className="text-xl font-bold">Estimation details</h4>
        Note: Metrics are for raw source contract, while other calculations are done post processing.
        {<pre>{JSON.stringify(resp.to_show, null, 2)}</pre>}
      </div> : null}
    </div>
  )
}

