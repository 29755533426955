import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";

const base_url = process.env.REACT_APP_BACKEND_URL;

// a function to check if a given string input is empty or not
const isEmpty = (str) => {
  let ans = str.trim();
  if (ans.length > 0) {
    return false;
  }
  return true;
};

// initial state of the form
const initialState = {
  title: "",
  date: "",
  img: "",
  platform: "",
  platfromHandle: "",
  link: "",
  visible: true,
  price: 0,
};

const EventForm = ({ selectedEvent, setSelectedEventToNull }) => {
  const [event, setEvent] = useState(initialState);
  const queryClient = useQueryClient();

  // create a event
  const { mutate: createEvent } = useMutation({
    mutationFn: (eventobj) =>
      axios.post(`${base_url}/api/v1/erp_audit/admin/events/createEvent`, {
        ...eventobj,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allEvents"] });
      toast.success(<SuccessToast message="Event Created SucessFully" />);
    },
    onError: (error) => {
      toast.error(<ErrorToast message="SomeThing Went Wrong while creating" />);
    },
  });

  // edit a event
  const { mutate: updateEvent } = useMutation({
    mutationFn: (eventobj) =>
      axios.patch(
        `${base_url}/api/v1/erp_audit/admin/events/updateEvent/${eventobj._id}`,
        { ...eventobj }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allEvents"] });
      toast.success(<SuccessToast message="Event Updated Successfully" />);
    },
    onError: (error) => {
      toast.error(<ErrorToast message="Something Went Wrong while edting" />);
    },
  });

  useEffect(() => {
    if (selectedEvent) {
      setEvent(selectedEvent);
    }
  }, [selectedEvent]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      isEmpty(event.title) ||
      // isEmpty(event.date) ||
      isEmpty(event.link) ||
      isEmpty(event.img)
    ) {
      toast.error(<ErrorToast message="Some Required Fields are missing" />);
      return;
    }
    if (event.price < 0) {
      toast.error(<ErrorToast message="Price can't be negative" />);
      return;
    }
    if (selectedEvent) {
      // Update event
      updateEvent(event);
    } else {
      // Create new event
      createEvent(event);
    }
    setSelectedEventToNull(null);
    setEvent(initialState);
  };
  return (
    <form onSubmit={handleSubmitForm}>
      <div
        style={{ backgroundColor: "none" }}
        className={`card mb-3 popupcard pr-4`}
      >
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <h1 className="h3">
              {selectedEvent === null ? "Add" : "Edit"} A Event
            </h1>
          </div>
          <div className="form-group row align-items-end">
            <div className="custom-form col-md-3">
              <label htmlFor="title" className="form-label">
                Title*
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                required
                value={event.title}
                onChange={(e) => setEvent({ ...event, title: e.target.value })}
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="date" className="form-label">
                Date*
              </label>
              <input
                type="date"
                className="form-control"
                name="date"
                // required
                value={event.date || ''}
                onChange={(e) => setEvent({ ...event, date: e.target.value })}
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="image" className="form-label">
                Image URL*
              </label>
              <input
                type="text"
                className="form-control"
                name="image"
                required
                value={event.img}
                onChange={(e) => setEvent({ ...event, img: e.target.value })}
              />
            </div>
            <div className="custom-form col-md-2">
              <label htmlFor="visible" className="form-label">
                Visible
              </label>
              <select
                name="visible"
                id=""
                className="form-control"
                value={event.visible ? "True" : "False"}
                onChange={(e) => {
                  const visi = e.target.value === "True" ? true : false;
                  setEvent({ ...event, visible: visi });
                }}
              >
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>
          </div>
          <div className="form-group row align-items-end">
            <div className="custom-form col-md-3">
              <label htmlFor="platform" className="form-label">
                Platform
              </label>
              <input
                type="text"
                className="form-control"
                name="platform"
                value={event.platform}
                onChange={(e) =>
                  setEvent({ ...event, platform: e.target.value })
                }
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="platformhandle" className="form-label">
                Platform Handle
              </label>
              <input
                type="text"
                className="form-control"
                name="platformhandle"
                value={event.platfromHandle}
                onChange={(e) =>
                  setEvent({ ...event, platfromHandle: e.target.value })
                }
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="eventlink" className="form-label">
                Event Link*
              </label>

              <input
                type="text"
                className="form-control"
                name="eventlink"
                required
                value={event.link}
                onChange={(e) => setEvent({ ...event, link: e.target.value })}
              />
            </div>
            <div className="custom-form col-md-2">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <input
                type="number"
                className="form-control"
                value={event.price}
                name="price"
                onChange={(e) => {
                  let num = e.target.value - 0;
                  setEvent({ ...event, price: num });
                }}
              />
            </div>
          </div>
          <div className="form-group row align-items-end">
            <button  className="btn btn-primary mt-4">
              {selectedEvent === null ? "Add" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EventForm;
