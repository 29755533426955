import React from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";


const base_url = process.env.REACT_APP_BACKEND_URL;

function SingleEventCard({
  date,
  img,
  link,
  platform,
  platfromHandle,
  price,
  title,
  visible,
  _id,
  handleEditFunctionality,
  scrollUpToTop,
}) {

  // extracting out date month and year to show the particular date of the event 
  const dateString = new Date(date);
  const month = dateString.getMonth() + 1;
  const year = dateString.getFullYear();
  const day = dateString.getDate();

  // extracting out the just the date part as the entire date is coming in Date() format
  const extractedDate = date ? date.split("T")[0] : '';

  // creating a event which will be passed when edit button is pressed.
  const event = {
    date: extractedDate,
    img: img,
    link: link,
    platform: platform,
    platfromHandle: platfromHandle,
    price: price,
    title: title,
    visible: visible,
    _id: _id,
  };

  // deleting a event 
  const queryClient = useQueryClient();
  const { mutate: deleteEvent } = useMutation({
    mutationFn: (eventId) =>
      axios.delete(
        `${base_url}/api/v1/erp_audit/admin/events/deleteEvent/${eventId}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allEvents"] });
      toast.success(<SuccessToast message="Event Deleted SucessFully" />);
    },
    onError: () => {
      toast.error(<ErrorToast message="Error while deleting the event" />);
    },
  });


  return (
    <>
      <div className={"card mb-3"}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-5">
              <div className="form-row">
                <div className="col-auto">
                  <div className="blog-img">
                    <img className="img-fluid" src={img} alt={title} />
                  </div>
                </div>
                <div className="col d-flex flex-column align-items-start justify-content-center">
                  <h6 className="h6 mb-0 ">{title}</h6>
                  <p>{date?`${day}-${month}-${year}`:''}</p>
                  {/* edit button */}
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() =>{
                      handleEditFunctionality({ ...event });
                      scrollUpToTop();
                  }}
                  >
                    Edit
                  </button>
                  {/* delete button */}
                  <button
                    className="btn btn-danger mt-4"
                    onClick={()=>deleteEvent(_id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="Platform" className="form-label">
                Platform
              </label>
              <input
                type="text"
                className="form-control"
                name="Platform"
                value={platform}
                disabled={true}
              />
              <label htmlFor="PlatformHandle" className="form-label">
                Platform handle
              </label>
              <input
                type="text"
                className="form-control"
                name="PlatformHandle"
                value={`@${platfromHandle}`}
                disabled={true}
              />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <span>
                  <label htmlFor="Visible" className="form-label">
                    Visible
                  </label>
                  <select
                    name="Visible"
                    id=""
                    className="custom-select col-md-4"
                  >
                    <option>{visible ? "True" : "False"}</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="Price" className="form-label">
                    Price
                  </label>
                  <input
                    type="text"
                    className="col-md-4"
                    name="Price"
                    value={price === 0 ? "Free" : price}
                    disabled={true}
                  />
                </span>
              </div>
              <div className="">
                <label htmlFor="eventLink" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="eventLink"
                  value={link}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleEventCard;
