import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import EventCard from "../../components/EventCard/EventCard";
import Podcast from "../../components/Podcast/Podcast";
import Webinars from "../../components/Webinars/Webinars";
import Incubation from "../../components/Incubation/Incubation";

const tabs = [
  "Podcasts",
  "Events and Webinars",
  "Incubation and Accelator Programs",
];
function Events() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const toggleNewEvent = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  const handleTab = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <>
      <Header
        title="Events"
        btntitle="New Event"
        newbtnclick={toggleNewEvent}
        isnew={isNew}
      />
      <div className="px-md-3 pt-md-3 pb-1">
        <div className="nav nav-pills nav-fill tabs custom-shadow rounded">
          {tabs &&
            tabs.length > 0 &&
            tabs.map((tab) => (
              <button
                className={`btn nav-item nav-link ${
                  selectedTab === tab ? "active" : ""
                }`}
                key={tab}
                onClick={() => handleTab(tab)}
              >
                {tab}
              </button>
            ))}
        </div>
      </div>
      <div className="content-scroll">
        {selectedTab==tabs[0] && isNew && <Podcast isnew={isNew} onclose={toggleNewEvent} />}
        {selectedTab==tabs[0] && Array.from({ length: 3 }).map((item, i) => (
          <Podcast key={i} onedit={handleEdit} title={selectedTab} />
        ))}
         {selectedTab==tabs[1] && isNew && <Webinars isnew={isNew} onclose={toggleNewEvent} />}
         {selectedTab==tabs[1] && Array.from({ length: 3 }).map((item, i) => (
          <Webinars key={i} onedit={handleEdit} title={selectedTab} />
        ))}
          {selectedTab==tabs[2] && isNew && <Incubation isnew={isNew} onclose={toggleNewEvent} />}
         {selectedTab==tabs[2] && Array.from({ length: 3 }).map((item, i) => (
          <Incubation key={i} onedit={handleEdit} title={selectedTab} />
        ))}
      </div>
      {selectedTab==tabs[0] && showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <Podcast
            onedit={handleEdit}
            showpopup={showPopup}
            title={selectedTab}
          />
        </Popup>
      )}
        {selectedTab==tabs[1] && showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <Webinars
            onedit={handleEdit}
            showpopup={showPopup}
            title={selectedTab}
          />
        </Popup>
      )}
        {selectedTab==tabs[2] && showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <Incubation
            onedit={handleEdit}
            showpopup={showPopup}
            title={selectedTab}
          />
        </Popup>
      )}
    </>
  );
}

export default Events;
