import React, { useState, useEffect, useRef } from 'react';
import List from './List';

function Headings() {
    const [items, setItems] = useState([]);
    const [inputValues, setInputValues] = useState({
        path: '',
        message: '',
        messageLinks: {}
    });
    const [link, setLink] = useState('');
    const inputRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [applyAllPath, setApplyAllPath] = useState(false);
  
    const handleCheckboxChange = () => {
      setApplyAllPath(!applyAllPath);
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setInputValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const fetchItems = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/headings');
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLinkInputChange = (event) => {
        setLink(event.target.value);
    };

    const handleLinkSubmit = () => {
        const inputElement = inputRef.current;
        if (inputElement) {
          const selectionStart = inputElement.selectionStart;
          const selectionEnd = inputElement.selectionEnd;
          const selectedText = inputElement.value.substring(selectionStart, selectionEnd);
          const messageLink = {};
          messageLink[selectedText]=link;
          setInputValues(prevValues => ({ path: prevValues.path, message: prevValues.message,
            messageLinks: {...prevValues.messageLinks, ...messageLink} }));
        }
        setShowPopup(false);
    };

    const handleAddItem = async () => {
        try {
            const headingsData = inputValues;
            headingsData.applyAllPath = applyAllPath;
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/headings/add_heading', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": localStorage.getItem("token"),
                },
                body: JSON.stringify(headingsData),
            });
            setInputValues({ path: '', message: '', messageLinks: {} });
            window.location.reload();
            // Update the items state after adding or updating an item
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        fetchItems();
    }, []);

    return (
        <div className="input-list-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="text-center h3" style={{ marginTop: '20px'}}>Add Headings to List</h1>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '50px' }}>
                        <label htmlFor="path-input">Path:</label>
                        <input id="path-input" type="text" name="path" value={inputValues.path} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <label htmlFor="message-input">Message:</label>
                        <input id="message-input" ref={inputRef} type="text" name="message" value={inputValues.message} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <button className="add-button" onClick={handleAddItem} style={{ padding: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Add</button>
                    <div>
                        <button onClick={() => setShowPopup(true)} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Link</button>
                        {
                            showPopup && (<div className="popup">
                                <input type="text" value={link} onChange={handleLinkInputChange} />
                                <button onClick={handleLinkSubmit} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Submit</button>
                                <button onClick={() => setShowPopup(false)} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Cancel</button>
                            </div>)
                        }
                    </div>
                    <div>
                        <input
                        type="checkbox"
                        checked={applyAllPath}
                        onChange={handleCheckboxChange}
                        />&nbsp;
                        Apply All Path
                    </div>
                </div>
            </div>
        <div>
        </div>
            <div className="list-container" style={{ marginTop: '20px' }}>
                <h2 className="text-center h3">Items in List</h2>
                {items.length > 0 ? (
                    <ul className="item-list">
                        {items.map((item, index) => (
                            <li key={index} className="item">
                                <List path={item.path} message={item.message} messageLinks={item.messageLinks} applyAllPath={item.applyAllPath} _id={item._id} />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div >
                        No item in the headings
                    </div>
                )}
            </div>
        </div>
    );


}

export default Headings;
