import React, { useState, useEffect, useRef } from 'react';
import List from './List';
import { toast } from "react-toastify";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";

function ApiKeyRequests() {
    const [items, setItems] = useState([]);
    const [pagev, setPageV] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const limit = 10;

    const fetchItems = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/v1/quillmonitor/apikey?limit=${limit}&page=${page}`,{
                method: 'GET',
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            });
            const data = await response.json();
            setItems(data.data);
            setTotalRecord(data.totalRecord);

            toast.success(<SuccessToast message="Data Fetched SucessFully" />);
        } catch (error) {
            console.error(error);
            toast.error(<ErrorToast message="Error Fetching Data" />);
        }
    };

    useEffect(() => {
        fetchItems();
    }, [page]);

    return (
        <div className="input-list-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
        </div>
            <div className="list-container" style={{ marginTop: '20px' }}>
                <h2 className="text-center h3">Items in List</h2>
                {items.length > 0 ? (
                    <ul className="item-list">
                        {items.map((item, index) => (
                            <li key={index} className="item">
                                <List firstName={item.firstName|| null} lastName={item.lastName||null} apiKey={item.apiKey||null} projectName={item.projectName||null} projectDescription={item.projectDescription||null} email={item.email||null} socialMedia={item.socialMedia||null} apiUseCase={item.apiUseCase||null} estimatedUsage={item.estimatedUsage||null} id={item._id}/>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div >
                        No item in the List
                    </div>
                )}
            </div>
            <div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center" style={{ marginBottom: '1px' }}>
                        <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() => setPageV(0)}
                            >
                                First
                            </a>
                        </li>

                        <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() => setPageV((pr) => pr - 1)}
                            >
                                Previous
                            </a>
                        </li>
                        <li className="page-item">
                            <a
                                className="page-link"
                                href="#"
                                onClick={() => setPage(pagev + 1)}
                            >
                                {pagev + 1}
                            </a>
                        </li>
                        {items && totalRecord > 10 && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => setPage(pagev + 2)}
                                >
                                    {pagev + 2}
                                </a>
                            </li>
                        )}
                        {items && totalRecord > 20 && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => setPage(pagev + 3)}
                                >
                                    {pagev + 3}
                                </a>
                            </li>
                        )}

                        <li
                            className={`page-item ${Math.ceil(items && totalRecord / 10) == pagev + 3
                                    ? "disabled"
                                    : items && totalRecord < 20
                                        ? "disabled"
                                        : ""
                                }`}
                        >
                            <a
                                className={`page-link  `}
                                href="#"
                                onClick={() => setPageV((pr) => pr + 1)}
                            >
                                Next
                            </a>
                        </li>

                        <li
                            className={`page-item  ${Math.ceil(items && totalRecord / 10) === pagev + 3
                                    ? "disabled"
                                    : items && totalRecord < 20
                                        ? "disabled"
                                        : ""
                                }`}
                        >
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() =>
                                    setPageV(Math.ceil(items && totalRecord / 10) - 3)
                                }
                            >
                                Last
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <h5 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    marginBottom: '15px',
                    }}
                >
                    Total Count: {totalRecord}
                </h5>
            </div>
        </div>
    );


}

export default ApiKeyRequests;
