import React, { useState } from 'react';

function List({ name, company, email, twitter, linkedIn, telegram, website, city, country, status, type, rating, source }) {
    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };
    const [isHovered, setIsHovered] = useState(false);
    const [isWebsiteHovered, setIsWebsiteHovered] = useState(false);
    const [isTwitterHovered, setIsTwitterHovered] = useState(false);
    const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
    const handleWebsiteClick = () => {
        window.open(website, '_blank');
    };
    const handleTwitterClick = () => {
        window.open(twitter, '_blank');
    };
    const handleLinkedInClick = () => {
        window.open(linkedIn, '_blank');
    };
    return (
        <div style={styles.listContainer}>
            <div style={styles.listItem}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={name}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Company
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={company}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3" onClick={handleEmailClick} onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)} style={{
                            cursor: 'pointer',
                            backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
                            transition: 'background-color 0.3s',
                        }}>
                        <label htmlFor="name" className="form-label">
                            Email
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={email}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3" onMouseEnter={() => setIsTwitterHovered(true)}
                        onMouseLeave={() => setIsTwitterHovered(false)} onClick={handleTwitterClick}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            backgroundColor: isTwitterHovered ? '#f0f0f0' : 'transparent',
                            transition: 'background-color 0.3s',
                        }}>
                        <label htmlFor="name" className="form-label">
                            Twitter
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={twitter}
                            disabled={true}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="custom-form col-md-3" onMouseEnter={() => setIsWebsiteHovered(true)}
                        onMouseLeave={() => setIsWebsiteHovered(false)} onClick={handleWebsiteClick}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            backgroundColor: isWebsiteHovered ? '#f0f0f0' : 'transparent',
                            transition: 'background-color 0.3s',
                        }}>
                        <label htmlFor="name" className="form-label">
                            Website
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={website}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            City
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={city}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Country
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={country}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Status
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={status}
                            disabled={true}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="custom-form col-md-3" onMouseEnter={() => setIsLinkedinHovered(true)}
                        onMouseLeave={() => setIsLinkedinHovered(false)} onClick={handleLinkedInClick}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            backgroundColor: isLinkedinHovered ? '#f0f0f0' : 'transparent',
                            transition: 'background-color 0.3s',
                        }}>
                        <label htmlFor="name" className="form-label">
                            LinkedIn
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={linkedIn}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Telegram
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={telegram}
                            disabled={true}
                        />
                    </div>

                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Type
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={type}
                            disabled={true}
                        />
                    </div>
                    <div className="custom-form col-md-3">
                        <label htmlFor="name" className="form-label">
                            Rating
                        </label>
                        <input
                            type="text"
                            className={`form-control`}
                            name="auditreport"
                            maxLength="25"
                            value={rating}
                            disabled={true}
                        />
                    </div>
                </div>
                <h1 style={{ display: 'flex', flexDirection: 'row', padding: "5px" }}>Source : {source}</h1>
            </div>
        </div>
    );
}

const styles = {
    listKeys: {
        // fontWeight: 'bold',
        // paddingRight: '2px'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // width: '100%',
        boxSizing: 'border-box',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.3)',
        padding: '12px',
        // width: '100%',
        // marginTop: '5px',
        margin: "20px",
        boxSizing: 'border-box',
    },
    inputContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        // width: '100%',
        // padding:'8px'
    },
    label: {
        fontWeight: 'bold',
        marginRight: '10px',
        flex: '1',
    },
    input: {
        flex: '2',
        marginRight: '10px',
        width: '80%',
    },
    message: {
        flex: '1',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        marginLeft: '10px',
        padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none'
    },
};

export default List;
