import React, { useState, useEffect } from "react";
import {
  updateApikeyStart,
  generateApikeyStart,
} from "../../redux/audit/Actions";
import { useDispatch, useSelector } from "react-redux";
function ApiRoleManagement({ isnew = false, onclose, onedit, showpopup, el }) {
  const [createdFor, setCreatedFor] = useState("");
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const [deletePer, setDelete] = useState(false);
  const dispatch = useDispatch();
  const audit = useSelector((state) => state.audit);
  const { createsubadmin } = audit;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      created_for: createdFor,
      read: read,
      write,
      delete: deletePer,
    };
    if (isnew) {
      dispatch(generateApikeyStart(data));
      setTimeout(() => {
        onclose();
      }, 1000);
    } else {
      dispatch(updateApikeyStart(data));
      setTimeout(() => {
        onedit();
      }, 1000);
    }
  };

  useEffect(() => {
    if (el) {
      setCreatedFor(el.createdFor);
      setRead(el.permission.read);
      setWrite(el.permission.write);
      setDelete(el.permission.delete);
    }
  }, [el]);

  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="form-group row align-items-end">
              <div className="col-md-4">
                <div className="custom-form">
                  <label htmlFor="createdFor" className="form-label">
                    Created For
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    value={createdFor}
                    onChange={(e) => setCreatedFor(e.target.value)}
                    required
                    disabled={isnew ? false : true}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Read
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={read}
                    onClick={(e) => setRead(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Write
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={write}
                    onClick={(e) => setWrite(e.target.checked)}
                  />
                </div>
              </div>

              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Delete
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={deletePer}
                    onClick={(e) => setDelete(e.target.checked)}
                  />
                </div>
              </div>
            </div>
            {(isnew || showpopup) && (
              <div className="text-right">
                <button
                  className="btn btn-outline-primary btn-100"
                  onClick={onclose}
                >
                  Discard
                </button>
                <button className="btn btn-outline-primary ml-3 btn-100" type="submit">
                  {!createsubadmin ? (
                    "Save"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default ApiRoleManagement;
