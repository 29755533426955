import React, { useState, useRef } from 'react';

function List({ path: initialPath, message: initialMessage, messageLinks: initialMessageLinks,
    applyAllPath: initialApplyAllPath, _id: initialId }) {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({ path: initialPath, message: initialMessage, messageLinks: initialMessageLinks });
    const [link, setLink] = useState('');
    const inputRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [applyAllPath, setApplyAllPath] = useState(initialApplyAllPath);
  
    const handleCheckboxChange = () => {
      setApplyAllPath(!applyAllPath);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleUpdateClick = async () => {
        try {
            const updateData = formData;
            updateData.applyAllPath = applyAllPath;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/headings/update/${initialId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": localStorage.getItem("token"),
                },
                body: JSON.stringify(updateData)
            });
            const updatedHeading = await response.json();
            console.log(updatedHeading)
            // Do something with the updated heading data
        } catch (error) {
            console.error(error);
        }
        setIsEditing(false);
        window.location.reload();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleDeleteClick = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/headings/delete/${initialId}`, {
                method: 'DELETE',
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                  },
            });
            if (response.status === 204) {
                // Do something after successful deletion
            }
        } catch (error) {
            console.error(error);
        }
        window.location.reload();
    };

    function formattedMessage()
    {
        let headingMessage = formData.message;
        const messageLinkKeys = formData.messageLinks ? Object.keys(formData.messageLinks) : [];
        messageLinkKeys.forEach((key, index) => {
            const messageLinkKey = [key];
            const escapedKeys = messageLinkKey.map(key => key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
            const regex = new RegExp(escapedKeys.join("|"), "g");
            headingMessage = headingMessage.replace(regex, `$${index}`);
        });
        return headingMessage ? headingMessage.split(' ').map((part, index) => {
            if (part[0] === '$' && messageLinkKeys.length >= part.length-1) {
            return (
                <a key={index} href={formData.messageLinks[messageLinkKeys[part.slice(1,part.length)]]}
                 target="_blank" rel="noopener noreferrer">
                {messageLinkKeys[part.slice(1,part.length)]}&nbsp;
                </a>
            );
            } else {
            return <span key={index}>{part} &nbsp;</span>;
            }
        }): <></>;
    }

    const handleLinkInputChange = (event) => {
        setLink(event.target.value);
    };

    const handleLinkSubmit = () => {
        const inputElement = inputRef.current;
        if (inputElement) {
            const selectionStart = inputElement.selectionStart;
            const selectionEnd = inputElement.selectionEnd;
            const selectedText = inputElement.value.substring(selectionStart, selectionEnd);
            const messageLink = {};
            messageLink[selectedText]=link;
            setFormData(prevValues => ({ path: prevValues.path, message: prevValues.message,
            messageLinks: {...prevValues.messageLinks, ...messageLink} }));
        }
        setShowPopup(false);
      };    

    return (
        <div style={styles.listContainer}>
            <div style={styles.listItem}>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Path:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input, width: 'auto' }} // dynamically set width to auto
                            name="path"
                            value={formData.path}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.path}>{formData.path}</div>
                    )}
                </div>
                <div style={styles.inputContainer}>
                    <h3 style={styles.listKeys}>Message:&nbsp;</h3>
                    {isEditing ? (
                        <input
                            style={{ ...styles.input, width: 'auto' }} // dynamically set width to auto
                            name="message"
                            value={formData.message}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    ) : (
                        <div style={styles.message}>{formattedMessage()}</div>
                    )}
                </div>
                {isEditing ? (
                    <button style={styles.button} onClick={handleUpdateClick}>
                        Update
                    </button>
                ) : (
                    <button style={styles.button} onClick={handleEditClick}>
                        Edit
                    </button>
                )}
                {!isEditing && <button style={styles.button} onClick={handleDeleteClick}>
                    Delete
                </button>}
                {
                    isEditing &&
                    <div style={styles.inputContainer}>
                        <button onClick={() => setShowPopup(true)} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Link</button>
                        <input
                            type="checkbox"
                            checked={applyAllPath}
                            onChange={handleCheckboxChange}
                        />
                        <span> Apply All Path</span>
                        {
                            showPopup && (<div className="popup">
                                <input type="text" value={link} onChange={handleLinkInputChange} />
                                <button onClick={handleLinkSubmit} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Submit</button>
                                <button onClick={() => setShowPopup(false)} style={{ padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Cancel</button>
                            </div>)
                        }
                    </div>
                }

            </div>
        </div>
    );
}

const styles = {
    listKeys: {
        fontWeight: 'bold'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '1000px',
    },
    listItem: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        width: '90%',
        margin: '10px',
        boxSizing: 'border-box',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '60%',
    },
    label: {
        fontWeight: 'bold',
        marginRight: '10px',
        flex: '1',
    },
    input: {
        flex: '2',
        marginRight: '10px',
        width: '100%',
    },
    path: {
        flex: '1',
        width: '40%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    message: {
        flex: '1',
        width: '60%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        marginLeft: '10px',
        padding: '5px', margin: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' 
    },
};

export default List;
