import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {
  addAuditStart,
  deleteAuditStart,
  updateAuditStart,
  uploadLogoStart,
  uploadCertificateStart,
  uploadToIPFSStart,
  clearStateData,
} from "../../redux/audit/Actions";
import { AuditApis } from "../../redux/Api";
import Popup from "../Popup/Popup";
import { copyToClipboard /*isValidNetwork*/ } from "../../utils/common";
import {
  isValidAddress,
  isValidString,
  isValidUri,
  isValidNote,
  isValidArray,
} from "../../utils/common";

const STATIC_JS_URL = `https://erp-api.quillhash.com/widgetJS`;

let validAddress = true;
let validName = false;
let validAuditLink = false;
let validSourceCode = false;

let validAuditedBy = false;
let validOverview = false;
let validScopeOfAudit = false;
let validClientUrl = false;
//let validNetwork = false; //
let validStatus = true;
let validFixedIn = false;
let validTestnet = false;
let validMainnet = false;

function ReportCard(props) {
  const {
    isnew = false,
    onclose,
    onedit,
    showpopup,
    el,
    setCurrentReport,
    index,
    blockchains,
    networks,
    tags,
    statusList,
  } = props;
  const [name, setName] = useState("");
  const [address, setAddress] = useState([{ contractAddress: "" }]); //change
  const [auditlink, setAuditLink] = useState("");
  const [sourcecode, setSourceCode] = useState(""); //change
  const [tag, setTag] = useState([]);
  const [auditedby, setAuditedBy] = useState("QuillAudits");
  const [featured, setFeatured] = useState(false);
  const [status, setStatus] = useState("");
  const [IPFSlink, setIPFSlink] = useState("");
  //const [network, setNetwork] = useState(""); //change

  const [overview, setOverview] = useState(""); //description
  const [scopeOfAudit, setScopeOfAudit] = useState(""); //description
  const [fixedIn, setFixedIn] = useState("");
  const [testnet, setTestnet] = useState("");
  const [mainnet, setMainnet] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [clienturl, setClientUrl] = useState("");
  const [blockchain, setBlockChain] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [popup, setOpenPopup] = useState(false);
  const [text, setText] = useState(false);
  const [uploadlogo, setUploadLogo] = useState("");
  const [uploadcertificate, setUploadCertificate] = useState("");
  const [preview, setPreviewFile] = useState("");
  const [certificate, setCertificate] = useState("");
  const dispatch = useDispatch();
  const auditadding = useSelector((state) => state.audit.auditadding);
  const history = useHistory();
  const [addresstext, setAddressText] = useState(false);
  const audit = useSelector((state) => state.audit);
  const { logoloading, certificateloading } = audit;
  const [image, setImage] = useState();
  const [addresserror, setAddressError] = useState(false);
  const [nameerror, setNameError] = useState(false);
  const [auditlinkerror, setAuditLinkError] = useState(false); //change
  const [fixedInError, setFixedInError] = useState(false);
  const [testnetError, setTestnetError] = useState(false);
  const [mainnetError, setMainnetError] = useState(false);
  const [sourcecodeerror, setSourceCodeError] = useState(false); //change

  const [auditedbyerror, setAuditedByError] = useState(false);
  const [overviewerror, setOverviewError] = useState(false);
  const [scopeOfAuditError, setScopeOfAuditError] = useState(false);
  const [clienturlerror, setClientUrlError] = useState(false);
  //const [networkerror, setNetworkError] = useState(false); //change
  const [blockchainerror, setBlockChainError] = useState(false);
  const [tagerror, setTagError] = useState(false);
  const [statuserror, setStatusError] = useState(false);
  const [contactaddress, setContactAddress] = useState([]);

  const handledelete = async () => {
    //await dispatch(deleteAuditStart(el._id));
    await onedit();
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...address];
    const arr = [];
    for (var i of list) {
      arr.push({ contractAddress: value });
    }
    //list[index][name] = value;
    setAddress(arr);
    validAddress = true;
  };

  const handleRemoveClick = (index) => {
    const list = [...address];
    list.splice(index, 1);
    setAddress(list);
    validAddress = true;
  };

  const handleAddClick = () => {
    //setAddress([...address, { contractAddress: "" }]);
  };

  const handleUploadIPFS = async () => {
    document.getElementById("ipfsbtn").disabled = true;
    const payload = {
      id: el.page_id,
    };
    await dispatch(uploadToIPFSStart(payload));
  };

  const handleSave = async () => {
    //address.map((e) => {
    //  if (e.contractAddress && e.contractAddress.length == 0) {
    //    validAddress = true;
    //  }
    //});

    const data = {
      name: name && name.toLowerCase(),
      blockchain: blockchain,
      tags: tag,
      featured: featured,
      status: status || props.statusList[0],
      start_date: startDate && moment.utc(startDate).format(),
      end_date: endDate && moment.utc(endDate).format(),
      auditedBy: auditedby,
      //network: network || props.networks[0],
      overview: overview,
      scope_of_audit: scopeOfAudit,
      report: auditlink,
      testnet: testnet,
      mainnet: mainnet,
      fixed_in: fixedIn,
      source_code: sourcecode,
      auditReportLink: auditlink,
      /*address: address 
      auditReportTitle: "hello",
      auditReportDescription: "hello",
      auditReportCoverImage: "hello",*/
      client_website: clienturl,
    };
    //if (address && address[0].contractAddress) {
    //  data.address = address;
    //}

    if (showpopup) {
      //validNetwork = isValidNetwork(network);
      validClientUrl = isValidUri(clienturl);
      validOverview = isValidNote(overview);
      validScopeOfAudit = isValidNote(scopeOfAudit);
      validAuditLink = isValidUri(auditlink);
      validSourceCode = isValidUri(sourcecode);
      validFixedIn = isValidUri(fixedIn);
      validAuditedBy = isValidString(auditedby);
      validName = isValidString(name);
    }

    if (validName) {
      await setNameError(false);
    } else {
      await setNameError(true);
    }

    if (validAuditLink) {
      await setAuditLinkError(false);
    } else {
      await setAuditLinkError(true);
    }
    if (validFixedIn) {
      await setFixedInError(false);
    } else {
      await setFixedInError(true);
    }
    if (validTestnet) {
      await setTestnetError(false);
    } else {
      await setTestnetError(true);
    }
    if (validMainnet) {
      await setMainnetError(false);
    } else {
      await setMainnetError(true);
    }
    if (validSourceCode) {
      await setSourceCodeError(false);
    } else {
      await setSourceCodeError(true);
    }

    if (validAuditedBy) {
      await setAuditedByError(false);
    } else {
      await setAuditedByError(true);
    }

    if (validOverview) {
      await setOverviewError(false);
    } else {
      await setOverviewError(true);
    }

    if (validScopeOfAudit) {
      await setScopeOfAuditError(false);
    } else {
      await setScopeOfAuditError(true);
    }
    if (validStatus) {
      await setStatusError(false);
    } else {
      await setStatusError(true);
    }

    if (validClientUrl) {
      await setClientUrlError(false);
    } else {
      await setClientUrlError(true);
    }

    //if (validNetwork) {
    //  await setNetworkError(false);
    //} else {
    //  await setNetworkError(true);
    //}
    // remove validation contract

    if (validAddress) {
      await setAddressError(false);
    } else {
      await setAddressError(true);
    }

    if (isnew) {
      if (
        //validNetwork &&
        validClientUrl &&
        validOverview &&
        validScopeOfAudit &&
        validAuditLink &&
        validSourceCode &&
        validAuditedBy &&
        validName &&
        validAddress &&
        validStatus &&
        validFixedIn &&
        validTestnet &&
        validMainnet
      ) {
        await dispatch(addAuditStart(data));
      }
      // await onclose()
    } else {
      const payload = {
        id: el.page_id,
        data: data,
      };
      if (
        //validNetwork &&
        validClientUrl &&
        validOverview &&
        validScopeOfAudit &&
        validAuditLink &&
        validSourceCode &&
        validAuditedBy &&
        validName &&
        validAddress &&
        validStatus &&
        validFixedIn &&
        validTestnet &&
        validMainnet
      ) {
        await onedit();
      }
      if (
        //validNetwork &&
        validClientUrl &&
        validOverview &&
        validScopeOfAudit &&
        validAuditLink &&
        validSourceCode &&
        validAuditedBy &&
        validName &&
        validAddress &&
        validStatus &&
        validFixedIn &&
        validTestnet &&
        validMainnet
      ) {
        await dispatch(updateAuditStart(payload));
      }
    }
    // onclose()
  };

  //---------copy address and text-----------------------
  function copyText() {
    setText(true);
    var timer = setTimeout(() => {
      setText(false);
    }, 1500);
  }

  function copyAddress() {
    setAddressText(true);
    var timer = setTimeout(() => {
      setAddressText(false);
    }, 1500);
  }
  //--------------------------------------------------------

  // useEffect(()=>{
  // if(addedAudit){
  //   onclose && onclose();
  //   dispatch(clearStateData())
  // }

  // },[addedAudit])
  useEffect(() => {
    const blockchainList = [],
      tagsList = [];
    for (let d of el.blockchain) {
      blockchainList.push({ name: d });
    }
    for (let d of el.tags) {
      tagsList.push({ name: d });
    }
    //setNetwork(el ? el.network : "Ethereum Mainnet");
    setName(el && el.name);
    if (el && el.address && el.address.length) {
      setAddress(el ? el.address : [{ contractAddress: "" }]);
    }

    setAuditLink(el ? el.auditReportGitLink : "");
    // adding new sourcecode
    setSourceCode(el ? el.source_code : "");
    setCreatedAt(el ? el.created_at : "");
    setTag(el ? tagsList : null);
    setAuditedBy("QuillAudits");
    setFeatured(el ? el.featured : false);
    setStatus(el ? el.status : "todo");
    setOverview(el ? el.overview : "");
    setScopeOfAudit(el ? el.scope_of_audit : "");
    setClientUrl(el ? el.clientWebsiteUrl : "");
    setFixedIn(el ? el.fixed_in : null);
    setBlockChain(el ? blockchainList : [{ name: "Ethereum", id: 1 }]);
    setStartDate(el ? new Date(el.start_date) : null);
    setEndDate(el ? new Date(el.end_date) : null);
    setPreviewFile(el ? el.project_logo : "");
    setUploadLogo(el ? el.project_logo : "");
    setCertificate(el ? el.certificate : "");
    setImage(el ? el.image : "");
    setMainnet(el ? el.mainnet : null);
    setTestnet(el ? el.testnet : null);
    setIPFSlink(el ? el.IPFS_link : "");
  }, [el]);

  //handle submits for functionalities-----------------------------------
  const handleUploadLogo = (event) => {
    setPreviewFile(URL.createObjectURL(event.target.files[0]));
    setUploadLogo(event.target.files[0]);
  };
  const handleUploadCertificate = (event) => {
    setUploadCertificate(event.target.files[0]);
  };

  const handleLogoSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", uploadlogo);

    const payload = {
      id: el.pageID,
      data: formData,
    };
    dispatch(uploadLogoStart(payload));
  };
  const handleSubmitCertificate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", uploadcertificate);

    const payload = {
      id: el.pageID,
      data: formData,
    };
    dispatch(uploadCertificateStart(payload));
  };
  //---------------------------------------------------------

  return (
    <>
      <div
        style={
          index && index % 2 !== 0
            ? { backgroundColor: "#c1d9fc" }
            : { backgroundColor: "none" }
        }
        className={`card mb-3 popupcard ${onedit ? "pr-4" : ""} `}
      >
        <div className="card-body">
          {isnew ? (
            <div className="form-group row align-items-center">
              <div className="col-md-5">
                <input
                  type="text"
                  className={`form-control ${nameerror ? "error-border" : ""}`}
                  placeholder="Enter Name"
                  autoFocus={true}
                  value={name}
                  minLength="1"
                  maxlength="20"
                  disabled={el && !showpopup ? true : false}
                  onChange={(e) => {
                    setName(e.target.value);
                    validName = isValidString(e.target.value);
                  }}
                />
              </div>

              <div className="col-auto ml-auto">
                <label htmlFor="customSwitch21" className="d-flex p-2 border">
                  Featured ?
                  <div className="custom-control custom-switch custom-control-inline ml-2 mr-0">
                    <input
                      type="checkbox"
                      className="custom-control-input "
                      id="customSwitch1"
                      checked={featured}
                      onChange={(e) => setFeatured(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                    ></label>
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div className="form-group row align-items-center ">
              <div className="d-flex  align-items-center col-md-6 ">
                <div className="blog-img ">
                  <img className="img-logo" src={preview} alt="" />
                </div>
                <div className="p-2 d-flex flex-column col-md-8">
                  <div className="h5 text-center ">
                    {el ? el.name.toUpperCase() : ""}
                  </div>
                  {el && !showpopup ? (
                    ""
                  ) : (
                    <form onSubmit={handleLogoSubmit}>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleUploadLogo}
                        className="mb-1"
                        required
                      />
                      <button className="btn btn-primary w-100" type="submit">
                        {!logoloading ? (
                          "Upload Logo"
                        ) : (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Uploading...</span>
                          </>
                        )}{" "}
                      </button>
                    </form>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center col-md-2 justify-content-center ">
                Embed Link
                <img
                  src="/images/copy.svg"
                  alt="copy"
                  className="h-1 ml-2"
                  role="button"
                  onClick={() => setOpenPopup(true)}
                />
                {popup && (
                  <Popup size={"sm"} onclose={() => setOpenPopup(false)}>
                    <div>
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="card align-items-center">
                          <div className="card-body">
                            <i className="far fa-file-code fa-4x"></i>
                          </div>
                          <div className="card-body">
                            <p className="card-text">
                              {
                                <button
                                  className="btn btn-primary btn-md"
                                  onClick={() => (
                                    copyToClipboard(
                                      `<div id="quillaudits"></div><script id="embededdquillscript" src="${STATIC_JS_URL}" uuid="${createdAt}" disp="code-snippet"></script>`
                                    ),
                                    copyText()
                                  )}
                                >
                                  Code Snippet
                                </button>
                              }
                            </p>
                          </div>
                        </div>

                        <div className="card  align-items-center">
                          <div className="card-body">
                            <i className="fab fa-fort-awesome-alt fa-4x"></i>
                          </div>
                          {/* <iframe src="https://www.w3schools.com" title="W3Schools Free Online Web Tutorials"></iframe>  */}
                          <div className="card-body">
                            {
                              <button
                                className="btn btn-primary btn-md"
                                onClick={() => (
                                  copyToClipboard(`
                                  <div id="quillaudits"></div><script id="embededdquillscript"  src="${STATIC_JS_URL}" uuid="${createdAt}" disp="hover-snippet"></script>`),
                                  copyText()
                                )}
                              >
                                Hovering Snippet
                              </button>
                            }
                          </div>
                        </div>
                        {/* <div className='vertical-align'>Please add Certificate First</div> */}
                      </div>
                      {text && (
                        <div className="text-center text-success">
                          Copied !!
                        </div>
                      )}
                      {certificate ? (
                        ""
                      ) : (
                        <div className="text-center " style={{ color: "red" }}>
                          Please Add Project Logo, Client URL, Audit Report
                          Link, Source Code Link and Upload Certificate First in
                          Edit Section
                        </div>
                      )}
                    </div>
                  </Popup>
                )}
              </div>
              <div className="col-auto col-md-2 text-right">
                {el && !showpopup ? (
                  <label
                    htmlFor="flexSwitchCheckDisabled"
                    className="d-flex btn border"
                  >
                    Featured
                    <div className="custom-control custom-switch custom-control-inline ml-2 mr-0">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="flexSwitchCheckDisabled"
                        checked={featured}
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      ></label>
                    </div>
                  </label>
                ) : (
                  <label htmlFor="customSwitch1" className="d-flex btn border">
                    Featured ?
                    <div className="custom-control custom-switch custom-control-inline ml-2 mr-0">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        checked={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      ></label>
                    </div>
                  </label>
                )}
              </div>
              {/*IPFSlink && IPFSlink.length > 0 ? (
                <div className="d-flex align-items-center col-md-2 justify-content-center ">
                  IPFS Link
                  <img
                    src="/images/copy.svg"
                    alt="copy"
                    className="h-1 ml-2"
                    role="button"
                    onClick={() => (copyToClipboard(IPFSlink), copyText())}
                  />
                </div>
              ) : (
                <div></div>
              )*/}
            </div>
          )}
          <div className="form-group row align-items-end">
            {/*el && !showpopup ? (
              <div className="custom-form col-md-6">
                {address && address.length && address[0].contractAddress ? (
                  <>
                    <label htmlFor="auditreport" className="form-label">
                      Contract Address
                    </label>
                    <div class="row">
                      {address.map((el) => {
                        return (
                          <>
                            <div class="col-md-11">
                              <input
                                type="text"
                                className="form-control"
                                name="auditreport"
                                value={el.contractAddress}
                                disabled={el && !showpopup ? true : false}
                              />
                            </div>
                            <div class="col-md-1">
                              <img
                                src="/images/copy.svg"
                                alt="copy"
                                className="h-1 m-auto p-auto"
                                role="button"
                                onClick={() => (
                                  copyToClipboard(`${el.contractAddress}`),
                                  copyAddress()
                                )}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {/* <label htmlFor="auditreport" className="form-label">
                  Contract Address  
                </label> }
              </div>
            ) : (
              <div className="col-md-5">
                {address.map((x, i) => {
                  return (
                    <div className="d-flex justify-content-start">
                      <input
                        className={`form-control col-md-12 ${
                          addresserror ? "error-border" : ""
                        }`}
                        name="contractAddress"
                        placeholder="Enter Contract Address"
                        value={x.contractAddress}
                        disabled={el && !showpopup ? true : false}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <div className="d-flex justify-content-start">
                        {address.length !== 1 && (
                          <button
                            className="mr10"
                            style={{
                              color: "#226cfc",
                              backgroundColor: "white",
                              border: "none",
                              fontSize: "x-large",
                              marginLeft: "5px",
                            }}
                            onClick={() => handleRemoveClick(i)}
                          >
                            <strong>-</strong>
                          </button>
                        )}
                        {address.length - 1 === i && (
                          <button
                            style={{
                              color: "#226cfc",
                              backgroundColor: "white",
                              border: "none",
                              fontSize: "x-large",
                              marginLeft: "5px",
                            }}
                            onClick={handleAddClick}
                          >
                            <strong></strong>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )*/}
          </div>
          <div className="form-group row align-items-end">
            {el && !showpopup ? (
              <div className="custom-form col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Blockchain
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="auditreport"
                  value={blockchain && blockchain.map((el) => el.name + " ")}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            ) : (
              <div className="col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Blockchain
                </label>
                <Multiselect
                  options={blockchains}
                  selectedValues={blockchain}
                  onSelect={setBlockChain}
                  onRemove={setBlockChain}
                  displayValue="name"
                />
                {/* <select name="" id="" className="custom-select">
               <option value="">Blockchain</option>
             </select> */}
              </div>
            )}

            {/*el && !showpopup ? (
              <div className="custom-form col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Network
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="network"
                  value={network}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            ) : (
              <div className="col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Network
                </label>
                <select
                  name="networks"
                  id="network"
                  style={{ height: "38px", width: "100%" }}
                  onChange={(e) => {
                    setNetwork(e.target.value);
                    validNetwork = isValidNetwork(e.target.value);
                  }}
                  className={`form-select ${
                    networkerror ? "error-border" : ""
                  }`}
                  aria-label="Default select example"
                >
                  {networks &&
                    networks.map((el, i) => {
                      return (
                        <option
                          value={`${el}`}
                          selected={network === el ? true : false}
                        >
                          {el}
                        </option>
                      );
                    })}
                </select>
              </div>
                  )*/}
            {el && !showpopup ? (
              <div className="custom-form col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Tags
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="auditreport"
                  value={tag && tag.map((el) => el.name + " ")}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            ) : (
              <div className="col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Tags
                </label>
                <Multiselect
                  options={tags}
                  selectedValues={tag}
                  onSelect={setTag}
                  onRemove={setTag}
                  displayValue="name"
                />
                {/* <select name="" id="" className="custom-select">
               <option value="">Blockchain</option>
             </select> */}
              </div>
            )}
            <div className="custom-form col-md-2">
              <label htmlFor="auditreport" className="form-label">
                Audited By
              </label>
              <input
                type="text"
                className={`form-control ${
                  auditedbyerror ? "error-border" : ""
                }`}
                name="auditreport"
                maxLength="25"
                value={auditedby}
                disabled={true}
                onChange={(e) => {
                  setAuditedBy(e.target.value);
                  validAuditedBy = isValidString(e.target.value);
                }}
                required
              />
            </div>

            <div className="col d-flex flex-column justify-content-end">
              <label
                htmlFor="auditreport"
                className="form-label d-flex align-self-start "
              >
                Client Website URL
              </label>
              <div className="input-group custom-group">
                <div className=" input-group-text bg-primary text-white">
                  <a href={clienturl} target="_blank">
                    <img src="/images/world.svg" alt="world" />
                  </a>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    clienturlerror ? "error-border" : ""
                  }`}
                  disabled={el && !showpopup ? true : false}
                  value={clienturl}
                  onChange={(e) => {
                    setClientUrl(e.target.value);
                    validClientUrl = isValidUri(e.target.value);
                  }}
                  placeholder={"Client Website URL"}
                />
              </div>
            </div>
            {el && !showpopup ? (
              <div className="custom-form col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Status
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="status"
                  value={status}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            ) : (
              <div className="col-md-2">
                <label htmlFor="auditreport" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  id="status"
                  style={{ height: "38px", width: "100%" }}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    validStatus = isValidString(e.target.value);
                  }}
                  className={`form-select ${statuserror ? "error-border" : ""}`}
                  aria-label="Default select example"
                >
                  {statusList &&
                    statusList.map((el, i) => {
                      return (
                        <option
                          value={`${el}`}
                          selected={status === el ? true : false}
                        >
                          {el}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          </div>
          <div className="form-group row align-items-end">
            <div className="col-md-2">
              <label htmlFor="startdate" className="form-label">
                Start Date
              </label>
              {el && !showpopup ? (
                <input
                  type="text"
                  className="form-control"
                  name="startdate"
                  disabled={el && !showpopup ? true : false}
                  value={startDate && moment(startDate).format("MM/DD/YYYY")}
                />
              ) : (
                <DatePicker
                  className="custom-select"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              )}
            </div>

            <div className="col-md-2">
              <label htmlFor="auditreport" className="form-label">
                End Date
              </label>
              {el && !showpopup ? (
                <input
                  type="text"
                  className="form-control"
                  name="startdate"
                  disabled={el && !showpopup ? true : false}
                  value={endDate && moment(endDate).format("MM/DD/YYYY")}
                />
              ) : (
                <DatePicker
                  className="custom-select"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              )}
            </div>

            <div className="custom-form col-md-4">
              <label htmlFor="auditreport" className="form-label">
                Audit Report Link
              </label>
              <input
                type="text"
                className={`form-control ${
                  auditlinkerror ? "error-border" : ""
                }`}
                name="auditreport"
                disabled={el && !showpopup ? true : false}
                placeholder={"Enter Audit Git Link"}
                value={auditlink}
                onChange={(e) => {
                  setAuditLink(e.target.value);
                  validAuditLink = isValidUri(e.target.value);
                }}
              />
            </div>
            <div className="custom-form col-md-4">
              <label htmlFor="auditreport" className="form-label">
                Source Code Url
              </label>
              <input
                type="text"
                className={`form-control ${
                  sourcecodeerror ? "error-border" : ""
                }`}
                name="auditreport"
                disabled={el && !showpopup ? true : false}
                placeholder={" Source Code"}
                value={sourcecode}
                onChange={(e) => {
                  setSourceCode(e.target.value);
                  validSourceCode = isValidUri(e.target.value);
                }}
              />
            </div>
          </div>{" "}
          <div className="form-group row align-items-end">
            <div className="custom-form col-md-3">
              {isnew ? (
                ""
              ) : (
                <>
                  <label htmlFor="auditreport" className="form-label">
                    Upload Certificate
                  </label>

                  {el && !showpopup ? (
                    <div className="input-group custom-group">
                      <div className=" input-group-text bg-primary text-white">
                        <a href={certificate} target="_blank">
                          <img src="/images/world.svg" alt="world" />
                        </a>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        disabled={el && !showpopup ? true : false}
                        value={certificate}
                        placeholder={"Certificate"}
                      />
                    </div>
                  ) : (
                    <form
                      className="d-flex justify-content-between"
                      onSubmit={handleSubmitCertificate}
                    >
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleUploadCertificate}
                        required
                      />
                      <button className="btn btn-primary btn-sm">
                        {!certificateloading ? (
                          "Upload"
                        ) : (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Uploading...</span>
                          </>
                        )}
                      </button>
                    </form>
                  )}
                </>
              )}
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="testnet" className="form-label">
                Testnet
              </label>
              <input
                type="text"
                className={`form-control ${testnetError ? "error-border" : ""}`}
                name="testnet"
                disabled={el && !showpopup ? true : false}
                placeholder={"Enter Testnet address"}
                value={testnet}
                onChange={(e) => {
                  setTestnet(e.target.value);
                  if (e.target.value.length > 0) {
                    validTestnet = isValidUri(e.target.value);
                  } else {
                    validTestnet = false;
                  }
                }}
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="mainnet" className="form-label">
                Mainnet
              </label>
              <input
                type="text"
                className={`form-control ${mainnetError ? "error-border" : ""}`}
                name="mainnet"
                disabled={el && !showpopup ? true : false}
                placeholder={"Enter Mainnet address"}
                value={mainnet}
                onChange={(e) => {
                  setMainnet(e.target.value);
                  if (e.target.value.length > 0) {
                    validMainnet = isValidUri(e.target.value);
                  } else {
                    validMainnet = false;
                  }
                }}
              />
            </div>
            <div className="custom-form col-md-3">
              <label htmlFor="fixedin" className="form-label">
                Fixed In
              </label>
              <input
                type="text"
                className={`form-control ${fixedInError ? "error-border" : ""}`}
                name="fixedin"
                disabled={el && !showpopup ? true : false}
                placeholder={"Enter Fixed in Link"}
                value={fixedIn}
                onChange={(e) => {
                  setFixedIn(e.target.value);
                  validFixedIn = isValidUri(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group row align-items-end">
            <div className="custom-form d-block col-md-6">
              <label htmlFor="auditreport" className="form-label">
                Overview
              </label>
              <textarea
                style={{ width: "100%" }}
                className={`form-control ${
                  overviewerror ? "error-border" : ""
                }`}
                value={overview}
                disabled={el && !showpopup ? true : false}
                onChange={(e) => {
                  setOverview(e.target.value);
                  validOverview = isValidNote(e.target.value);
                }}
                rows="4"
                name="comment"
                form="usrform"
              >
                Enter overview here
              </textarea>
            </div>
            <div className="custom-form d-block col-md-6">
              <label htmlFor="auditreport" className="form-label">
                Scope Of Audit
              </label>
              <textarea
                style={{ width: "100%" }}
                className={`form-control ${
                  scopeOfAuditError ? "error-border" : ""
                }`}
                value={scopeOfAudit}
                disabled={el && !showpopup ? true : false}
                onChange={(e) => {
                  setScopeOfAudit(e.target.value);
                  validScopeOfAudit = isValidNote(e.target.value);
                }}
                rows="4"
                name="comment"
                form="usrform"
              >
                Enter scope of audit here
              </textarea>
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              {showpopup && !isnew && (
                <button
                  className="btn btn-secondary ml-3 btn-100"
                  onClick={handledelete}
                >
                  BACK
                </button>
              )}
              <button
                className="btn btn-primary ml-3 btn-100"
                id="ipfsbtn"
                onClick={handleUploadIPFS}
              >
                {!auditadding ? (
                  "UPLOAD REPORT TO IPFS"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>

              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={handleSave}
              >
                {!auditadding ? (
                  "SAVE"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button
            className="btn btn-more"
            onClick={() => {
              setCurrentReport(el);
              onedit();
              if (mainnet && mainnet.length > 0) validMainnet = true;
              if (testnet && testnet.length > 0) validTestnet = true;
            }}
          >
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default ReportCard;
