import { auditActionTypes } from "./Types";

export const loginStart = (data) => ({
  type: auditActionTypes.LOGIN_START,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: auditActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: auditActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: auditActionTypes.LOGOUT,
});

export const logoutSuccess = () => ({
  type: auditActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: auditActionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const addAuditStart = (data) => ({
  type: auditActionTypes.ADD_AUDIT_START,
  payload: data,
});

export const addAuditSuccess = (data) => ({
  type: auditActionTypes.ADD_AUDIT_SUCCESS,
  payload: data,
});

export const addAuditFailure = (error) => ({
  type: auditActionTypes.ADD_AUDIT_FAILURE,
  payload: error,
});

export const updateAuditStart = (data) => ({
  type: auditActionTypes.UPDATE_AUDIT_START,
  payload: data,
});

export const updateAuditSuccess = (data) => ({
  type: auditActionTypes.UPDATE_AUDIT_SUCCESS,
  payload: data,
});

export const updateAuditFailure = (error) => ({
  type: auditActionTypes.UPDATE_AUDIT_FAILURE,
  payload: error,
});

export const uploadToIPFSStart = (data) => ({
  type: auditActionTypes.UPLOAD_TO_IPFS_START,
  payload: data,
});

export const uploadToIPFSSuccess = (data) => ({
  type: auditActionTypes.UPLOAD_TO_IPFS_SUCCESS,
  payload: data,
});

export const uploadToIPFSFailure = (error) => ({
  type: auditActionTypes.UPLOAD_TO_IPFS_FAILURE,
  payload: error,
});
export const deleteAuditStart = (data) => ({
  type: auditActionTypes.DELETE_AUDIT_START,
  payload: data,
});

export const deleteAuditSuccess = (data) => ({
  type: auditActionTypes.DELETE_AUDIT_SUCCESS,
  payload: data,
});

export const deleteAuditFailure = (error) => ({
  type: auditActionTypes.DELETE_AUDIT_FAILURE,
  payload: error,
});

export const fetchAuditStart = (data) => ({
  type: auditActionTypes.FETCH_AUDIT_START,
  payload: data,
});

export const fetchAuditSuccess = (data) => ({
  type: auditActionTypes.FETCH_AUDIT_SUCCESS,
  payload: data,
});

export const fetchAuditFailure = (error) => ({
  type: auditActionTypes.FETCH_AUDIT_FAILURE,
  payload: error,
});

export const uploadLogoStart = (data) => ({
  type: auditActionTypes.UPLOAD_LOGO_START,
  payload: data,
});

export const uploadLogoSuccess = (data) => ({
  type: auditActionTypes.UPLOAD_LOGO_SUCCESS,
  payload: data,
});

export const uploadLogoFailure = (error) => ({
  type: auditActionTypes.UPLOAD_LOGO_FAILURE,
  payload: error,
});

export const uploadCertificateStart = (data) => ({
  type: auditActionTypes.UPLOAD_CERTIFICATE_START,
  payload: data,
});

export const uploadCertificateSuccess = (data) => ({
  type: auditActionTypes.UPLOAD_CERTIFICATE_SUCCESS,
  payload: data,
});

export const uploadCertificateFailure = (error) => ({
  type: auditActionTypes.UPLOAD_CERTIFICATE_FAILURE,
  payload: error,
});

export const uploadStaticStart = (data) => ({
  type: auditActionTypes.UPLOAD_STATIC_START,
  payload: data,
});

export const uploadStaticSuccess = (data) => ({
  type: auditActionTypes.UPLOAD_STATIC_SUCCESS,
  payload: data,
});

export const uploadStaticFailure = (error) => ({
  type: auditActionTypes.UPLOAD_STATIC_FAILURE,
  payload: error,
});

export const uploadHackImg = (data) => ({
  type: auditActionTypes.UPLOAD_HACK_IMG,
  payload: data,
});

export const uploadHackImgSuccess = (data) => ({
  type: auditActionTypes.UPLOAD_HACK_IMG_SUCCESS,
  payload: data,
});

export const UploadHackImgFailue = (error) => ({
  type: auditActionTypes.UPLOAD_HACK_IMG_FAILURE,
  payload: error,
});

export const createSubadminStart = (data) => ({
  type: auditActionTypes.CREATE_SUBADMIN_START,
  payload: data,
});

export const createSubadminSuccess = (data) => ({
  type: auditActionTypes.CREATE_SUBADMIN_SUCCESS,
  payload: data,
});

export const createSubadminFailure = (error) => ({
  type: auditActionTypes.CREATE_SUBADMIN_FAILURE,
  payload: error,
});

export const subadminPermissionStart = (data) => ({
  type: auditActionTypes.SUBADMIN_PERMISSION_START,
  payload: data,
});

export const subadminPermissionSuccess = (data) => ({
  type: auditActionTypes.SUBADMIN_PERMISSION_SUCCESS,
  payload: data,
});

export const subadminPermissionFailure = (error) => ({
  type: auditActionTypes.SUBADMIN_PERMISSION_FAILURE,
  payload: error,
});

export const viewSubadminStart = (data) => ({
  type: auditActionTypes.VIEW_SUBADMIN_START,
  payload: data,
});

export const viewSubadminSuccess = (data) => ({
  type: auditActionTypes.VIEW_SUBADMIN_SUCCESS,
  payload: data,
});

export const viewSubadminFailure = (error) => ({
  type: auditActionTypes.VIEW_SUBADMIN_FAILURE,
  payload: error,
});

export const searchAuditStart = (data) => ({
  type: auditActionTypes.SEARCH_AUDIT_START,
  payload: data,
});

export const searchAuditSuccess = (data) => ({
  type: auditActionTypes.SEARCH_AUDIT_SUCCESS,
  payload: data,
});

export const searchAuditFailure = (error) => ({
  type: auditActionTypes.SEARCH_AUDIT_FAILURE,
  payload: error,
});

export const deleteSubAdminStart = (data) => ({
  type: auditActionTypes.DELETE_SUBADMIN_START,
  payload: data,
});

export const deleteSubAdminSuccess = (data) => ({
  type: auditActionTypes.DELETE_SUBADMIN_SUCCESS,
  payload: data,
});

export const deleteSubAdminFailure = (error) => ({
  type: auditActionTypes.DELETE_SUBADMIN_FAILURE,
  payload: error,
});

export const quoteListingStart = (data) => ({
  type: auditActionTypes.QUOTE_LISTING_START,
  payload: data,
});

export const quoteListingSuccess = (data) => ({
  type: auditActionTypes.QUOTE_LISTING_SUCCESS,
  payload: data,
});

export const quoteListingFailure = (error) => ({
  type: auditActionTypes.QUOTE_LISTING_FAILURE,
  payload: error,
});

export const auditListingStart = (data) => ({
  type: auditActionTypes.AUDIT_LISTING_START,
  payload: data,
});

export const auditListingSuccess = (data) => ({
  type: auditActionTypes.AUDIT_LISTING_SUCCESS,
  payload: data,
});

export const auditListingFailure = (error) => ({
  type: auditActionTypes.AUDIT_LISTING_FAILURE,
  payload: error,
});

export const deleteQuoteStart = (data) => ({
  type: auditActionTypes.DELETE_QUOTE_START,
  payload: data,
});

export const deleteQuoteSuccess = (data) => ({
  type: auditActionTypes.DELETE_QUOTE_SUCCESS,
  payload: data,
});

export const deleteQuoteFailure = (error) => ({
  type: auditActionTypes.DELETE_QUOTE_FAILURE,
  payload: error,
});

export const deleteAuditQuoteStart = (data) => ({
  type: auditActionTypes.DELETE_AUDIT_QUOTE_START,
  payload: data,
});

export const deleteAuditQuoteSuccess = (data) => ({
  type: auditActionTypes.DELETE_AUDIT_QUOTE_SUCCESS,
  payload: data,
});

export const deleteAuditQuoteFailure = (error) => ({
  type: auditActionTypes.DELETE_AUDIT_QUOTE_FAILURE,
  payload: error,
});

export const getApikeyStart = (data) => ({
  type: auditActionTypes.GET_API_KEY_START,
  payload: data,
});

export const getApikeySuccess = (data) => ({
  type: auditActionTypes.GET_API_KEY_SUCCESS,
  payload: data,
});

export const getApikeyFailure = (error) => ({
  type: auditActionTypes.GET_API_KEY_FAILURE,
  payload: error,
});

export const updateApikeyStart = (data) => ({
  type: auditActionTypes.UPDATE_API_KEY_START,
  payload: data,
});

export const updateApikeySuccess = (data) => ({
  type: auditActionTypes.UPDATE_API_KEY_SUCCESS,
  payload: data,
});

export const updateApikeyFailure = (error) => ({
  type: auditActionTypes.UPDATE_API_KEY_FAILURE,
  payload: error,
});

export const generateApikeyStart = (data) => ({
  type: auditActionTypes.GENERATE_API_KEY_START,
  payload: data,
});

export const generateApikeySuccess = (data) => ({
  type: auditActionTypes.GENERATE_API_KEY_SUCCESS,
  payload: data,
});

export const generateApikeyFailure = (error) => ({
  type: auditActionTypes.GENERATE_API_KEY_FAILURE,
  payload: error,
});

export const getPublicApikeyStart = (data) => ({
  type: auditActionTypes.GET_PUBLIC_API_KEY_START,
  payload: data,
});

export const getPublicApikeySuccess = (data) => ({
  type: auditActionTypes.GET_PUBLIC_API_KEY_SUCCESS,
  payload: data,
});

export const getPublicApikeyFailure = (error) => ({
  type: auditActionTypes.GET_PUBLIC_API_KEY_FAILURE,
  payload: error,
});
export const generatePublicApikeyStart = (data) => ({
  type: auditActionTypes.GENERATE_PUBLIC_API_KEY_START,
  payload: data,
});

export const generatePublicApikeySuccess = (data) => ({
  type: auditActionTypes.GENERATE_PUBLIC_API_KEY_SUCCESS,
  payload: data,
});

export const generatePublicApikeyFailure = (error) => ({
  type: auditActionTypes.GENERATE_PUBLIC_API_KEY_FAILURE,
  payload: error,
});

export const updatePublicApikeyStart = (data) => ({
  type: auditActionTypes.UPDATE_PUBLIC_API_KEY_START,
  payload: data,
});

export const updatePublicApikeySuccess = (data) => ({
  type: auditActionTypes.UPDATE_PUBLIC_API_KEY_SUCCESS,
  payload: data,
});

export const updatePublicApikeyFailure = (error) => ({
  type: auditActionTypes.UPDATE_PUBLIC_API_KEY_FAILURE,
  payload: error,
});

export const getUserDetailsStart = (data) => ({
  type: auditActionTypes.GET_USER_DETAILS_START,
  payload: data,
});

export const getUserDetailsSuccess = (data) => ({
  type: auditActionTypes.GET_USER_DETAILS_SUCCESS,
  payload: data,
});

export const getUserDetailsFailure = (error) => ({
  type: auditActionTypes.GET_USER_DETAILS_FAILURE,
  payload: error,
});

export const updatePasswordStart = (data) => ({
  type: auditActionTypes.UPDATE_PASSWORD_START,
  payload: data,
});

export const updatePasswordSuccess = (data) => ({
  type: auditActionTypes.UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updatePasswordFailure = (error) => ({
  type: auditActionTypes.UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const clearStateData = () => ({
  type: auditActionTypes.CLEAR_STATE_DATA,
});

export const countLinesLinkStart = (data) => ({
  type: auditActionTypes.COUNT_LINES_LINK_START,
  payload: data,
});

export const countLinesLinkSuccess = (data) => ({
  type: auditActionTypes.COUNT_LINES_LINK_SUCCESS,
  payload: data,
});

export const countLinesLinkFailure = (error) => ({
  type: auditActionTypes.COUNT_LINES_LINK_FAILURE,
  payload: error,
});

export const countLinesInFileStart = (data) => ({
  type: auditActionTypes.COUNT_LINES_IN_FILE_START,
  payload: data,
});

export const countLinesInFileSuccess = (data) => ({
  type: auditActionTypes.COUNT_LINES_IN_FILE_SUCCESS,
  payload: data,
});

export const countLinesInFileFailure = (error) => ({
  type: auditActionTypes.COUNT_LINES_IN_FILE_FAILURE,
  payload: error,
});

export const countLinesTextStart = (data) => ({
  type: auditActionTypes.COUNT_LINES_TEXT_START,
  payload: data,
});

export const countLinesTextSuccess = (data) => ({
  type: auditActionTypes.COUNT_LINES_TEXT_SUCCESS,
  payload: data,
});

export const countLinesTextFailure = (error) => ({
  type: auditActionTypes.COUNT_LINES_TEXT_FAILURE,
  payload: error,
});

export const createRoleStart = (data) => ({
  type: auditActionTypes.CREATE_ROLE_START,
  payload: data,
});

export const createRoleSuccess = (data) => ({
  type: auditActionTypes.CREATE_ROLE_SUCCESS,
  payload: data,
});

export const createRoleFailure = (error) => ({
  type: auditActionTypes.CREATE_ROLE_FAILURE,
  payload: error,
});

export const getRoleStart = (data) => ({
  type: auditActionTypes.GET_ROLE_START,
  payload: data,
});

export const getRoleSuccess = (data) => ({
  type: auditActionTypes.GET_ROLE_SUCCESS,
  payload: data,
});

export const getRoleFailure = (error) => ({
  type: auditActionTypes.GET_ROLE_FAILURE,
  payload: error,
});

export const deleteRoleStart = (data) => ({
  type: auditActionTypes.DELETE_ROLE_START,
  payload: data,
});

export const deleteRoleSuccess = (data) => ({
  type: auditActionTypes.DELETE_ROLE_SUCCESS,
  payload: data,
});

export const deleteRoleFailure = (error) => ({
  type: auditActionTypes.DELETE_ROLE_FAILURE,
  payload: error,
});

export const fetchHacks = (queries) => ({
  type: auditActionTypes.FETCH_HACKS,
  payload: { queries },
});

export const fetchHacksSuccess = (data) => ({
  type: auditActionTypes.FETCH_HACKS_SUCCESS,
  payload: { data },
});

export const fetchHacksFailure = (error) => ({
  type: auditActionTypes.FETCH_HACKS_FAILURE,
  payload: { error },
});