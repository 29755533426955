import React, { useState, useEffect } from "react";
import { clearStateData, uploadStaticStart } from "../../redux/audit/Actions";
import { buckets } from "../../utils/dropdowndata";
import { copyToClipboard } from "../../utils/common";
import { useDispatch, useSelector } from 'react-redux'

function StaticDataCard({ isnew = false, onclose, onedit, showpopup }) {
  const [bucket, setBucket] = useState('QuillAudit');
  const [imagename, setImageName] = useState('');
  const [selected, setSelectedFile] = useState(null);
  const audit = useSelector(state => state.audit);
  const { createdurl, staticuploaded } = audit

  const [text, setText] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    document.getElementById('file-input').click();
  };

  function copyText() {
    setText(true);

    var timer = setTimeout(() => {
      setText(false)
    }, 1500)
  }

  const handleImageUpload = event => {

    setImageName(event.target.files[0].name)
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    dispatch(clearStateData())
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    let type = bucket == 'QuillAudit' ? 'audit' : 'quill'
    const formData = new FormData();
    formData.append('image', selected);
    const payload = {
      type: type,
      data: formData
    }
    dispatch(uploadStaticStart(payload))
  }


  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-4">
              <select name="type" id="type" style={{ height: '38px', width: '100%' }} className='form-select' aria-label="Default select example">
                {
                  buckets.map((el, i) => {
                    return (
                      <option value={`${el}`} selected={bucket === el ? true : false} onClick={() => setBucket(el)}>{el}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="col-md-4">
              <form onSubmit={handleSubmit}>
                <div className="input-group custom-group">

                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white" onClick={() => handleClick()}>
                      <img src="/images/add-file.svg" alt="addfile " />
                    </span>

                  </div>
                  <input type="text" type='text' required value={imagename} className="form-control" onClick={() => handleClick()} />
                  <input type="text" type='file' required onChange={handleImageUpload} className="form-control" style={{ display: 'none' }} id="file-input" />

                  <div className="input-group-append ml-1">
                    <button
                      className="btn"
                      type="button"
                      id="button-addon2"
                      type='submit'
                    >


                      {!staticuploaded ? 'Upload' : <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </>
                      }

                    </button>
                  </div>

                </div>
              </form>
            </div>
            <div className="custom-form col-md-4">
              <label htmlFor="auditreport" className="form-label">
                Static Link
              </label>
              <img
                src="/images/copy.svg"
                alt="copy"
                className="h-1 ml-2"
                role="button"
                onClick={() => (copyToClipboard(
                  `${createdurl}`
                ), copyText())}
              />
              {
                text && createdurl && <span className='text-center text-success ml-2'>Copied !!</span>
              }
              <input type="text" value={createdurl} className="form-control" name="auditreport" />
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {/* {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )} */}
      </div>
    </>
  );
}

export default StaticDataCard;
