import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import NewsCard from "../../components/NewsCard/NewsCard";

function News() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleNewEvent = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };

  return (
    <>
      <Header
        title="News"
        btntitle="New News"
        newbtnclick={toggleNewEvent}
        isnew={isNew}
      />
      <div className="content-scroll">
        {isNew && <NewsCard isnew={isNew} onclose={toggleNewEvent} />}
        {Array.from({ length: 3 }).map((item, i) => (
          <NewsCard key={i} onedit={handleEdit} />
        ))}
      </div>
      {showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <NewsCard onedit={handleEdit} showpopup={showPopup} />
        </Popup>
      )}
    </>
  );
}

export default News;
