import React from "react";
import "./Popup.scss";

function Popup({ children, size, onclose }) {
  return (
    <div className="popup popupscroll">
      <div className={`popup-box shadow ${size}`}>
        <div className="text-right pr-2">
          <button className="btn btn-popup-close" onClick={onclose}>
            <img src="/images/close.svg" alt="close" />
          </button>
        </div>
        <div className="popup-body">{children}</div>
      </div>
    </div>
  );
}

export default Popup;
