import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ImageComponent from "../components/ImageComponent/ImageComponent";
import AuditReports from "../containers/AuditReports/AuditReports";
import Blogs from "../containers/Blogs/Blogs";
import Clients from "../containers/Clients/Clients";
import Events from "../containers/Events/Events";
import Jobs from "../containers/Jobs/Jobs";
import Login from "../containers/Login/Login";
import News from "../containers/News/News";
import SmartContracts from "../containers/SmartContracts/SmartContracts";
import StaticData from "../containers/StaticData/StaticData";
import Testimonials from "../containers/Testimonials/Testimonials";
import Auditor from "../containers/Auditor";
import Certificate from "../components/Certificate/Certificate";
import ManageRole from "../containers/ManageRoles/ManageRoles";
import AuditContract from "../containers/AuditContract/AuditContract";
import SecurityCheck from "../containers/SecurityCheck/SecurityCheck";
import QuoteListing from "../containers/QuoteListing/QuoteListing";
import ApiKey from "../containers/ApiKey/ApiKey";
import Profile from "../containers/Profile/Profile";
import Reset from "./Reset";
import Landing from "../containers/Landing";
import Hackerboard from "../containers/hackerboard/Hackerboard"
import Headings from "../containers/headings/Headings";
import Careers from "../containers/careers/Careers";
import MediaHighlights from "../containers/MediaHighlights/MediaHighlights";
import EventPage from "../containers/EventPage/EventPage";
import Docs from "../containers/Docs/Docs";
import ApiKeyRequests from "../containers/QuillMonitor/Users";

function Routes({ auditloading }) {
  // const AuditReportWithSpinner=WithSpinner(AuditReports)
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/image" render={ImageComponent} />
      <Route
        exact
        path="/login"
        render={(props) =>
          !localStorage.getItem("token") ? (
            <Login {...props} />
          ) : (
            <Redirect to="/landing" />
          )
        }
      />
      <Route
        exact
        path="/reset"
        render={(props) =>
          <Reset />
        }
      />

      <Route
        exact
        path="/smartcontracts"
        render={(props) =>
          localStorage.getItem("token") ? (
            <SmartContracts {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route exact path="/auditreports"
        render={(props) =>
          localStorage.getItem("token") ? (
            <AuditReports isLoading={auditloading} {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route exact path="/hackerboard"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Hackerboard {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/headings"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Headings {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/quillmonitor"
        render={(props) =>
          localStorage.getItem("token") ? (
            <ApiKeyRequests {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/media"
        render={(props) =>
          localStorage.getItem("token") ? (
            <MediaHighlights {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/careers"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Careers {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/docs"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Docs {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/testimonials"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Testimonials {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/auditcontract"
        render={(props) =>
          localStorage.getItem("token") ? (
            <AuditContract {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/blogs"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Blogs {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/quotelisting"
        render={(props) =>
          localStorage.getItem("token") ? (
            <QuoteListing {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/clients"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Clients {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/jobs"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Jobs {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route exact path='/analyze-contract' render={(props) =>
        localStorage.getItem('token') ? (<Auditor {...props} />) : <Redirect to='/login' />
      } />

      <Route exact path='/landing' render={(props) =>
        localStorage.getItem('token') ? (<Landing {...props} />) : <Redirect to='/login' />
      } />
      <Route
        exact
        path="/staticdata"
        render={(props) =>
          localStorage.getItem("token") ? (
            <StaticData {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/security-check"
        render={(props) =>
          localStorage.getItem("token") ? (
            <SecurityCheck {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />

      <Route
        exact
        path="/events"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Events {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/apikey"
        render={(props) =>
          localStorage.getItem("token") ? (
            <ApiKey {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route exact path="/certificate/:imageurl" component={Certificate} />
      {/* <Route exact path='/certificate-hover/:imageurl'
           component={Certificate}
            /> */}

      <Route
        exact
        path="/news"
        render={(props) =>
          localStorage.getItem("token") ? (
            <News {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/manageroles"
        render={(props) =>
          localStorage.getItem("token") ? (
            <ManageRole {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/profile"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Profile {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/eventpage"
        render={(props) =>
          localStorage.getItem("token") ? (
            <EventPage {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </Switch>
  );
}

export default Routes;
