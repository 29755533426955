import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { columns, publicColumns } from "./data";
import {
  getApikeyStart,
  getPublicApikeyStart,
  generatePublicApikeyStart,
} from "../../redux/audit/Actions";
import Popup from "../../components/Popup/Popup";
import Loader from "../../components/LoadingSpinner/Loader";
import ApiRoleManagement from "../../components/Apikey/ManageRole";
import ApiRoleManagementPublic from "../../components/Apikey/ManageRolePublic";
import moment from "moment";
import CustomTable from "../../components/Table/Table";
import { copyToClipboard, shortAddress } from "../../utils/common";
import {
  generatePublicApiKey,
  generatePublicApiKeyStart,
  getPublicApiKey,
} from "../../redux/audit/Sagas";

const ApiKey = () => {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPublic, setShowPopupPublic] = useState(false);

  const dispatch = useDispatch();
  const [currentKey, setCurrentKey] = useState([]);
  const [currentKeyPublic, setCurrentKeyPublic] = useState([]);
  const audit = useSelector((state) => state.audit);
  const {
    getapikey,
    getApikeyloading,
    getpublicapikey,
    getpublicApikeyloading,
    updateApikeyloading,
    updatePublicApikeyloading,
    generateApikeyloading,
    generatePublicApiKey,
  } = audit;
  const toggleNewStaticData = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };
  const togglePopupPublic = () => {
    setShowPopupPublic((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };

  const handleEditPublic = () => {
    togglePopupPublic();
    const data = {
      limit: row,
      page,
    };
    setTimeout(() => {
      dispatch(getPublicApikeyStart(data));
    }, 100);
  };

  const handleCreatedFor = (e) => {
    setCreatedFor(e.target.value);
  };

  const handleGenerateKey = (e) => {
    e.preventDefault();
    if (created_for != "") {
      dispatch(
        generatePublicApikeyStart({
          created_for: created_for,
          created_by: localStorage.getItem('adminName'),
        })
      );
      const data = {
        limit: row,
        page,
      };
      setTimeout(() => {
        dispatch(getPublicApikeyStart(data));
      }, 2000);
    }
  };

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [row, setRow] = useState(10);
  const fetchIdRef = React.useRef(0);
  const [tableDataPublic, setTableDataPublic] = useState([]);
  const [totalRecordPublic, setTotalRecordPublic] = useState(0);
  const [pageCountPublic, setPageCountPublic] = React.useState(0);
  const [pagePublic, setPagePublic] = useState(1);
  const [rowPublic, setRowPublic] = useState(10);
  const fetchIdRefPublic = React.useRef(0);
  const [created_for, setCreatedFor] = useState("");

  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    if (fetchId === fetchIdRef.current) {
      setPage(pageIndex + 1);
      setRow(pageSize);
      setLoading(false);
    }
  }, []);

  const customColumns = React.useMemo(() => columns, []);
  const customColumnsPublic = React.useMemo(() => publicColumns, []);

  useEffect(() => {
    if (!updateApikeyloading && !generateApikeyloading) {
      const data = {
        limit: row,
        page,
      };
      dispatch(getApikeyStart(data));
      dispatch(getPublicApikeyStart(data));
    }
  }, [page, row, updateApikeyloading, generateApikeyloading]);

  const handleUpdate = async (el) => {
    setIsNew(false);

    setCurrentKey(el);
    togglePopup();
    // await dispatch(deleteAuditQuoteStart(id));
  };
  const handleUpdatePublic = async (el) => {
    setIsNew(false);

    setCurrentKeyPublic(el);
    togglePopupPublic();
    // await dispatch(deleteAuditQuoteStart(id));
  };

  useEffect(() => {
    if (getapikey) {
      const newArr = getapikey.data.map((el) => {
        return {
          ...el,
          prefix: (
            <div className="d-flex align-items-center">
              <span>{el.prefix}</span>
              <img
                src="/images/copy.svg"
                alt="copy"
                className="h-1 m-auto p-auto"
                role="button"
                onClick={() => copyToClipboard(`${el.prefix}`)}
              />
            </div>
          ),
          created_at: (
            <div>{moment(el.created_at).format("DD-MM-YYYY HH:MM a")}</div>
          ),
          read: el.permission && el.permission.read ? "Given" : "Not Given",
          write: el.permission && el.permission.write ? "Given" : "Not Given",
          delete: el.permission && el.permission.delete ? "Given" : "Not Given",
          action: (
            <button
              className="btn btn-success"
              onClick={() => handleUpdate(el)}
            >
              Update
            </button>
          ),
        };
      });
      setTableData(newArr);
      setTotalRecord(getapikey.totalRecords);
      setPageCount(Math.ceil(getapikey.totalRecords / row));
    }
  }, [getapikey]);
  useEffect(() => {
    if (getpublicapikey && getpublicapikey.data) {
      const newArr = getpublicapikey.data.map((el) => {
        const permissions = [];
        const keys = Object.keys(el);
        for (var temp of keys) {
          if (el[temp] == true) {
            permissions.push(temp + " | \n");
          }
        }
        return {
          ...el,
          api_key: (
            <div className="d-flex align-items-center">
              <span>{el.api_key}</span>
              <img
                src="/images/copy.svg"
                alt="copy"
                className="h-1 m-auto p-auto"
                role="button"
                onClick={() => copyToClipboard(`${el.api_key}`)}
              />
            </div>
          ),
          created_for: el.created_for,
          created_by: el.created_by,
          permissions: permissions,
          action: (
            <button
              className="btn btn-success"
              onClick={() => handleUpdatePublic(el)}
            >
              Update
            </button>
          ),
        };
      });
      setTableDataPublic(newArr);
      setTotalRecordPublic(getpublicapikey.totalRecords);
      setPageCountPublic(Math.ceil(getpublicapikey.totalRecords / row));
    }
  }, [getpublicapikey]);
  return (
    <>
      <Header
        title="Api Key"
        btntitle="Generate API Key"
        newbtnclick={toggleNewStaticData}
        isnew={isNew}
      />
      <h4>
        <center>ERP ACL</center>
      </h4>
      <div className="content-scroll">
        {isNew && (
          <ApiRoleManagement isnew={isNew} onclose={toggleNewStaticData} />
        )}

        {showPopup && (
          <Popup size={"xxl"} onclose={togglePopup}>
            <ApiRoleManagement
              onedit={handleEdit}
              onclose={togglePopup}
              showpopup={showPopup}
              el={currentKey}
              setCurrentKey={setCurrentKey}
            />
          </Popup>
        )}

        <div className="card-body  d-flex justify-content-center ">
          <div style={{ overflow: "auto" }}>
            {tableData ? (
              <CustomTable
                columns={customColumns}
                data={tableData}
                fetchData={fetchData}
                pageCount={pageCount}
                loading={getApikeyloading}
                totalRecord={totalRecord}
              />
            ) : (
              <h5
                className="m-3 d-flex align-items-center"
                style={{ height: "200px" }}
              >
                No Data Found
              </h5>
            )}
          </div>
        </div>
      </div>

      <h4>
        <center>PUBLIC API</center>
      </h4>

      <div className="content-scroll">
        {isNew && (
          <ApiRoleManagementPublic
            isnew={isNew}
            onclose={toggleNewStaticData}
          />
        )}

        {showPopupPublic && (
          <Popup size={"xxl"} onclose={togglePopupPublic}>
            <ApiRoleManagementPublic
              onedit={handleEditPublic}
              onclose={togglePopupPublic}
              showpopup={showPopupPublic}
              el={currentKeyPublic}
              setCurrentKey={setCurrentKeyPublic}
            />
          </Popup>
        )}

        <div className="card-body  d-flex justify-content-center ">
          <div style={{ overflow: "auto" }}>
            {tableDataPublic ? (
              <CustomTable
                columns={customColumnsPublic}
                data={tableDataPublic}
                fetchData={fetchData}
                pageCount={pageCountPublic}
                loading={getApikeyloading}
                totalRecord={totalRecordPublic}
              />
            ) : (
              <h5
                className="m-3 d-flex align-items-center"
                style={{ height: "200px" }}
              >
                No Data Found
              </h5>
            )}
          </div>
        </div>
        <form>
          <center>
            <label style={{ margin: "10px" }}>Created for</label>
            <input
              type="text"
              name="created_for"
              value={created_for}
              style={{ margin: "10px" }}
              onChange={handleCreatedFor}
              required
            ></input>
            <button
              type="submit"
              className="btn btn-success"
              onClick={handleGenerateKey}
            >
              GENERATE NEW PUBLIC API KEY
            </button>
          </center>
        </form>
      </div>
    </>
  );
};

export default ApiKey;
