import { render } from "@testing-library/react";
import React, { createRef, Component, useState,useEffect, useCallback, useRef } from "react";
import {useSelector,useDispatch} from 'react-redux';
import {connect} from 'react-redux'
import Header from "../../components/Header/Header";
import { useScreenshot, createFileName } from "use-react-screenshot";



export default () => {
  const [isNew, setIsNew] = useState(false);
  const [red,setRed] = useState();
  const [yellow,setYellow] = useState();
  const [green,setGreen] = useState();

  const toggleNewStaticData = () => {
    setIsNew((prev) => !prev);
  };

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

return (
    <>
      <Header
        title="Security Check"
        isnew={isNew}
        type={'static'}
        searchbar={false}
      />
    <div className="content-scroll">
      <div
        ref={ref}
        style={{
          border: "none",
          marginTop: "20px",
          marginBottom: "20px"
        }}
      >
        <div className="d-flex position-relative">
          <div style={(red&&yellow&&green)?{width:((red/(red+yellow+green))*100)+"%", height:'30px', background: 'linear-gradient(to right, red, red, red, red, red, red, orange)',border:'none'}:{width:'20%',height:'30px', background: 'linear-gradient(to right, red, red, red, red, red, red, orange)',border:'none'}} className="float-left border-0"></div>
          <div style={(red&&yellow&&green)?{width:((yellow/(red+yellow+green))*100)+"%", height:'30px', background: 'linear-gradient(to right, orange, yellow, yellow, yellow, yellow, yellow, green)',border:'none'}:{width:'50%',height:'30px', background: 'linear-gradient(to right, orange, yellow, yellow, yellow, yellow, yellow, green)',border:'none'}} className="float-left border-0"></div>
          <div style={(red&&yellow&&green)?{width:((green/(red+yellow+green))*100)+"%", height:'30px', background: 'linear-gradient(to right, green, green, green, green, green, green, green)',border:'none'}:{width:'30%',height:'30px', background: 'linear-gradient(to right, green, green, green, green, green, green, green)',border:'none'}} className="float-left border-0"></div>
        </div>
      </div>
      <form>
                <input
                  type="number"
                  className="form-control w-25"
                  placeholder="Red"
                  autoFocus={true}
                  value={red}
                  onChange={(e) => {setRed(parseInt(e.target.value));}}
                  required
                /><br/>
                <input
                  type="number"
                  className="form-control w-25"
                  placeholder="Yellow"
                  autoFocus={true}
                  value={yellow}
                  onChange={(e) => {setYellow(parseInt(e.target.value));}}
                  required
                /><br/>
                <input
                  type="number"
                  className="form-control w-25"
                  placeholder="Green"
                  autoFocus={true}
                  value={green}
                  onChange={(e) => {setGreen(parseInt(e.target.value));}}
                  required
                /><br/>
              </form>
                {
                 (red&&yellow&&green)? 
                 <button class="btn btn-primary btn-block w-25" onClick={downloadScreenshot}>Download</button>:
                 <button class="btn btn-primary btn-block w-25" onClick={downloadScreenshot} disabled>Download</button>
                }
    </div>
      </>)
      }