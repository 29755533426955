import React, { useState, useEffect, useRef } from 'react';
import List from './List';

function Careers() {
    const [items, setItems] = useState([]);
    const [inputValues, setInputValues] = useState({
        title: '',
        type: '',
        skills: '',
        link: '',
    });
    const inputRef = useRef(null);
    const [open, setOpen] = useState(false);
  
    const handleCheckboxChange = () => {
        setOpen(!open);
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setInputValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const fetchItems = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/careers');
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddItem = async () => {
        try {
            const careersData = inputValues;
            careersData.open = open;
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/erp_audit/careers/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": localStorage.getItem("token"),
                },
                body: JSON.stringify(careersData),
            });
            setInputValues({
                title: '',
                type: '',
                skills: '',
                link: ''
            });
            window.location.reload();
            // Update the items state after adding or updating an item
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    return (
        <div className="input-list-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="text-center h3" style={{ marginTop: '20px'}}>Add Careers To The List</h1>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '2px' }}>
                        <label htmlFor="title-input">Title:</label>
                        <input id="title-input" type="text" name="title" value={inputValues.title} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ marginRight: '2px' }}>
                        <label htmlFor="type-input">Type:</label>
                        <input id="type-input" ref={inputRef} type="text" name="type" value={inputValues.type} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ marginRight: '2px' }}>
                        <label htmlFor="skills-input">Skills:</label>
                        <input id="skills-input" ref={inputRef} type="text" name="skills" value={inputValues.skills} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{ marginRight: '2px' }}>
                        <label htmlFor="link-input">Link:</label>
                        <input id="link-input" ref={inputRef} type="text" name="link" value={inputValues.link} onChange={handleInputChange} style={{ marginLeft: '5px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }} />
                    </div>
                    <div style={{marginRight:'2px'}}>
                        <input
                        type="checkbox"
                        checked={open}
                        onChange={handleCheckboxChange}
                        />&nbsp;
                        Open
                    </div>
                    <button className="add-button" onClick={handleAddItem} style={{ marginRight:'2px', padding: '5px', borderRadius: '3px', background: '#008CBA', color: 'white', border: 'none' }}>Add</button>
                </div>
            </div>
        <div>
        </div>
            <div className="list-container" style={{ marginTop: '20px' }}>
                <h2 className="text-center h3">Items in List</h2>
                {items.length > 0 ? (
                    <ul className="item-list">
                        {items.map((item, index) => (
                            <li key={index} className="item">
                                <List title={item.title} type={item.type} skills={item.skills} link={item.link} open={item.open} _id={item._id} />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div >
                        No item in the careers
                    </div>
                )}
            </div>
        </div>
    );


}

export default Careers;
