import React from "react";

function ClientCard({ isnew = false, onclose, onedit, showpopup }) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-5">
              <div className="form-row">
                <div className="col-auto">
                  <div className="blog-img">
                    {/* <img className="img-fluid" src="/images/quillhash.png" alt="" /> */}
                  </div>
                </div>
                <div className="col d-flex flex-column align-items-start justify-content-center">
                  <h6 className="h6 mb-0 ">Client Title</h6>
                  <button className="btn btn-primary mt-4">
                    QuillAudits
                    <svg viewBox="0 0 16 16" height="12" className="ml-2">
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2 5l6 6 6-6"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <select name="" id="" className="custom-select">
                  <option value="">Product Service Page</option>
                </select>
              </div>
              <div className="">
                <div className="input-group custom-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-primary text-white">
                      <img src="/images/world.svg" alt="world" />
                    </span>
                  </div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="auditreport" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
                rows="4"
              />
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default ClientCard;
