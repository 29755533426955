import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import QuoteListingCard from "../../components/QuoteListing/QuoteListing";

function QuoteListing() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleNewStaticData = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Quote Listing"
        btntitle="New Asset"
        newbtnclick={toggleNewStaticData}
        isnew={isNew}
        type={"static"}
        searchbar={false}
      />
      <div className="content-scroll">
        {Array.from({ length: 1 }).map((item, i) => (
          <QuoteListingCard key={i} onedit={handleEdit} />
        ))}
      </div>
    </>
  );
}

export default QuoteListing;
