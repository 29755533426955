import React from "react";
import { LinkText } from "../LinkElements/LinkText";

function Incubation({
  isnew = false,
  onclose,
  onedit,
  showpopup,
  title = "Title",
}) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-4">
              <div className="form-row">
              <div className="col d-flex flex-column justify-content-around">
           {/* 1st row---- */}
                        <div className='row align-items-end'>
                        <div className="custom-form col-md-6 ">
                        <select name="" id="" className="custom-select">
                            <option value="">Investment</option>
                        </select>
                        </div>
                        <div className="custom-form col-md-6">
                      
                        <select name="start" id="" className="custom-select">
                            <option value="">Start Date</option>
                        </select>
                        </div>
                        </div>
            {/* 2nd row---- */}
                        <div className='row align-items-end'>
                        <div className="custom-form col-md-6 ">
                            <select name="" id="" className="custom-select">
                                <option value="">Type</option>
                            </select>
                            </div> 
                            <div className="custom-form col-md-6">
                          <label htmlFor="eventtext" className="form-label">
                            Lorem Ipsum
                          </label>
                          <input type="text" className="form-control" name="eventtext" />
                            </div>
                        </div>
        </div>
         </div>
            </div>
            <div className="col-md-3">
            <label htmlFor="eventtext" className="form-label">
                            Lorem Ipsum
                          </label>
            {Array.from({ length: 3 }).map((item, i) => (
          <LinkText key={i}  />
        ))}
         
       </div>
       <div className="col-md-5">
              <label htmlFor="auditreport" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
                rows="4"
              />
            </div>
        </div>
         
          
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default Incubation;
