import React from "react";

function TestimonialCard({ isnew = false, onclose, onedit, showpopup }) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          {isnew ? (
            <div className="form-group row align-items-center">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Client and Company Name"
                  autoFocus={true}
                />
              </div>
              <div className="col">
                <button className="btn btn-primary ">
                  Select Service
                  <svg viewBox="0 0 16 16" height="12" className="ml-2">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 5l6 6 6-6"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className="form-group row align-items-center">
                <div className="col-auto">
                  <div className="blog-img">
                    {/* <img className="img-fluid" src="/images/quillhash.png" alt="" /> */}
                  </div>
                </div>
              <h4 className="h5 mb-0 col">Client Name, Clinet Company</h4>

              <div className="col">
                <button className="btn btn-primary ">
                  QuillAudits
                  <svg viewBox="0 0 16 16" height="12" className="ml-2">
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 5l6 6 6-6"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          )}
          <div className="form-group row align-items-end">
            <div className="custom-form col">
              <label htmlFor="auditreport" className="form-label">
                Testimonial
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
              />
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default TestimonialCard;
