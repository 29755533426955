// export const tags=['ril','sil','uuu']

export const tags = [
  { name: "Defi", id: 1 },
  { name: "DeFi Staking", id: 2 },
  { name: "NFT", id: 3 },
  { name: "DAO", id: 4 },
  { name: "ERC 20 Token Contract", id: 5 },
  { name: "ERC721 Contract", id: 6 },
  { name: "ERC865 Contract", id: 7 },
  { name: "Erc1155", id: 8 },
  { name: "IERC165", id: 9 },
  { name: "BEP 20", id: 10 },
  { name: "Crowdsale Contract", id: 11 },
  { name: "Governance Contract", id: 12 },
  { name: "Time-LOCK Contract", id: 13 },
  { name: "Vesting CONTRACT", id: 14 },
  { name: "Burnable & Mintable Feature", id: 15 },
  { name: "Token Distribution", id: 16 },
  { name: "AirDrop & Token Lock", id: 17 },
  { name: "Taxation Feature", id: 18 },
  { name: "TRON BLOCKCHAIN", id: 19 },
  { name: "Factory Contract", id: 20 },
  // { name: "Factory Contract", id: 21 },
  { name: "Whitelisting & Reward Distribution", id: 22 },
  { name: "Upgradable Contracts", id: 23 },
  { name: "ERC31337", id: 24 },
  { name: "IDO/ILO", id: 25 },
  { name: "Voting", id: 26 },
  { name: "TokenSwap", id: 27 },
  { name: "Bridge", id: 28 },
  { name: "uniswap", id: 29 },
  { name: "Ownable Contract", id: 30 },
  { name: "Vaults", id: 31 },
  { name: "Wallet", id: 32 },
  { name: "Proxy", id: 33 },
  { name: "Cross chain Wallet", id: 34 },
  { name: "Bridge", id: 35 },
  { name: "upgradable Contracts", id: 36 },
  { name: "Factory Contract", id: 37 },
  { name: "Games", id: 38 },
  { name: "Governance contract", id: 39 },
  { name: "IERC165", id: 40 },
  { name: "NFT", id: 41 },
  { name: "Taxation Feature", id: 42 },
  { name: "Time-LOCK Contract", id: 43 },
  { name: "Token Distribution", id: 44 },
  { name: "eSports", id: 45 },
  { name: "escrow", id: 46 },
  { name: "gateway", id: 47 },
  { name: "yield farming", id: 48 },
  { name: "AMM", id: 49 },
  { name: "Bonding Curve Algo", id: 50 },
  { name: "uniswap v2", id: 51 },
  { name: "uniswap v3", id: 52 },
  { name: "pancakeswap", id: 53 },
  { name: "Lending", id: 54 },
  { name: "Decentralised Exchanges", id: 55 },
  { name: "pegged and unpegged", id: 56 },
  { name: "Derivatives", id: 57 },
  { name: "Aggregators", id: 58 },
  { name: "NFT LOANS", id: 59 },
  { name: "BOT Trading", id: 60 },
  { name: "pre sale", id: 61 },

  { name: "Deflationary", id: 62 },
  { name: "Gaming", id: 63 },
  { name: "ECDSA", id: 64 },
  { name: "Freezable", id: 65 },
  { name: "Reward Tokens", id: 66 },
  { name: "Reward Pool", id: 67 },
  { name: "Multi Reward Pool", id: 68 },
  { name: "Tokenisation", id: 69 },
  { name: "stableCoins", id: 70 },
  { name: "Loyalty Contracts", id: 71 },
  { name: "Play to earn", id: 72 },
  { name: "Auction Contract", id: 73 },
  { name: "Fee Deduction", id: 74 },
  { name: "DEX", id: 75 },
  { name: "ERC2981", id: 76 },
  { name: "BlackList Feature", id: 77 },
  { name: "Proof of Signature", id: 78 },
  { name: "ERC2771", id: 79 },
  { name: "Rebasing/Elastic Token", id: 80 },
  { name: "ICO", id: 81 },
];

export const blockchains = [
  { name: "Ethereum", id: 1 },
  { name: "Tron", id: 2 },
  { name: "BSC", id: 4 },
  { name: "Polkadot", id: 5 },
  { name: "Polygon", id: 6 },
  { name: "Kucoin Chain", id: 7 },
  { name: "Terra", id: 8 },
  { name: "Flow Blockchain", id: 9 },
  { name: "Huobi Eco Chain", id: 10 },
  { name: "HSC", id: 11 },
  { name: "Optimistic Ethereum L2", id: 12 },
  { name: "Avalanche C Chain", id: 13 },
  { name: "Moonbeam", id: 14 },
  { name: "Arbitrum", id: 15 },
  { name: "Solana", id: 16 },
  { name: "Fantom", id: 17 },
  { name: "Moonriver", id: 18 },
  { name: "NEAR", id: 19 },
  { name: "EVMOS", id: 20 },
  { name: "Harmony", id: 21 },
];

// export const blockchains=['eth','btc']

// export const networks=[{label: 'Bsc Mainnet', key: 1},{label: 'Bsc Testnet', key: 2},{label: 'Bsc Mainnet', key: 3},
// {label: 'Ethereum Testnet', key: 4},
// {label: 'Ethereum Mainnet', key: 5},{label: 'Ropsten Testnet', key: 6},{label: 'Rinkeby Mainnet', key: 7},
// {label: 'Kovan Testnet', key: 8},{label: 'Goerli Testnet', key: 9}
// ,{label: 'Beacon Testnet', key: 10},{label: 'NILE Testnet', key: 11},
// {label: 'SHASTA Testnet', key: 12}
// ]
export const networks = [
  "Bsc Mainnet",
  "Bsc Testnet",
  "Ethereum Mainnet",
  "Ropsten Testnet(Ethereum)",
  "Rinkeby Testnet(Ethereum)",
  "Kovan Testnet(Ethereum)",
  "Goerli Testnet(Ethereum)",
  "Beacon Testnet(Ethereum)",
  "NILE Testnet(Tron)",
  "SHASTA Testnet(Tron)",
  "Tron Mainnet",
  "Kucoin Mainnet",
  "Kucoin Testnet",
  "Solana Testnet",
  "Solana Mainnet",
  "PolygonScan Testnet(Polygon)",
  "Polygon Mainnet",
  "Terra Testnet",
  "Terra Mainnet",
  "Flow Testnet",
  "Flow Mainnet",
  "Fantom Testnet",
  "Fantom Mainnet",
  "HecoChain Testnet",
  "HecoChain Mainnet",
  "HSC Testnet",
  "HSC Mainnet",
  "Arbitrum Testnet",
  "Arbitrum Mainnet",
  "Avalanche Testnet",
  "Avalanche Mainnet",
  "Moonbeam Testnet",
  "Moonbeam Mainnet",
  "Moonriver Testnet",
  "Moonriver Mainnet",
  "Optimistic Testnet",
  "Optimistic Mainnet",
  "NEAR Testnet",
  "NEAR Mainnet",
  "EVMOS Testnet",
  "EVMOS Mainnet",
  "Harmony Testnet",
  "Harmony Mainnet",
];

export const statusList = [
  "To Plan",
  "todo",
  "doing",
  "done",
  "Initial Audit Done",
  "Extra Reviews",
  "Design Doing",
  "Project Work",
  "On Hold",
];

export const buckets = [
  "QuillAudit",
  "QuillHash",
  // 'Tokenic',
  // 'DNFT',
  // 'QuillPlay',
  // 'QuillLabs'
];
export const permissions = [
  {
    name: "Auditor",
    value: "audit",
  },
  {
    name: "Static Data",
    value: "static_data",
  },
  {
    name: "Audit Contract",
    value: "audit_contract",
  },
];

export const countries = [
  { "name": "Afghanistan", "id": 1 },
  { "name": "Albania", "id": 2 },
  { "name": "Algeria", "id": 3 },
  { "name": "Andorra", "id": 4 },
  { "name": "Angola", "id": 5 },
  { "name": "Antigua and Barbuda", "id": 6 },
  { "name": "Argentina", "id": 7 },
  { "name": "Armenia", "id": 8 },
  { "name": "Australia", "id": 9 },
  { "name": "Austria", "id": 10 },
  { "name": "Azerbaijan", "id": 11 },
  { "name": "Bahamas", "id": 12 },
  { "name": "Bahrain", "id": 13 },
  { "name": "Bangladesh", "id": 14 },
  { "name": "Barbados", "id": 15 },
  { "name": "Belarus", "id": 16 },
  { "name": "Belgium", "id": 17 },
  { "name": "Belize", "id": 18 },
  { "name": "Benin", "id": 19 },
  { "name": "Bhutan", "id": 20 }, 
  { "name": "Bolivia", "id": 21 },
  { "name": "Bosnia and Herzegovina", "id": 22 }, 
  { "name": "Botswana", "id": 23 },
  { "name": "Brazil", "id": 24 },
  { "name": "Brunei", "id": 25 },
  { "name": "Bulgaria", "id": 26 },
  { "name": "Burkina Faso", "id": 27 },
  { "name": "Burundi", "id": 28 },
  { "name": "Cabo Verde", "id": 29 },
  { "name": "Cambodia", "id": 30 },
  { "name": "Cameroon", "id": 31 },
  { "name": "Canada", "id": 32 },
  { "name": "Central African Republic", "id": 33 },
  { "name": "Chad", "id": 34 },
  { "name": "Chile", "id": 35 },
  { "name": "China", "id": 36 },
  { "name": "Colombia", "id": 37 },
  { "name": "Comoros", "id": 38 },
  { "name": "Congo (Congo-Brazzaville)", "id": 39 },
  { "name": "Costa Rica", "id": 40 },
  { "name": "Croatia", "id": 41 },
  { "name": "Cuba", "id": 42 },
  { "name": "Cyprus", "id": 43 },
  { "name": "Czechia (Czech Republic)", "id": 44 },
  { "name": "Democratic Republic of the Congo (Congo-Kinshasa)", "id": 45 },
  { "name": "Denmark", "id": 46 },
  { "name": "Djibouti", "id": 47 },
  { "name": "Dominican Republic", "id": 48 },
  { "name": "Dominica", "id": 49 },
  { "name": "East Timor (Timor-Leste)", "id": 50 },
  { "name": "Ecuador", "id": 51 },
  { "name": "Egypt", "id": 52 },
  { "name": "Equatorial Guinea", "id": 53 },
  { "name": "El Salvador", "id": 54 },
  { "name": "Eritrea", "id": 55 },
  { "name": "Estonia", "id": 56 },
  { "name": "Eswatini", "id": 57 },
  { "name": "Ethiopia", "id": 58 },
  { "name": "Fiji", "id": 59 },
  { "name": "Finland", "id": 60 },
  { "name": "France", "id": 61 },
  { "name": "Gabon", "id": 62 },
  { "name": "Gambia", "id": 63 },
  { "name": "Georgia", "id": 64 },
  { "name": "Germany", "id": 65 },
  { "name": "Ghana", "id": 66 },
  { "name": "Greece", "id": 67 },
  { "name": "Grenada", "id": 68 },
  { "name": "Guatemala", "id": 69 },
  { "name": "Guinea", "id": 70 },
  { "name": "Guinea-Bissau", "id": 71 },
  { "name": "Guyana", "id": 72 },
  { "name": "Haiti", "id": 73 },
  { "name": "Honduras", "id": 74 },
  { "name": "Hungary", "id": 75 },
  { "name": "Iceland", "id": 76 },
  { "name": "India", "id": 77 },
  { "name": "Indonesia", "id": 78 },
  { "name": "Iran", "id": 79 },
  { "name": "Iraq", "id": 80 },
  { "name": "Ireland", "id": 81 },
  { "name": "Israel", "id": 82 },
  { "name": "Italy", "id": 83 },
  { "name": "Ivory Coast", "id": 84 },
  { "name": "Jamaica", "id": 85 },
  { "name": "Japan", "id": 86 },
  { "name": "Jordan", "id": 87 },
  { "name": "Kazakhstan", "id": 88 },
  { "name": "Kenya", "id": 89 },
  { "name": "Kiribati", "id": 90 },
  { "name": "Kosovo", "id": 91 },
  { "name": "Kuwait", "id": 92 },
  { "name": "Kyrgyzstan", "id": 93 },
  { "name": "Laos", "id": 94 },
  { "name": "Latvia", "id": 95 },
  { "name": "Lebanon", "id": 96 },
  { "name": "Lesotho", "id": 97 },
  { "name": "Liberia", "id": 98 },
  { "name": "Libya", "id": 99 },
  { "name": "Liechtenstein", "id": 100 },
  { "name": "Lithuania", "id": 101 },
  { "name": "Luxembourg", "id": 102 },
  { "name": "Madagascar", "id": 103 },
  { "name": "Malawi", "id": 104 },
  { "name": "Malaysia", "id": 105 },
  { "name": "Maldives", "id": 106 },
  { "name": "Mali", "id": 107 },
  { "name": "Malta", "id": 108 },
  { "name": "Marshall Islands", "id": 109 },
  { "name": "Mauritania", "id": 110 },
  { "name": "Mauritius", "id": 111 },
  { "name": "Mexico", "id": 112 },
  { "name": "Micronesia", "id": 113 },
  { "name": "Moldova", "id": 114 },
  { "name": "Monaco", "id": 115 },
  { "name": "Mongolia", "id": 116 },
  { "name": "Montenegro", "id": 117 },
  { "name": "Morocco", "id": 118 },
  { "name": "Mozambique", "id": 119 },
  { "name": "Myanmar (Burma)", "id": 120 },
  { "name": "Namibia", "id": 121 },
  { "name": "Nauru", "id": 122 },
  { "name": "Nepal", "id": 123 },
  { "name": "Netherlands", "id": 124 },
  { "name": "New Zealand", "id": 125 },
  { "name": "Nicaragua", "id": 126 },
  { "name": "Niger", "id": 127 },
  { "name": "Nigeria", "id": 128 },
  { "name": "North Macedonia", "id": 129 },
  { "name": "Norway", "id": 130 },
  { "name": "Oman", "id": 131 },
  { "name": "Pakistan", "id": 132 },
  { "name": "Palau", "id": 133 },
  { "name": "Palestine", "id": 134 },
  { "name": "Panama", "id": 135 },
  { "name": "Papua New Guinea", "id": 136 },
  { "name": "Paraguay", "id": 137 },
  { "name": "Peru", "id": 138 },
  { "name": "Philippines", "id": 139 },
  { "name": "Poland", "id": 140 },
  { "name": "Portugal", "id": 141 },
  { "name": "Qatar", "id": 142 },
  { "name": "Romania", "id": 143 },
  { "name": "Russia", "id": 144 },
  { "name": "Samoa", "id": 145 },
  { "name": "San Marino", "id": 146 },
  { "name": "Saudi Arabia", "id": 147 },
  { "name": "Senegal", "id": 148 },
  { "name": "Serbia", "id": 149 },
  { "name": "Seychelles", "id": 150 },
  { "name": "Singapore", "id": 151 },
  { "name": "Slovakia", "id": 152 },
  { "name": "Solomon Islands", "id": 153 },
  { "name": "Somalia", "id": 154 },
  { "name": "South Africa", "id": 155 },
  { "name": "South Sudan", "id": 156 },
  { "name": "Spain", "id": 157 },
  { "name": "Sri Lanka", "id": 158 },
  { "name": "Sudan", "id": 159 },
  { "name": "Sweden", "id": 160 },
  { "name": "Syria", "id": 161 },
  { "name": "Tajikistan", "id": 162 },
  { "name": "Thailand", "id": 163 },
  { "name": "Tanzania", "id": 164 },
  { "name": "Togo", "id": 165 },
  { "name": "Turkey", "id": 166 },
  { "name": "Tonga", "id": 167 },
  { "name": "Turkmenistan", "id": 168 },
  { "name": "Ukraine", "id": 169 },
  { "name": "UAE", "id": 170 },
  { "name": "United Arab Emirates", "id": 171 },
  { "name": "United Kingdom", "id": 172 },
  { "name": "United States", "id": 173 },
  { "name": "Uruguay", "id": 174 },
  { "name": "Uzbekistan", "id": 175 },
  { "name": "Vanuatu", "id": 178 },
  { "name": "Venezuela", "id": 179 },
  { "name": "Vietnam", "id": 180 },
  { "name": "Zambia", "id": 181 },
  { "name": "Yemen", "id": 182 },
];

export const status = [
  { "name": "To Do", "id": 1},
  { "name": "Awaiting Response", "id": 2},
  { "name": "In Contact", "id": 3},
  { "name": "Converted", "id": 4},
];
export const source = [
  { "name": "Development Firms", "id": 1},
  { "name": "PR & Marketing", "id": 2},
  { "name": "VC & Accelerators", "id": 3},
  { "name": "Influencers KOLs", "id": 4},
  { "name": "Referral Partners", "id": 5},
  { "name": "Market Makers", "id": 6},
  { "name": "Clubs & Communities", "id": 7},
  { "name": "CRM Prospects", "id":8},
  { "name": "QuillAdvocates", "id": 9},
  { "name": "Audit Clients", "id": 10},
  { "name": "Blockchains", "id": 11},
];
export const type = [
  { "name": "Clients", "id": 1},
  { "name": "Partners", "id": 2},
  { "name": "Influencers", "id": 3},
  { "name": "Prospects", "id": 4},
];
export const typeToId = {
  "Clients": 1,
  "Partners": 2,
  "Influencers": 3,
  "Prospects": 4,
};
export const idToType = {
  '1':"Clients",
  '2':"Partners",
  '3':"Influencers",
  '4':"Prospects",
};
export const rating = [
  { "name": "⭐️", "id": 1},
  { "name": "⭐️⭐️", "id": 2},
  { "name": "⭐️⭐️⭐️", "id": 3},
];
