import React,{useState} from "react";

function AuditContractCard({ isnew = false, onclose, onedit, showpopup }) {
    const [imagename,setImageName]=useState('');
    const [selected,setSelectedFile]=useState(null);
    const [auditedby,setAuditedBy]=useState('');
    const [text, setText] = useState(false);
    const handleSubmit=()=>{
  //sdsdsd
    }

    function copyText() {
      setText(true);
      var timer = setTimeout(() => {
        setText(false)
      }, 1500)
    }

    const handleClick = () => {
        document.getElementById('file-input').click();
      };
    
      const handleImageUpload = event => {

        setImageName(event.target.files[0].name)
        setSelectedFile(event.target.files[0]);
      };
    

    

  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          {isnew ? (
            <div className="form-group row align-items-center">
              <div className="col-md-4">
              <label htmlFor="auditreport" className="form-label">
              Client Name
              </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Client and Company Name"
                  autoFocus={true}
                />
              </div>
              <div className="custom-form col-md-2">
              <label htmlFor="auditreport" className="form-label">
               Authored By
              </label>
              <input type="text" className="form-control" name="auditreport" value={auditedby}
                  // disabled={el && !showpopup?true:false}
               onChange={(e)=>setAuditedBy(e.target.value)}  required/>
            </div>
            <div className="col">
            <label htmlFor="auditreport" className="form-label">
             Upload Smart Contract
              </label>
            <form onSubmit={handleSubmit}>
              <div className="input-group custom-group">
              
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary text-white"  onClick={() => handleClick()}>
                    <img src="/images/add-file.svg" alt="addfile " />
                  </span>
                 
                </div>
                <input type="text" type='text' required value={imagename} className="form-control"  onClick={() => handleClick()}/>
                <input type="text" type='file' required onChange={handleImageUpload} className="form-control" style={{ display: 'none' }}  id="file-input" />
              
                <div className="input-group-append ml-1">
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    type='submit'
                  >
                  

                    { true ?'Upload':<>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                   </>
                }

                  </button>
                </div>
           
              </div>
              </form>
            </div>
            </div>
          ) : (
            <div className="form-group row align-items-center">
                <div className="col-md-2">
                  <div className="blog-img">
                    {/* <img className="img-fluid" src="/images/quillhash.png" alt="" /> */}
                  </div>
                </div>
              <h4 className="h5 mb-0 col-md-3">Client Name</h4>
              {
                 <div className="col col-md-4">
                <label htmlFor="auditreport" className="form-label">
                  Smart Contract Url
                  </label>
                <form onSubmit={handleSubmit}>
                  <div className="input-group custom-group">
                  
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-primary text-white"  onClick={() => handleClick()}>
                        <img src="/images/add-file.svg" alt="addfile " />
                      </span>
                     
                    </div>
                    <input type="text" type='text' required value={imagename} className="form-control"  onClick={() => handleClick()} disabled/>
                    <input type="text" type='file' required onChange={handleImageUpload} className="form-control" style={{ display: 'none' }}  id="file-input" disabled/>
                  
                    <div className="input-group-append ml-1">
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        id="button-addon2"
                        type='submit'
                      >
                        { true ?'Upload':<>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       </>
                    }
    
                      </button> */}
                    </div>
               
                  </div>
                  </form>
                </div>
              }
            
        
   
            </div>
          )}

          <div className="form-group row align-items-end">
          <div className="custom-form col-md-8 d-flex flex-column">
              <label htmlFor="auditreport" className="form-label align-self-start">
              Solidity Code
              
              </label>
              <span>
              {/* <img
                  src="/images/copy.svg"
                  alt="copy"
                  className="h-1 ml-2"
                  role="button"
         
                  /> */}
              </span>
              
              <textarea  placeholder='Enter Solidity Code here' disabled  rows="4" cols="52" name="comment" form="usrform">
                  </textarea>
    
            </div>
            {/* <div className="custom-form col">
              <label htmlFor="auditreport" className="form-label">
               Solidity Code
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
              />
            </div> */}

          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default AuditContractCard;
