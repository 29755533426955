import React, { useState, useEffect } from "react";
import { clearStateData, createSubadminStart, uploadStaticStart, getRoleStart } from "../../redux/audit/Actions";
import { useDispatch, useSelector } from 'react-redux'
function ManageRoleComponent({ isnew = false, onclose, onedit, showpopup }) {
  const [email, setEmail] = useState('');
  const [listOfRoles, setListOfRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(listOfRoles[0]);
  const [password, setPassword] = useState('')
  const dispatch = useDispatch();
  const audit = useSelector(state => state.audit)
  const allPermissions = audit.roles;
  const { createsubadmin, roles } = audit

  useEffect(() => {
    dispatch(getRoleStart({}))
  }, [])

  useEffect(() => {
    setSelectedRole(listOfRoles[0])
  }, [listOfRoles])

  useEffect(() => {
    if (roles.data) {
      let roleList = []
      for (let role in roles.data) {
        roleList.push(roles.data[role].name)
      }
      setListOfRoles(roleList)
    }
  }, [roles])


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(allPermissions, "allPermissions", selectedRole);
    const permissions = allPermissions.data.filter((role) => role.name === selectedRole)[0].permissions
    const data = {
      email: email,
      password: password,
      role: selectedRole,
      permissions: permissions
    }
    dispatch(createSubadminStart(data))
  }


  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="form-group row align-items-end">
              <div className="col-md-4">
                <div className="custom-form">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input type="text" className="form-control" name="email" value={email}
                    onChange={(e) => setEmail(e.target.value)} required />
                </div>
              </div>

              <div className="col-md-4">

                <div className="custom-form">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input type="text" className="form-control" name="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} required />
                </div>
              </div>
              <div className="col-md-4">
                {listOfRoles.length && <div className="custom-form">
                  <label htmlFor="password" className="form-label">
                    Role
                  </label>
                  <select name="type" id="type" style={{ height: '38px', width: '100%' }} className='form-select' aria-label="Default select example" onChange={(e) => setSelectedRole(e.target.value)} value={selectedRole}>
                    {listOfRoles.map((role, index) => {
                      return <option value={role} selected={selectedRole === role ? true : false}>{role}</option>
                    })}
                  </select>
                </div>}
              </div>

            </div>
            {(isnew || showpopup) && (
              <div className="text-right">
                <button
                  className="btn btn-outline-primary btn-100"
                  onClick={onclose}
                >
                  Discard
                </button>
                <button
                  className="btn btn-outline-primary ml-3 btn-100"
                  type='submit'
                >
                  {!createsubadmin ? 'Save' : <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                  </>
                  }
                </button>
              </div>

            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default ManageRoleComponent;
