import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import JobsCard from "../../components/JobsCard/JobsCard";

function Jobs() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleNewJob = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Job Openings"
        btntitle="New Job"
        newbtnclick={toggleNewJob}
        isnew={isNew}
      />
      <div className="content-scroll">
        {isNew && <JobsCard isnew={isNew} onclose={toggleNewJob} />}
        {Array.from({ length: 3 }).map((item, i) => (
          <JobsCard key={i} onedit={handleEdit} />
        ))}
      </div>
      {showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <JobsCard onedit={handleEdit} showpopup={showPopup} />
        </Popup>
      )}
    </>
  );
}

export default Jobs;
