import React from "react";

function JobsCard({ isnew = false, onclose, onedit, showpopup }) {
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body">
          {isnew ? (
            <div className="form-group row align-items-center">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Job Role and Job Title"
                  autoFocus={true}
                />
              </div>

              <div className="col-auto ml-auto">
                <label htmlFor="customSwitch1" className="d-flex btn border">
                  Audited ?
                  <div className="custom-control custom-switch custom-control-inline ml-2 mr-0">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                    ></label>
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div className="form-group row align-items-center">
              <h4 className="h5 mb-0 col-auto col-md-3">Job Role, Job Title</h4>

              <div className="col-auto ml-auto">
                <button className="btn btn-primary ">Active</button>
              </div>
            </div>
          )}
          <div className="form-group row align-items-end">
            <div className="custom-form col">
              <label htmlFor="auditreport" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                name="auditreport"
              />
            </div>
          </div>
          <div className="form-group row align-items-end">
            <div className="col-md-2">
              <select name="" id="" className="custom-select">
                <option value="">Experience</option>
              </select>
            </div>
            <div className="col-md-2">
              <select name="" id="" className="custom-select">
                <option value="">Type</option>
              </select>
            </div>
            <div className="col-md-2">
              <select name="" id="" className="custom-select">
                <option value="">Location</option>
              </select>
            </div>
            <div className="col-md-2">
              <select name="" id="" className="custom-select">
                <option value="">Skills</option>
              </select>
            </div>

            <div className="col-md-2">
              <div className="input-group custom-group">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary text-white">
                    <img src="/images/email.svg" alt="email" />
                  </span>
                </div>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
          {(isnew || showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-primary btn-100"
                onClick={onclose}
              >
                Discard
              </button>
              <button
                className="btn btn-primary ml-3 btn-100"
                onClick={onclose}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {onedit && !showpopup && (
          <button className="btn btn-more" onClick={onedit}>
            <img src="/images/more.svg" alt="more" />
          </button>
        )}
      </div>
    </>
  );
}

export default JobsCard;
