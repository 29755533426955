import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import Loader from "../../components/LoadingSpinner/Loader";
import { getUserDetailsStart } from "../../redux/audit/Actions";
import { ChangePassword } from "./ChangePassword";
import { Permissions } from "./Permissions";

const Profile = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const audit = useSelector((state) => state.audit);
  const { userdetails, getuserdetailsloading } = audit;
  useEffect(() => {
    dispatch(getUserDetailsStart({}));
  }, []);

  useEffect(() => {
    if (userdetails) {
      console.log(userdetails);
      setData(userdetails.data[0]);
    }
  }, [userdetails]);

  return (
    <>
      <Header
        title="Profile"
        btntitle="Update Password"
        newbtnclick={togglePopup}
        isnew={showPopup}
      />
      <div className="content-scroll">
        {!getuserdetailsloading && <Loader />}

        {getuserdetailsloading && data ? (
          <div className="card-body">
            <div className="form-group row align-items-end">
              <div className="col-md-4">
                <div className="custom-form">
                  <label htmlFor="createdFor" className="form-label">
                    Admin Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    value={data.adminType}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-form">
                  <label htmlFor="createdFor" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    value={data.local && data.local.email}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <h4>Permissions</h4>
            {data.permissions ? <Permissions data={data} /> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
      {showPopup && <ChangePassword handleClose={togglePopup} />}
    </>
  );
};

export default Profile;
