import { useEffect } from "react";

const Reset = () => {
    localStorage.clear();
    useEffect(() => {
        console.log("Reset");
        localStorage.clear();
    }, []);
    return <div>
        Reset successful
    </div>;
};

export default Reset;