import React from "react";

function Landing() {
    return (
        <div className="block m-auto">
            <div className="text-2xl mx-auto max-w-fit font-extrabold">Welcome {localStorage.getItem("adminName")}!</div>
            <br />
            Click on the buttons on the left sidebar to navigate to the respective pages.
        </div >
    )
}

export default Landing;
