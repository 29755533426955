import React, { useState, useEffect } from "react";
import {
  updatePublicApikeyStart,
  generatePublicApikeyStart,
} from "../../redux/audit/Actions";
import { useDispatch, useSelector } from "react-redux";
function ApiRoleManagementPublic({
  isnew = false,
  onclose,
  onedit,
  showpopup,
  el,
}) {
  const [apikey, setapikey] = useState("");
  const [created_for, setcreated_for] = useState("");
  const [created_by, setcreated_by] = useState("");
  const [active, setActive] = useState(false);
  const [name, setName] = useState(false);
  const [address, setAddress] = useState(false);
  const [blockchain, setBlockchain] = useState(false);
  const [tag, setTag] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [status, setStatus] = useState(false);
  const [notes, setNotes] = useState(false);
  const [auditedBy, setAuditedBy] = useState(false);
  const [network, setNetwork] = useState(false);
  const [image, setImage] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [auditReportGitLink, setAuditReportGitLink] = useState(false);
  const [auditReportDescription, setAuditReportDescription] = useState(false);
  const [clientWebsiteUrl, setClientWebsiteUrl] = useState(false);
  const [source_code, setSource_code] = useState(false);
  const [coinId, setCoinId] = useState(false);

  const dispatch = useDispatch();
  const audit = useSelector((state) => state.audit);
  const { createsubadmin } = audit;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      api_key: apikey,
      name: name,
      active: active,
      address: address,
      blockchain: blockchain,
      tag: tag,
      featured: featured,
      status: status,
      notes: notes,
      auditedBy: auditedBy,
      network: network,
      image: image,
      certificate: certificate,
      startDate: startDate,
      endDate: endDate,
      auditReportGitLink: auditReportGitLink,
      auditReportDescription: auditReportDescription,
      clientWebsiteUrl: clientWebsiteUrl,
      source_code: source_code,
      coinId: coinId
    };
    if (isnew) {
      dispatch(generatePublicApikeyStart(data));
      setTimeout(() => {
        onclose();
      }, 1000);
    } else {
      dispatch(updatePublicApikeyStart(data));
      setTimeout(() => {
        onedit();
      }, 1000);
    }
  };

  useEffect(() => {
    if (el) {
      setapikey(el.api_key);
      setcreated_for(el.created_for);
      setcreated_by(el.created_by);
      setName(el.name);
      setActive(el.active);
      setAddress(el.address);
      setBlockchain(el.blockchain);
      setTag(el.tag);
      setFeatured(el.featured);
      setStatus(el.status);
      setNotes(el.notes);
      setAuditedBy(el.auditedBy);
      setNetwork(el.network);
      setImage(el.image);
      setCertificate(el.certificate);
      setStartDate(el.startDate);
      setEndDate(el.endDate);
      setAuditReportGitLink(el.auditReportGitLink);
      setAuditReportDescription(el.auditReportDescription);
      setClientWebsiteUrl(el.clientWebsiteUrl);
      setSource_code(el.source_code);
      setCoinId(el.coinId);
    }
  }, [el]);

  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="form-group row align-items-end">
              <div className="col-md-6">
                <div className="custom-form">
                  <label htmlFor="created_for" className="form-label">
                    Created For
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    value={created_for}
                    onChange={(e) => setcreated_for(e.target.value)}
                    required
                    disabled={isnew ? false : true}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="custom-form">
                  <label htmlFor="created_for" className="form-label">
                    Created By
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    value={created_by}
                    onChange={(e) => setcreated_by(e.target.value)}
                    required
                    disabled={isnew ? false : true}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Active
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={active}
                    onClick={(e) => setActive(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Name
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={name}
                    onClick={(e) => setName(e.target.checked)}
                  />
                </div>
              </div>

              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Address
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={address}
                    onClick={(e) => setAddress(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Blockchain
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={blockchain}
                    onClick={(e) => setBlockchain(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Tag
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={tag}
                    onClick={(e) => setTag(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Featured
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={featured}
                    onClick={(e) => setFeatured(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Status
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={status}
                    onClick={(e) => setStatus(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Notes
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={notes}
                    onClick={(e) => setNotes(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  AuditedBy
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={auditedBy}
                    onClick={(e) => setAuditedBy(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Network
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={network}
                    onClick={(e) => setNetwork(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Image
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={image}
                    onClick={(e) => setImage(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Certificate
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={certificate}
                    onClick={(e) => setCertificate(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Start Date
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={startDate}
                    onClick={(e) => setStartDate(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  End Date
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={endDate}
                    onClick={(e) => setEndDate(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Audit Report Git Link
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={auditReportGitLink}
                    onClick={(e) => setAuditReportGitLink(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Audit Report Description
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={auditReportDescription}
                    onClick={(e) => setAuditReportDescription(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Client Website Url
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={clientWebsiteUrl}
                    onClick={(e) => setClientWebsiteUrl(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  Source Code
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={source_code}
                    onClick={(e) => setSource_code(e.target.checked)}
                  />
                </div>
              </div>
              <div className="custom-form col-md-2 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label align-self-start"
                >
                  CoinId
                </label>

                <div className="ml-1">
                  <input
                    type="checkbox"
                    checked={coinId}
                    onClick={(e) => setCoinId(e.target.checked)}
                  />
                </div>
              </div>
            </div>
            {(isnew || showpopup) && (
              <div className="text-right">
                <button
                  className="btn btn-outline-primary btn-100"
                  onClick={onclose}
                >
                  Discard
                </button>
                <button className="btn btn-outline-primary ml-3 btn-100" type="submit">
                  {!createsubadmin ? (
                    "Save"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default ApiRoleManagementPublic;
