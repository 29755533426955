import React from 'react'

export const LinkElement=()=>{
    return(
        <div className='d-flex flex-row'>
        <div className="input-group custom-group">
                    <input type="text" className="form-control small-input" />
         </div>
        <div className="">
                    <div className="input-group custom-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-primary text-white">
                        <img src="/images/world.svg" alt="world" />
                        </span>
                    </div>
                    <input type="text" className="form-control small-input" />
                    </div>
        </div>
        <div className="">
                     <div className="input-group custom-group">
                     <div className="input-group-prepend">
                         <span className="input-group-text bg-primary text-white">
                         <img src="/images/world.svg" alt="world" />
                         </span>
                     </div>
                     <input type="text" className="form-control small-input" />
                     </div>
            
         </div>
        </div>
    )
}