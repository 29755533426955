import { render } from "@testing-library/react";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import DatePicker from "react-datepicker";
import ReportCard from "../../components/ReportCard/ReportCard";
import {
  clearStateData,
  fetchAuditStart,
  searchAuditStart,
} from "../../redux/audit/Actions";
import { AuditApis } from "../../redux/Api";

import {
  blockchains,
  networks,
  tags,
  statusList,
  countries
} from "../../utils/dropdowndata";

import { debounceApi } from "../../utils/common";
import Loader from "../../components/LoadingSpinner/Loader";

function AuditReports(props) {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const [currentreport, setCurrentReport] = useState([]);
  const [pagev, setPageV] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { audit } = props;
  const { reports, auditloading, auditadded } = audit;
  const [filter, setFilter] = useState(false);
  const [selnetwork, setSelNetwork] = useState("");
  const [selblockchain, setSelBlockChain] = useState("");
  const [selCountry, setSelCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [queryStr, setQueryStr] = useState("");

  const toggleNewAuditReport = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };
  // startDate && moment.utc(startDate).format(),
  // endDate && moment.utc(endDate).format()
  const handleFilter = () => {
    const data = {
      page: page,
      limit: 10,
      //network: selnetwork ? selnetwork : null,
      status: queryStr ? queryStr : null,
      blockchain: selblockchain ? selblockchain : null,
      country: selCountry ? selCountry:null,
      startDate: startDate && moment.utc(startDate).format(),
      endDate: endDate && moment.utc(endDate).format(),
    };
    setFilter(false);
    dispatch(fetchAuditStart(data));
  };

  const handleEdit = () => {
    togglePopup();
  };

  useEffect(() => {
    handleFilter();
  }, [page]);

  const fetchAudit = (q) => {
    const data = {
      query_string: q.trim(),
    };
    if (q.trim()) {
      dispatch(searchAuditStart(data));
    } else {
      dispatch(fetchAuditStart({}));
    }
  };
  useEffect(() => {
    if (auditadded) {
      toggleNewAuditReport();
      dispatch(clearStateData());
    }
  }, [auditadded]);

  return (
    <>
      <Header
        title="QuillAudit Reports"
        btntitle="New Audit Report"
        newbtnclick={toggleNewAuditReport}
        isnew={isNew}
        search={search}
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        apicall={fetchAudit}
        statusSelect={true}
      />
      {/* .filter(el=>el.name.includes(search)) */}
      <div className="content-scroll">
        {isNew && <ReportCard isnew={isNew} onclose={toggleNewAuditReport} />}
        {auditloading && <Loader />}
        {!auditloading &&
          audit.reports &&
          audit.reports.data &&
          audit.reports.data.map((el, index) => {

            return (
              <ReportCard
                key={el._id}
                index={index}
                onedit={handleEdit}
                el={el}
                setCurrentReport={setCurrentReport}
                blockchains={blockchains}
                //networks={networks}
                tags={tags}
                statusList={statusList}
              />
            );
          })}
      </div>
      {showPopup && (
        <Popup size={"xxl"} onclose={togglePopup}>
          <ReportCard
            onedit={handleEdit}
            showpopup={showPopup}
            el={currentreport}
            setCurrentReport={setCurrentReport}
            blockchains={blockchains}
            //networks={networks}
            tags={tags}
            statusList={statusList}
          />
        </Popup>
      )}
      {filter && (
        <Popup size={"md"} onclose={() => setFilter(false)}>
          <div>
            <div className="d-flex justify-content-around align-items-apart h-100 flex-wrap">
              <div className="card d-flex">
                <div style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    Blockchain
                  </label>
                  <select
                    name="blockchains"
                    id="blockchains"
                    style={{ height: "38px", maxWidth: "200px" }}
                    className="form-select"
                    value={selblockchain}
                    onChange={(e) => {
                      if (e.target.value !== "Select Blockchain") {
                        setSelBlockChain(e.target.value);
                      } else {
                        setSelBlockChain(null);
                      }
                    }}
                  >
                    <option value={null} selected>
                      Select Blockchain
                    </option>
                    {blockchains.map((el, i) => {
                      return (
                        <option
                          value={`${el.name}`}
                          selected={selblockchain === el.name ? true : false}
                        >
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    Country
                  </label>
                  <select
                    name="countries"
                    id="countries"
                    style={{ height: "38px", maxWidth: "200px" }}
                    className="form-select"
                    value={selCountry}
                    onChange={(e) => {
                      if (e.target.value !== "Select Country") {
                        setSelCountry(e.target.value);
                      } else {
                        setSelCountry(null);
                      }
                    }}
                  >
                    <option value={null} selected>
                      Select Country
                    </option>
                    {countries.map((el, i) => {
                      return (
                        <option
                          value={`${el.name}`}
                          selected={selCountry === el.name ? true : false}
                        >
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                
                <div className="" style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    Start Date
                  </label>
                  <DatePicker
                    className="custom-select"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </div>
              <div className="card ">
                <div style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    Status
                  </label>
                  <select
                    class="form-control rounded"
                    name="status"
                    id="status"
                    style={{ height: "38px", width: "100%" }}
                    onChange={(e) => setQueryStr(e.target.value)}
                    value={queryStr}
                  >
                    <option value="" selected>
                      Select Status
                    </option>
                    {statusList.map((el, i) => {
                      return <option value={`${el}`}>{el}</option>;
                    })}
                  </select>
                </div>
                {/*<div className="" style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    Network
                  </label>
                  <select
                    name="networks"
                    id="network"
                    style={{ height: "38px", maxWidth: "200px" }}
                    className="form-select"
                    value={selnetwork}
                    aria-label="Default select example"
                    onChange={(e) => {
                      if (e.target.value !== "Select Network") {
                        setSelNetwork(e.target.value);
                      } else {
                        setSelNetwork(null);
                      }
                    }}
                  >
                    <option value={null} selected>
                      Select Network
                    </option>
                    {networks.map((el, i) => {
                      return (
                        <option
                          value={`${el}`}
                          selected={selnetwork === el ? true : false}
                        >
                          {el}
                        </option>
                      );
                    })}
                  </select>
                  </div>*/}

                <div style={{ maxWidth: "150px" }}>
                  <label htmlFor="auditreport" className="form-label">
                    End Date
                  </label>
                  <DatePicker
                    className="custom-select"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>

              <div className="card "></div>
            </div>
            <button
              className="btn btn-primary btn-md mx-auto d-block mt-3"
              onClick={handleFilter}
            >
              Submit
            </button>
          </div>
        </Popup>
      )}
      <div>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item   ${pagev == 0 ? "disabled" : ""}`}>
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() => setPageV(0)}
              >
                First
              </a>
            </li>

            <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() => setPageV((pr) => pr - 1)}
              >
                Previous
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => setPage(pagev + 1)}
              >
                {pagev + 1}
              </a>
            </li>
            {reports && reports.total_record > 10 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={() => setPage(pagev + 2)}
                >
                  {pagev + 2}
                </a>
              </li>
            )}
            {reports && reports.total_record > 20 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={() => setPage(pagev + 3)}
                >
                  {pagev + 3}
                </a>
              </li>
            )}

            <li
              className={`page-item ${
                Math.ceil(reports && reports.total_record / 10) == pagev + 3
                  ? "disabled"
                  : reports && reports.total_record < 20
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className={`page-link  `}
                href="#"
                onClick={() => setPageV((pr) => pr + 1)}
              >
                Next
              </a>
            </li>

            <li
              className={`page-item  ${
                Math.ceil(reports && reports.total_record / 10) === pagev + 3
                  ? "disabled"
                  : reports && reports.total_record < 20
                  ? "disabled"
                  : ""
              }`}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={() =>
                  setPageV(Math.ceil(reports && reports.total_record / 10) - 3)
                }
              >
                Last
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  audit: state.audit,
});
export default connect(mapStateToProps)(AuditReports);
