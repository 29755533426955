import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import AuditContractCard from "../../components/AuditContractCard/AuditContractCard";
import ContractPopup from "../../components/AuditContractCard/ContractPopup";

function AuditContract() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleNewTestimonial = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Quill Relay"
        btntitle="New Contract"
        newbtnclick={toggleNewTestimonial}
        isnew={isNew}
      />
      <div className="content-scroll">
        {/* {isNew && (
          <AuditContractCard isnew={isNew} onclose={toggleNewTestimonial} />
        )} */}
        {Array.from({ length: 2 }).map((item, i) => (
          <AuditContractCard key={i} onedit={handleEdit} />
        ))}
      </div>
      {showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <ContractPopup onedit={handleEdit} showpopup={showPopup} />
        </Popup>
      )}
      {isNew && (
        <Popup size={"xl"} onclose={toggleNewTestimonial}>
          <ContractPopup
            onedit={handleEdit}
            showpopup={isNew}
            onedit={toggleNewTestimonial}
          />
        </Popup>
      )}
    </>
  );
}

export default AuditContract;
