import React, { useState, useEffect } from "react";
import { deleteQuoteStart, quoteListingStart } from "../../redux/audit/Actions";
import { columns } from "./data";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../Table/Table";
import moment from "moment";

function QuoteListingCard({ onedit }) {
  const quotelisting = useSelector((state) => state.audit.quotelisting);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [row, setRow] = useState(10);
  const fetchIdRef = React.useRef(0);
  const quoteListingLoading = useSelector(
    (state) => state.audit.quotelistingLoading
  );
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);
    if (fetchId === fetchIdRef.current) {
      setPage(pageIndex + 1);
      setRow(pageSize);
      setLoading(false);
    }
  }, []);

  const dispatch = useDispatch();
  const customColumns = React.useMemo(() => columns, []);

  useEffect(() => {
    dispatch(quoteListingStart({ limit: row, page: page }));
  }, [page, row]);

  const handleDelete = async (id) => {
    await dispatch(deleteQuoteStart(id));
  };

  useEffect(() => {
    if (quotelisting) {
      const newArr = quotelisting.data.map((el) => {
        return {
          ...el,
          created_at: (
            <div>{moment(el.created_at).format("DD-MM-YYYY HH:MM a")}</div>
          ),
          email: <a href={`mailto:${el.email}`}>{el.email}</a>,
          contact: el.contact && el.contact !== "null" ? el.contact : "-",
          gitLink: el.gitLink ? (
            <a target="_blank" href={`${el.gitLink}`}>
              {el.gitLink ? "Link" : "-"}
            </a>
          ) : (
            "-"
          ),
          action: (
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(el._id)}
            >
              Delete
            </button>
          ),
        };
      });
      setTableData(newArr);
      setTotalRecord(quotelisting.totalRecord);
      setPageCount(Math.ceil(quotelisting.totalRecord / row));
    }
  }, [quotelisting]);

  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>
        <div className="card-body  d-flex justify-content-center ">
          <div style={{ overflow: "auto" }}>
            {tableData ? (
              <CustomTable
                columns={customColumns}
                data={tableData}
                fetchData={fetchData}
                pageCount={pageCount}
                loading={quoteListingLoading}
                totalRecord={totalRecord}
              />
            ) : (
              <h5
                className="m-3 d-flex align-items-center"
                style={{ height: "200px" }}
              >
                No Data Found
              </h5>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuoteListingCard;
