const base_url = process.env.REACT_APP_BACKEND_URL;
//const base_url = "http://localhost:5656";

export const AuditApis = {
  LOGIN: `${base_url}/api/v1/erp_audit/admin/login/local`,
  GET_AUDITS: `${base_url}/api/v1/erp_audit/admin/get/audit_report`,
  UPDATE_AUDITS: `${base_url}/api/v1/erp_audit/admin/update/audit_report`,
  UPLOAD_TO_IPFS: `${base_url}/api/v1/erp_audit/admin/get/uploadToIPFS`,
  ADD_AUDITS: `${base_url}/api/v1/erp_audit/admin/create/audit_report`,
  DELETE_AUDITS: `${base_url}/api/v1/erp_audit/admin/update/audit_report`,
  UPLOAD_LOGO: `${base_url}/api/v1/erp_audit/admin/upload/audit_report/project_logo`,
  UPLOAD_CERTIFICATE: `${base_url}/api/v1/erp_audit/admin/upload/audit_report/certificate`,
  STATIC_UPLOAD_AUDIT: `${base_url}/api/v1/erp_audit/admin/upload/auditaudit`,
  STATIC_UPLOAD_QUILL: `${base_url}/api/v1/erp_audit/admin/upload/quillwebsite`,
  CREATE_SUBADMIN: `${base_url}/api/v1/erp_audit/admin/create/subadmin`,
  SUBADMIN_PERMISSION: `${base_url}/api/v1/erp_audit/admin/manage/permission`,
  VIEW_SUBADMIN: `${base_url}/api/v1/erp_audit/admin/subadmin`,
  SEARCH_AUDIT: `${base_url}/api/v1/erp_audit/admin/get/audit_report_NandA`,
  DELETE_SUBADMIN: `${base_url}/api/v1/erp_audit/admin/subadmin`,
  LOGOUT: `${base_url}/api/v1/erp_audit/admin/logout`,
  QUOTE_LISTING: `${base_url}/api/v1/erp_audit/admin/get/estimated_pricing`,
  DELETE_QUOTE_LISTING: `${base_url}/api/v1/erp_audit/admin/delete/price/`,
  AUDIT_LISTING: `${base_url}/api/v1/erp_audit/admin/get/audit_quote`,
  DELETE_AUDIT_LISTING: `${base_url}/api/v1/erp_audit/admin/delete/audit_quote/`,

  GET_API_KEYS: `${base_url}/api/v1/erp_audit/admin/APIKEY`,
  UPDATE_API_KEY: `${base_url}/api/v1/erp_audit/admin/APIKEY/permission`,
  GENERATE_API_KEY: `${base_url}/api/v1/erp_audit/admin/APIKEY/generate`,
  GET_DETAILS: `${base_url}/api/v1/erp_audit/admin/get_details`,
  UPDATE_PASSWORD: `${base_url}/api/v1/erp_audit/admin/update_password`,

  GET_PUBLIC_API_KEYS: `${base_url}/api/v1/erp_audit/admin/publicApiKey/all`,
  UPDATE_PUBLIC_API_KEY: `${base_url}/api/v1/erp_audit/admin/publicApiKey/update`,
  GENERATE_PUBLIC_API_KEY: `${base_url}/api/v1/erp_audit/admin/publicApiKey/generate`,

  UPLOAD_CODE_COUNT_LINE: `${base_url}/api/v1/erp_audit/admin/post/countLinesText`,
  COUNT_LINES_LINK: `${base_url}/api/v1/erp_audit/admin/post/countFilesInLink`,
  COUNT_LINES_IN_FILE: `${base_url}/api/v1/erp_audit/admin/post/countLinesInFile`,

  GET_ROLE: `${base_url}/api/v1/erp_audit/get/role`,
  CREATE_ROLE: `${base_url}/api/v1/erp_audit/create/role`,
  DELETE_ROLE: `${base_url}/api/v1/erp_audit/delete/role/`,
};
