import React, { useState } from "react";
import AddImage from "./AddImage";
import axios from "axios";
import { toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../components/ToastMessage/SuccessToast";

function Hacks(props) {
  const [showPopup, setShowPopup] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`https://erp-api.quillhash.com/api/v1/erp_audit/hackerboard/delete_img/${props.data._id}`);
      console.log(response.data); // print the deleted hack object without the image URL
      if (response) {
        window.location.reload();
        toast.success(<SuccessToast message="Image uploaded successfully" />)

      }
      // You can also reload the component here to see the updated hack object
    } catch (error) {
      window.location.reload();
      toast.error(<ErrorToast message="Failed to upload the Image" />)

      console.error(error);
    }
  }

  return (
    <div className="relative flex w-full flex-col items-center justify-center gap-4 rounded-md bg-[#D5EBF6] px-8 py-12 text-black shadow-md md:px-24 height" style={{ height: "600px" }}>
      <div>
        <h2 className="text-2xl font-bold">{props.data.name}</h2>
      </div>
      {props.data.img_url && props.data.img_url !== "" && (
        <div style={{ position: "relative" }}>
          <img
            src={props.data.img_url}
            alt="My Image"
            style={{ height: "200px" }}
          />
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              cursor: "pointer"
            }}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
      )}
      {showDeleteConfirmation && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 opacity-50 z-30"></div>
          <div className="z-40 bg-white rounded-md shadow-md p-4">
            <h3 className="text-lg font-bold mb-4">Delete Image</h3>
            <p className="mb-4">Are you sure you want to delete this image?</p>
            <div className="flex justify-end">
              <button
                className="font-bold text-secondary mr-4"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                No
              </button>
              <button
                className="font-bold text-secondary"
                onClick={() => {
                  handleDelete();
                  // handle the delete action here
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row flex-wrap items-center justify-center gap-2">
        <div>
          <div className="rounded-full bg-slate-600 px-2 py-1 text-xs text-slate-200 md:px-4">
            {props.data.category[0]}
          </div>
        </div>
      </div>
      <div>
        <p>
          The {props.data.name} on{" "}
          <span className="font-bold">{props.data.blockchain[0]}</span> was compromised. It was
          a {props.data.category[0]} that {props.data.amount_in_dollar ? `led to a loss of $${props.data.amount_in_dollar}` : "The exact amount of loss is currently unknown"}.
        </p>
      </div>
      <div className="flex w-full flex-row items-center justify-between md:w-1/2">
        <h3 className="text-base font-bold md:text-xl">{props.data.amount_in_dollar ? `Loss incurred - $${props.data.amount_in_dollar}` : "Loss incurred - Unavailable"}</h3>
        <h3 className="text-base font-bold md:text-xl">{props.data.month} {props.data.day_of_month}, {props.data.year}</h3>
      </div>
      <a
        className="font-bold text-secondary"
        target="_blank"
        href={props.data.post_mortem_report}
        rel="noreferrer"
      >
        Read More &gt;
      </a>
      {permissions && permissions["hackerboard"].create && (
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center">
            <button
              className="font-bold text-secondary"
              onClick={() => {
                setShowPopup(true);
              }}
            >
              {props.data.img_url && props.data.img_url !== "" ? "Update Image" : "Add Image"}
            </button>
          </div>
          {showPopup && (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 opacity-50 z-30"></div>
              <div className="z-40 bg-white rounded-md shadow-md p-4">
                <h3 className="text-lg font-bold mb-4">Add Image</h3>
                <AddImage
                  Hackid={props.data._id}
                  name={props.data.name}
                  id={`add-image-${props.data._id}`}
                />
                <button
                  className="font-bold text-secondary mt-4"
                  onClick={() => setShowPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Hacks;
