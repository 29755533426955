import React,{useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function ContractPopup({ isnew = false, onclose, onedit, showpopup }) {
    const [imagename,setImageName]=useState('');
    const [selected,setSelectedFile]=useState(null);
    const [auditedby,setAuditedBy]=useState('');
    const [text, setText] = useState(false);

    const handleSubmit=()=>{
  //sdsdsd
    }


    const handleClick = () => {
        document.getElementById('file-input').click();
      };
    
      const handleImageUpload = event => {

        setImageName(event.target.files[0].name)
        setSelectedFile(event.target.files[0]);
      };
    


  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `} style={{overflow:'auto'}}>
          <h2 className='text-center '>Solidity Code</h2>
     <Tabs >
    <TabList>
      <Tab>Paste Code</Tab>
      <Tab>Upload Code</Tab>
    </TabList>

    <TabPanel>
     <div style={{maxHeight:'600px'}}>
     <div className="custom-form col d-flex flex-column">
              <label htmlFor="auditreport" className="form-label align-self-start">
              Solidity Code 
              </label>
              <span>
    
              </span>
              
              <textarea  rows="20" cols="52"  className="form-control" style={{minWidth: "100%"}}  placeholder='Enter Solidity Code here'  
              >
               </textarea>
    
            </div>
     </div>
    </TabPanel>
    <TabPanel>
     <div style={{height:'600px'}}>
    <div className="co mx-auto" style={{maxWidth:'400px'}}>
                <label htmlFor="auditreport" className="form-label">
                 Upload Smart Contract
                  </label>
                <form onSubmit={handleSubmit}>
                  <div className="input-group custom-group">
                  
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-primary text-white"  onClick={() => handleClick()}>
                        <img src="/images/add-file.svg" alt="addfile " />
                      </span>
                     
                    </div>
                    <input type="text" type='text' required value={imagename} className="form-control"  onClick={() => handleClick()}/>
                    <input type="text" type='file' required onChange={handleImageUpload} className="form-control" style={{ display: 'none' }}  id="file-input" />
                  
                    <div className="input-group-append ml-1">
                      <button
                        className="btn btn-primary"
                        type="button"
                        id="button-addon2"
                        type='submit'
                      >
                        { true ?'Upload':<>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       </>
                    }
    
                      </button>
                    </div>
               
                  </div>
                  </form>
                </div>
                </div>
    </TabPanel>
  </Tabs>
  <button className='btn btn-primary btn-md mx-auto' style={{maxWidth:'200px'}}>Get Audit Report</button>
      </div>
    </>
  );
}

export default ContractPopup;
