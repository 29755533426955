const INITIALSTATE = {
  smartcontracts: [],
};
export const SmartContractReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default SmartContractReducer;
