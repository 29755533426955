import createKeccakHash from "keccak";

export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};
export const shortAddress = (address) => {
  const pre = address.substr(0, 6);
  const last = address.substr(-4);
  return `${pre}...${last}`;
};
export const debounceApi = (delay, fncall) => {
  let time;
  return function (...args) {
    if (time) clearTimeout(time);
    time = setTimeout(() => {
      fncall.apply(this, args);
    }, delay);
  };
};

export const isValidString = (name) => {
  if (/^[^-\s][a-zA-Z0-9_\s-]+$/.test(name)) return true;
  else return false;
};

export const isValidNetwork= (name) => {
  if (/^[a-zA-Z() ]*$/.test(name)) return true;
  else return false;
};

export const isValidNote = (note) => {
  if (/^[^-\s][a-zA-Z_!._,'@?//s\s-]+$/.test(note)) return true;
  else return false;
};

export const isValidArray = (array) => {
  if (array >= 1) return true;
  else return false;
};

export const isValidUri = (uri) => {
  try {
    new URL(uri);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidAddress = (address) => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    return true;
  } else {
    return isChecksumAddress(address);
  }
};

const isChecksumAddress = (address) => {
  address = address.replace("0x", "");

  var addressHash = createKeccakHash("keccak256")
    .update(address.toLowerCase())
    .digest("hex");
  for (var i = 0; i < 40; i++) {
    if (
      (parseInt(addressHash[i], 16) > 7 &&
        address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 &&
        address[i].toLowerCase() !== address[i])
    ) {
      return false;
    }
  }
  return true;
};
