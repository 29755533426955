import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/Header/Header'
import ManageRoleComponent from '../../components/ManageRole/ManageRole'
import ViewRole from '../../components/ManageRole/ViewRole';
import NewRole from '../../components/ManageRole/NewRole'
import { clearStateData, viewSubadminStart } from '../../redux/audit/Actions';
import Popup from "../../components/Popup/Popup";
import Loader from '../../components/LoadingSpinner/Loader';
import ViewAllRoles from '../../components/ManageRole/ViewAllRoles';
const ManageRoles = () => {
  const [isNew, setIsNew] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [newrole, setNewRole] = useState(false);
  const [pagev, setPageV] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const [currentrole, setCurrentRole] = useState([]);
  const audit = useSelector(state => state.audit);
  const { subadmins, subadminloading, subadmincreated, permissionloaded } = audit;
  const toggleNewStaticData = () => {
    setIsNew((prev) => !prev);
  };

  const toggleNewRole = () => {
    setNewRole((prev) => !prev);
  };

  useEffect(() => {
    const data = {
      page: page,
      limit: 10
    }
    dispatch(viewSubadminStart(data))

  }, [page])


  useEffect(() => {
    if (subadmincreated) {
      console.log(audit);
      setNewRole(true);
      dispatch(clearStateData())
    }
  }, [subadmincreated])

  useEffect(() => {
    if (permissionloaded) {
      togglePopup();
      dispatch(clearStateData())
    }
  }, [permissionloaded])

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Manage Roles"
        btntitle="Manage Role"
        newbtnclick={toggleNewStaticData}
        isnew={isNew}
      />

      {/* For different types of roles */}
      <div>
        <button className="btn btn-outline-primary btn-100 text-lg ml-3 mt-1" onClick={toggleNewRole}> {!newrole ? <>Add New Roles</> : <>Close</>} </button>
        {newrole && <NewRole onclose={toggleNewRole} />}
        <ViewAllRoles />
      </div>

      <div className="content-scroll">
        {isNew && <ManageRoleComponent isnew={isNew} onclose={toggleNewStaticData} />}
        {
          subadminloading && <Loader />
        }

        {
          !subadminloading && subadmins && subadmins.data && subadmins.data.map((el, i) => <ViewRole el={el} setCurrentRole={setCurrentRole} onedit={handleEdit} key={i} />)
        }
        {showPopup && (
          <Popup size={"xxl"} onclose={togglePopup}>
            <ViewRole onedit={handleEdit} onclose={togglePopup} showpopup={showPopup} el={currentrole} setCurrentRole={setCurrentRole} />
          </Popup>
        )}
        {
          !subadminloading && <div>

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className={`page-item   ${pagev == 0 ? 'disabled' : ''}`}>
                  <a className="page-link" href="#" tabIndex="-1" onClick={() =>
                    setPageV(0)
                  }>First</a>
                </li>

                <li className={`page-item  ${pagev == 0 ? 'disabled' : ''}`}>
                  <a className="page-link" href="#" tabIndex="-1" onClick={() =>
                    setPageV(pr => pr - 1)

                  }>Previous</a>
                </li>
                <li className="page-item"><a className="page-link" href="#" onClick={() => setPage(pagev + 1)}>{pagev + 1}</a></li>
                {
                  subadmins && subadmins.total_record > 10 && <li className="page-item"><a className="page-link" href="#" onClick={() => setPage(pagev + 2)}>{pagev + 2}</a></li>
                }
                {
                  subadmins && subadmins.total_record > 20 && <li className="page-item"><a className="page-link" href="#" onClick={() => setPage(pagev + 3)}>{pagev + 3}</a></li>
                }

                <li className={`page-item ${Math.ceil(subadmins && subadmins.total_record / 10) == pagev + 3 ? 'disabled' : subadmins && subadmins.total_record < 20 ? 'disabled' : ''}`}>
                  <a className={`page-link  `} href="#" onClick={() => setPageV(pr => pr + 1)}>Next</a>
                </li>

                <li className={`page-item  ${Math.ceil(subadmins && subadmins.total_record / 10) === pagev + 3 ? 'disabled' : subadmins && subadmins.total_record < 20 ? 'disabled' : ''}`}>
                  <a className="page-link" href="#" tabIndex="-1" onClick={() =>
                    setPageV(Math.ceil(subadmins && subadmins.total_record / 10) - 3)
                  }>Last</a>
                </li>
              </ul>
            </nav>
          </div>
        }




      </div>


    </>
  )
}

export default ManageRoles