import { auditActionTypes } from "../audit/Types";

const INITIALSTATE = {
  smartcontracts: [],
  loading: false,
  reports: [],
  updated: false,
  auditloading: false,
  logoloading: false,
  certificateloading: false,
  auditadding: false,
  createdurl: "",
  subadmins: [],
  roleadded: false,
  roles: [],
  createsubadmin: false,
  logindata: null,
  permissionadded: false,
  permissionloaded: false,
  subadminloading: false,
  auditadded: false,
  subadmincreated: false,
  staticuploaded: false,
  quotelisting: null,
  quotelistingLoading: false,
  auditlisting: null,
  auditlistingLoading: false,
  deleteauditlistingLoading: false,
  getapikey: null,
  getApikeyloading: false,
  updateApikeyloading: false,
  generateApikeyloading: false,
  userdetails: null,
  userRole: null,
  getuserdetailsloading: false,
  updatepasswordloading: false,
  deletequoteLoading: false,
  uploadedToIPFS: false,
  getpublicapikey: null,
  getpublicApikeyloading: false,
  generatePublicApikeyloading: false,
  updatePublicApikeyloading: false,
  Hacks: null,
  HackImgUrl: "",
  HackImageUploaded: false,
};
export const auditReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case auditActionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case auditActionTypes.FETCH_AUDIT_START:
      return {
        ...state,
        auditloading: true,
      };
    case auditActionTypes.SEARCH_AUDIT_START:
      return {
        ...state,
        auditloading: true,
      };

    case auditActionTypes.FETCH_AUDIT_SUCCESS:
    case auditActionTypes.SEARCH_AUDIT_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        auditloading: false,
      };
    case auditActionTypes.UPLOAD_LOGO_START:
      return {
        ...state,
        logoloading: true,
      };
    case auditActionTypes.UPLOAD_CERTIFICATE_START:
      return {
        ...state,
        certificateloading: true,
      };
    case auditActionTypes.UPLOAD_LOGO_SUCCESS:
    case auditActionTypes.UPLOAD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificateloading: false,
        logoloading: false,
      };
    case auditActionTypes.UPDATE_AUDIT_SUCCESS:
      return {
        ...state,
        updated: !state.updated,
      };
    case auditActionTypes.UPLOAD_TO_IPFS_SUCCESS:
      return {
        ...state,
        uploadedToIPFS: !state.uploadedToIPFS,
      };
    case auditActionTypes.FETCH_AUDIT_FAILURE:
    case auditActionTypes.SEARCH_AUDIT_FAILURE:
      return {
        ...state,
        reports: [],
        auditloading: false,
      };
    case auditActionTypes.ADD_AUDIT_START:
      return {
        ...state,
        auditadding: true,
        auditadded: false,
      };
    case auditActionTypes.ADD_AUDIT_SUCCESS:
      return {
        ...state,
        auditadding: false,
        auditadded: true,
      };

    case auditActionTypes.ADD_AUDIT_FAILURE:
      return {
        ...state,
        auditadding: false,
      };
    case auditActionTypes.SUBADMIN_PERMISSION_START:
      return {
        ...state,
        permissionadded: true,
        permissionloaded: false,
      };
    case auditActionTypes.SUBADMIN_PERMISSION_SUCCESS:
      return {
        ...state,
        permissionloaded: true,
        permissionadded: false,
      };
    case auditActionTypes.SUBADMIN_PERMISSION_FAILURE:
      return {
        ...state,
        permissionadded: false,
      };
    case auditActionTypes.UPLOAD_STATIC_START:
      return {
        ...state,
        staticuploaded: true,
      };
    case auditActionTypes.UPLOAD_STATIC_SUCCESS:
      return {
        ...state,
        createdurl: action.payload.url,
        staticuploaded: false,
      };
    case auditActionTypes.UPLOAD_STATIC_FAILURE:
      return {
        ...state,
        staticuploaded: false,
      };
    case auditActionTypes.UPLOAD_HACK_IMG:
      return {
        ...state,
        HackImageUploaded: true,
      }
    case auditActionTypes.UPLOAD_HACK_IMG_SUCCESS:
      return {
        ...state,
        HackImgUrl: action.payload.url,
        HackImageUploaded: false,
      }
    case auditActionTypes.UPLOAD_HACK_IMG_FAILURE:
      return {
        ...state,
        HackImageUploaded: false,
      }
    case auditActionTypes.VIEW_SUBADMIN_START:
      return {
        ...state,
        subadminloading: true,
      };
    case auditActionTypes.VIEW_SUBADMIN_SUCCESS:
      return {
        ...state,
        subadmins: action.payload,
        subadminloading: false,
      };
    case auditActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logindata: action.payload,
      };
    case auditActionTypes.CREATE_SUBADMIN_START:
      return {
        ...state,
        createsubadmin: true,
        subadmincreated: false,
      };
    case auditActionTypes.CREATE_SUBADMIN_SUCCESS:
      return {
        ...state,
        createsubadmin: false,
        subadmincreated: true,
      };
    case auditActionTypes.CREATE_SUBADMIN_FAILURE:
      return {
        ...state,
        createsubadmin: false,
      };

    case auditActionTypes.QUOTE_LISTING_START:
      return {
        ...state,
        quotelistingLoading: true,
      };
    case auditActionTypes.QUOTE_LISTING_SUCCESS:
      return {
        ...state,
        quotelisting: action.payload,
        quotelistingLoading: false,
      };
    case auditActionTypes.QUOTE_LISTING_FAILURE:
      return {
        ...state,
        quotelistingLoading: false,
      };
    case auditActionTypes.AUDIT_LISTING_START:
      return {
        ...state,
        auditlistingLoading: true,
      };
    case auditActionTypes.AUDIT_LISTING_SUCCESS:
      return {
        ...state,
        auditlisting: action.payload,
        auditlistingLoading: false,
      };
    case auditActionTypes.AUDIT_LISTING_FAILURE:
      return {
        ...state,
        auditlistingLoading: false,
        auditlisting: null,
      };

    case auditActionTypes.DELETE_QUOTE_START:
      return {
        ...state,
        deletequoteLoading: true,
      };
    case auditActionTypes.DELETE_QUOTE_SUCCESS:
      return {
        ...state,

        deletequoteLoading: false,
      };
    case auditActionTypes.DELETE_QUOTE_FAILURE:
      return {
        ...state,
        deletequoteLoading: false,
        deletequote: null,
      };

    case auditActionTypes.GET_API_KEY_START:
      return {
        ...state,
        getApikeyloading: true,
      };
    case auditActionTypes.GET_API_KEY_SUCCESS:
      return {
        ...state,
        getapikey: action.payload,
        getApikeyloading: false,
      };
    case auditActionTypes.GET_API_KEY_FAILURE:
      return {
        ...state,
        getApikeyloading: false,
        getapikey: null,
      };

    case auditActionTypes.UPDATE_API_KEY_START:
      return {
        ...state,
        updateApikeyloading: true,
      };
    case auditActionTypes.UPDATE_API_KEY_SUCCESS:
      return {
        ...state,
        updateApikeyloading: false,
      };
    case auditActionTypes.UPDATE_API_KEY_FAILURE:
      return {
        ...state,
        updateApikeyloading: false,
      };

    case auditActionTypes.GENERATE_API_KEY_START:
      return {
        ...state,
        generateApikeyloading: true,
      };
    case auditActionTypes.GENERATE_API_KEY_SUCCESS:
      return {
        ...state,

        generateApikeyloading: false,
      };
    case auditActionTypes.GENERATE_API_KEY_FAILURE:
      return {
        ...state,
        generateApikeyloading: false,
      };

    case auditActionTypes.GET_PUBLIC_API_KEY_START:
      return {
        ...state,
        getpublicApikeyloading: true,
      };
    case auditActionTypes.GET_PUBLIC_API_KEY_SUCCESS:
      return {
        ...state,
        getpublicapikey: action.payload,
        getpublicApikeyloading: false,
      };
    case auditActionTypes.GET_PUBLIC_API_KEY_FAILURE:
      return {
        ...state,
        getpublicApikeyloading: false,
        getpublicapikey: null,
      };
    case auditActionTypes.GENERATE_PUBLIC_API_KEY_START:
      return {
        ...state,
        generatePublicApikeyloading: true,
      };
    case auditActionTypes.GENERATE_PUBLIC_API_KEY_SUCCESS:
      return {
        ...state,

        generatePublicApikeyloading: false,
      };
    case auditActionTypes.GENERATE_PUBLIC_API_KEY_FAILURE:
      return {
        ...state,
        generatePublicApikeyloading: false,
      };
    case auditActionTypes.UPDATE_PUBLIC_API_KEY_START:
      return {
        ...state,
        updatePublicApikeyloading: true,
      };
    case auditActionTypes.UPDATE_PUBLIC_API_KEY_SUCCESS:
      return {
        ...state,
        updatePublicApikeyloading: false,
      };
    case auditActionTypes.UPDATE_PUBLIC_API_KEY_FAILURE:
      return {
        ...state,
        updatePublicApikeyloading: false,
      };
    case auditActionTypes.UPDATE_PASSWORD_START:
      return {
        ...state,
        updatepasswordloading: true,
      };
    case auditActionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,

        updatepasswordloading: false,
      };
    case auditActionTypes.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatepasswordloading: false,
      };

    case auditActionTypes.GET_USER_DETAILS_START:
      return {
        ...state,
        getuserdetailsloading: true,
      };
    case auditActionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userdetails: action.payload,
        getuserdetailsloading: false,
      };
    case auditActionTypes.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getuserdetailsloading: false,
      };

    case auditActionTypes.DELETE_AUDIT_QUOTE_START:
      return {
        ...state,
        deleteauditlistingLoading: true,
      };
    case auditActionTypes.DELETE_AUDIT_QUOTE_SUCCESS:
    case auditActionTypes.DELETE_AUDIT_QUOTE_FAILURE:
      return {
        ...state,
        deleteauditlistingLoading: false,
      };

    case auditActionTypes.LOGIN_FAILURE:
    case auditActionTypes.UPDATE_AUDIT_FAILURE:
    case auditActionTypes.UPLOAD_LOGO_FAILURE:
    case auditActionTypes.UPLOAD_CERTIFICATE_FAILURE:
    case auditActionTypes.VIEW_SUBADMIN_FAILURE:
    case auditActionTypes.DELETE_SUBADMIN_SUCCESS:
    case auditActionTypes.DELETE_SUBADMIN_FAILURE:
      return {
        ...state,
        auditloading: false,
        loading: false,
        certificateloading: false,
        logoloading: false,
        subadminloading: false,
      };
    case auditActionTypes.LOGOUT:
      return {
        state: undefined,
      };
    case auditActionTypes.CLEAR_STATE_DATA:
      return {
        ...state,
        auditAdded: false,
        createdurl: "",
        roleadded: false,
        auditadded: false,
        subadmincreated: false,
        permissionloaded: false,
        HackImgUrl: "",
      };

    case auditActionTypes.COUNT_LINES_LINK_START:
      return {
        ...state,
        countLines: "Loading...",
      };
    case auditActionTypes.COUNT_LINES_LINK_SUCCESS:
      return {
        ...state,
        countLines: action.payload.linesOfCode,
      };
    case auditActionTypes.COUNT_LINES_LINK_FAILURE:
      return {
        ...state,
        countLines: 0,
      };
    case auditActionTypes.COUNT_LINES_IN_FILE_START:
      return {
        ...state,
        countLines: "Loading...",
      };
    case auditActionTypes.COUNT_LINES_IN_FILE_SUCCESS:
      return {
        ...state,
        countLines: action.payload.data,
      };
    case auditActionTypes.COUNT_LINES_IN_FILE_FAILURE:
      return {
        ...state,
        countLines: 0,
      };
    case auditActionTypes.COUNT_LINES_TEXT_START:
      return {
        ...state,
        countLines: "Loading...",
      };
    case auditActionTypes.COUNT_LINES_TEXT_SUCCESS:
      return {
        ...state,
        countLines: action.payload.data,
      };
    case auditActionTypes.COUNT_LINES_TEXT_FAILURE:
      return {
        ...state,
        countLines: 0,
      };

    case auditActionTypes.CREATE_ROLE_START:
      return {
        ...state
      };
    case auditActionTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roleadded: true,
      };
    case auditActionTypes.CREATE_ROLE_FAILURE:
      return {
        ...state,
        roleadded: false,
      };
    case auditActionTypes.GET_ROLE_START:
      return {
        ...state,
      };

    case auditActionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case auditActionTypes.GET_ROLE_FAILURE:
      return {
        ...state,
      };

    case auditActionTypes.DELETE_ROLE_START:
      return {
        ...state,
      };

    case auditActionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
      };

    case auditActionTypes.DELETE_ROLE_FAILURE:
      return {
        ...state,
      };

    case auditActionTypes.SET_USER_ROLE_START:
      return {
        ...state,
      };

    case auditActionTypes.SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: action.payload,
      };

    case auditActionTypes.SET_USER_ROLE_FAILURE:
      return {
        ...state,
      };
    case auditActionTypes.FETCH_HACKS:
      return {
        ...state,
      };
    case auditActionTypes.FETCH_HACKS_SUCCESS:
      return {
        ...state,
        Hacks: action.payload.data,
      };
    case auditActionTypes.FETCH_HACKS_FAILURE:
      return {
        ...state,
        Hacks: null,
      };
    default:
      return { ...state };
  }
};

export default auditReducer;
