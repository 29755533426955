import React, { useState, useEffect } from "react";
import { subadminPermissionStart, deleteSubAdminStart, createSubadminStart } from "../../redux/audit/Actions";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'

import { AiOutlineSync } from 'react-icons/ai'
function ViewRole({ isnew = false, onclose, onedit, showpopup, el, setCurrentRole }) {
  const [permission] = useState('audit');
  const [view, setView] = useState(false);
  const [create, setCreate] = useState(false);
  const [deletep, setDeletePer] = useState(false)
  const dispatch = useDispatch();
  const audit = useSelector(state => state.audit)
  const allPermissions = audit.roles;
  const handleSync = () => {
    console.log('inside sync', allPermissions, el);
    const permissions = allPermissions.data.filter((role) => role.name === el.local.role)[0].permissions
    const data = {
      email: el.local.email,
      password: "verysecretpassworddonotuseit",
      role: el.local.role,
      permissions: permissions
    }
    dispatch(createSubadminStart(data))
  }
  useEffect(() => {
    if (el && el.permissions) {
      for (let element in el.permissions) {
        if (element === permission) {
          setView(el.permissions[element].view)
          setCreate(el.permissions[element].create)
          setDeletePer(el.permissions[element].delete)
        }
      } console.log(el)
    }
  }, [permission, el])

  const handleDelete = () => {
    onclose();
    const data = {
      "email": el.local.email
    }
    dispatch(deleteSubAdminStart(data))
  }
  return (
    <>
      <div className={`card mb-3 ${onedit ? "pr-4" : ""} `}>

        <div className="card-body">
          <div className="form-group row align-items-end">
            <div className="col-md-3">
              <div className="custom-form">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input type="text" className="form-control" name="email" value={el.local.email}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom-form">
                <label htmlFor="email" className="form-label">
                  Account Verified
                </label>
                <input type="text" className="form-control" name="email" value={el.accountVerified.isVerified ? 'Yes' : 'No'}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom-form">
                <label htmlFor="email" className="form-label">
                  Created At
                </label>
                <input type="text" className="form-control" name="email" value={moment(el.created_at).format('YYYY-MM-DD')}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom-form">
                <label htmlFor="email" className="form-label">
                  Admin Type
                </label>
                <input type="text" className="form-control" name="email" value={el.adminType}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom-form">
                <label htmlFor="email" className="form-label">
                  Role
                </label>
                <input type="text" className="form-control" name="email" value={el.local.role}
                  disabled={el && !showpopup ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="form-group row align-items-end">
            <div className="col-md-4">
              {/* <div className="custom-form">
              <label htmlFor="email" className="form-label">
                   Permissions
              </label>
              <select name="blockchain" id="blockchain" style={{height:'38px',width:'100%'}} className='form-select' aria-label="Default select example">
              {
                  permissions.map((el,i)=>{
                    return(
                      <option value={`${el.name}`} key={i} selected={permission===el.value?true:false} onClick={()=>setPermission(el.value)}>{el.name}</option>
                    )
                  })
                }
              </select>
            </div> */}
            </div>

            <div className="col-md-8">
              <div className='row'>
                {/* <div className="custom-form col-md-4 d-flex flex-column ">
              <label htmlFor="password" className="form-label align-self-start">
                   View
              </label>
              <div className=" ml-1">
                      <input
                        type="checkbox"
                       defaultChecked={
                            view
                        }
                        onClick={e => setView(e.target.checked)}
                         disabled={el && !showpopup?true:false}
                      />
                    
                      </div>
            </div> */}
                <div className="custom-form col-md-4  hidden flex-column">
                  <label htmlFor="password" className="form-label align-self-start ">
                    Create
                  </label>
                  <div className=" ml-1">
                    <input
                      type="checkbox"

                      defaultChecked={
                        create
                      }
                      checked={create}
                      onClick={e => setCreate(e.target.checked)}
                      disabled={el && !showpopup ? true : false}
                    />

                  </div>
                </div>
                {
                  permission !== 'static_data' && <div className="custom-form col-md-4 hidden flex-column">
                    <label htmlFor="password" className="form-label align-self-start">
                      Delete
                    </label>

                    <div className="ml-1">
                      <input
                        type="checkbox"
                        defaultChecked={
                          deletep
                        }
                        checked={deletep}
                        onClick={e => setDeletePer(e.target.checked)}
                        disabled={el && !showpopup ? true : false}
                      />

                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
          {(showpopup) && (
            <div className="text-right">
              <button
                className="btn btn-outline-danger btn-100"
                onClick={handleDelete}

              >
                Delete
              </button>
            </div>

          )}
          {onedit && !showpopup && (
            <>
              <button className="btn btn-more" onClick={() => (setCurrentRole(el), onedit())}>
                <img src="/images/more.svg" alt="more" />
              </button>
              <button className="btn" onClick={handleSync}>
                <AiOutlineSync />
              </button>
            </>
          )}
        </div>

      </div>
    </>
  );
}

export default ViewRole;
