import React from "react";

export const Permissions = ({ data }) => {
  return (
    <div>
      {data.permissions.API ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"API"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.API.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.API.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.API.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.audit ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Audit"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.audit_contract ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Audit Contract"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit_contract.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit_contract.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.audit_contract.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.client_partner ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Client and Partner"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.client_partner.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.client_partner.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.client_partner.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.events ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Events"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.events.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.events.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.events.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.smart_contract ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Smart Contract"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.smart_contract.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.smart_contract.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.smart_contract.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.static_data ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Static Data"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.static_data.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.static_data.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.static_data.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {data.permissions.testimonial ? (
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Testimonial"}
                required
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.testimonial.view}
                readonly
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.testimonial.create}
                readonly
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                checked={data.permissions.testimonial.delete}
                readonly
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
