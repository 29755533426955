import React, { useState, useEffect } from 'react';
import List from './List';
import { countries, idToType, rating, source, status, type, typeToId } from '../../utils/dropdowndata';
import { toast } from "react-toastify";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";

function Docs() {
    const [items, setItems] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [searchName, setSearchName] = useState("");
    const [searchCity, setSearchCity] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedSource, setSelectedSource] = useState("");
    const [selectedRating, setSelectedRating] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [pagev, setPageV] = useState(0);
    const [page, setPage] = useState(1);

    const limit = 10;

    useEffect(() => {
        fetchItems();
    }, [page, searchName, selectedCountry, selectedRating, selectedType, selectedStatus, searchCity, selectedSource]);

    const handleSearchChange = (e) => {
        setPageV(0);
        setPage(1);
        setSearchName(e.target.value);
    };
    const handleSearchCityChange = (e) => {
        setPageV(0);
        setPage(1);
        setSearchCity(e.target.value);
    };
    const handleCountryChange = (e) => {
        setPageV(0);
        setPage(1);
        setSelectedCountry(e);
    };
    const handleStatusChange = (e) => {
        setPageV(0);
        setPage(1);
        setSelectedStatus(e);
    };
    const handleSourceChange = (e) => {
        setPageV(0);
        setPage(1);
        setSelectedSource(e);
    };
    const handleRatingChange = (e) => {
        setPageV(0);
        setPage(1);
        setSelectedRating(e);
    };
    const handleTypeChange = (e) => {
        setPageV(0);
        setPage(1);
        setSelectedType(e);
    };

    const fetchItems = async () => {
        try {
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/docs?limit=${limit}&page=${page}`;
            if (searchName && searchName !== '') url+=`&name=${searchName}`;
            if (searchCity && searchCity !== '') url+=`&city=${searchCity}`;
            if (selectedCountry && selectedCountry !== '') url+=`&country=${selectedCountry}`;
            if (selectedRating && selectedRating !== '') url+=`&rating=${selectedRating}`;
            if (selectedStatus && selectedStatus !== '') url+=`&status=${selectedStatus}`;
            if (selectedSource && selectedSource !== '') url+=`&source=${selectedSource}`;
            if (selectedType && selectedType !== '' && typeToId[selectedType]) url+=`&type=${typeToId[selectedType]}`;
            const response = await fetch(url);
            const data = await response.json();
            setItems(data.data);
            setTotalRecord(data.totalRecord);
            toast.success(<SuccessToast message="Data Fetched SucessFully" />);

        } catch (error) {
            toast.error(<ErrorToast message="Error Fetching Data" />);

            console.error(error);
        }
    };
    async function saveAsExcel() {
        try {
            toast.success(<SuccessToast message="Data Export Started" />);
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/erp_audit/docs?limit=${totalRecord}&page=1`;
            if (searchName && searchName !== '') url+=`&name=${searchName}`;
            if (searchCity && searchCity !== '') url+=`&city=${searchCity}`;
            if (selectedCountry && selectedCountry !== '') url+=`&country=${selectedCountry}`;
            if (selectedRating && selectedRating !== '') url+=`&rating=${selectedRating}`;
            if (selectedStatus && selectedStatus !== '') url+=`&status=${selectedStatus}`;
            if (selectedSource && selectedSource !== '') url+=`&source=${selectedSource}`;
            if (selectedType && selectedType !== '' && typeToId[selectedType]) url+=`&type=${typeToId[selectedType]}`;
            const response = await fetch(url);
            const data = await response.json();
            if (data.data && data.data.length > 0) {
                const filteredData = data.data.map((e) => ({
                    Name: e.name,
                    City: e.city,
                    Country: e.country,
                    Status: e.status,
                    Email: e.email,
                    Source: e.source,
                    Telegram: e.telegram,
                    LinkedIn: e.linkedIn,
                    Twitter: e.twitter,
                    Rating: e.rating,
                    Type: e.type ? idToType[e.type] : null,
                    Website: e.website,
                }));
                let header = ["Name", "City","Country", "Status", "Email","Source","Telegram","LinkedIn","Twitter","Rating","Type","Website"];
                const ws = XLSX.utils.book_new();
                XLSX.utils.sheet_add_aoa(ws, [header]);
                XLSX.utils.sheet_add_json(ws, filteredData, { origin: 'A2', skipHeader: true });
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles:true });
                const finalData = new Blob([excelBuffer], { type: 'application/octet-stream' });
                saveAs(finalData, "Data.xlsx");     
                toast.success(<SuccessToast message="Data Exported SucessFully" />);       
            } else {
                toast.error(<ErrorToast message="No Data To Be Exported" />);
            }
        } catch (error) {
            toast.error(<ErrorToast message="Error Exporting Data" />);
        }
    }
    return (
        <>
            <div className="input-list-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="list-container" style={{ marginTop: '20px' }}>
                    <h2 className="text-center h3">QA Docs Dashboard</h2>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <input
                            type="text"
                            placeholder="Name"
                            value={searchName}
                            onChange={handleSearchChange}
                            style={{ width: '120px'}}
                        />
                        <input
                            type="text"
                            placeholder="City"
                            value={searchCity}
                            onChange={handleSearchCityChange}
                            style={{ width: '120px'}}

                        />
                        <select value={selectedCountry} 
                            style={{ width: '120px'}}
                            onChange={(e)=> {
                                if (e.target.value !== "Country") {
                                    handleCountryChange(e.target.value);
                                } else {
                                   handleCountryChange(null);
                                }
                            }
                        }>
                            <option value={null} selected>
                                Country
                            </option>
                            {countries.map((el) => {
                                return <option
                                    value={`${el.name}`}
                                    selected={selectedCountry === el.name ? true : false}
                                >
                                    {el.name}
                                </option>
                            })};
                        </select>
                        <select value={selectedSource} style={{ width: '120px'}}
                            onChange={(e)=> {
                                if (e.target.value !== "Source") {
                                    handleSourceChange(e.target.value);
                                } else {
                                   handleSourceChange(null);
                                }
                            }
                        }>
                        <option value={null} selected>
                                Source
                            </option>
                            {source.map((el) => {
                                return <option
                                    value={`${el.name}`}
                                    selected={selectedStatus === el.name ? true : false}
                                >
                                    {el.name}
                                </option>
                            })};
                        </select>
                        <select value={selectedStatus} style={{ width: '120px'}} onChange={(e)=> {
                                if (e.target.value !== "Status") {
                                    handleStatusChange(e.target.value);
                                } else {
                                   handleStatusChange(null);
                                }
                            }
                        }>
                        <option value={null} selected>
                                Status
                            </option>
                            {status.map((el) => {
                                return <option
                                    value={`${el.name}`}
                                    selected={selectedStatus === el.name ? true : false}
                                >
                                    {el.name}
                                </option>
                            })};
                        </select>
                        <select value={selectedType} style={{ width: '120px'}} onChange={(e)=> {
                                if (e.target.value !== "Type") {
                                    handleTypeChange(e.target.value);
                                } else {
                                   handleTypeChange(null);
                                }
                            }
                        }>
                        <option value={null} selected>
                                Type
                            </option>
                            {type.map((el) => {
                                return <option
                                    value={`${el.name}`}
                                    selected={selectedType === el.name ? true : false}
                                >
                                    {el.name}
                                </option>
                            })};
                        </select>
                        <select value={selectedRating}  style={{ width: '120px'}} onChange={(e)=> {
                                if (e.target.value !== "Rating") {
                                    handleRatingChange(e.target.value);
                                } else {
                                   handleRatingChange(null);
                                }
                            }
                        }>
                        <option value={null} selected>
                                Rating
                            </option>
                            {rating.map((el) => {
                                return <option
                                    value={`${el.name}`}
                                    selected={selectedRating === el.name ? true : false}
                                >
                                    {el.name}
                                </option>
                            })};
                        </select>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent: 'flex-end' }}>
                    <button onClick={saveAsExcel} disabled={!items || items.length <= 0} style={{ fontWeight: 'bold' }}>Export to Excel</button>
                    </div>
                    {items && items.length > 0 ? (
                    <ul className="item-list">
                        {items.map((item, index) => (
                            <li key={index} className="item">
                                <List name={item.name || ''} company={item.company || ''} email={item.email || ''} twitter={item.twitter || ''} linkedIn={item.linkedIn || ''}
                                    telegram={item.telegram || ''} website={item.website || ''} city={item.city || ''} country={item.country || ''} status={item.status || ''}
                                    type={item.type? idToType[String(item.type)] : ''} rating={item.rating || ''} source={item.source || ''} />
                            </li>
                        ))}
                    </ul>
                    ) : (
                        <div >
                            No item in the docs
                        </div>
                    )}
                </div>
            </div>
            <div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center" style={{ marginBottom: '1px' }}>
                        <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() => setPageV(0)}
                            >
                                First
                            </a>
                        </li>

                        <li className={`page-item  ${pagev == 0 ? "disabled" : ""}`}>
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() => setPageV((pr) => pr - 1)}
                            >
                                Previous
                            </a>
                        </li>
                        <li className="page-item">
                            <a
                                className="page-link"
                                href="#"
                                onClick={() => setPage(pagev + 1)}
                            >
                                {pagev + 1}
                            </a>
                        </li>
                        {items && totalRecord > 10 && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => setPage(pagev + 2)}
                                >
                                    {pagev + 2}
                                </a>
                            </li>
                        )}
                        {items && totalRecord > 20 && (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => setPage(pagev + 3)}
                                >
                                    {pagev + 3}
                                </a>
                            </li>
                        )}

                        <li
                            className={`page-item ${Math.ceil(items && totalRecord / 10) == pagev + 3
                                    ? "disabled"
                                    : items && totalRecord < 20
                                        ? "disabled"
                                        : ""
                                }`}
                        >
                            <a
                                className={`page-link  `}
                                href="#"
                                onClick={() => setPageV((pr) => pr + 1)}
                            >
                                Next
                            </a>
                        </li>

                        <li
                            className={`page-item  ${Math.ceil(items && totalRecord / 10) === pagev + 3
                                    ? "disabled"
                                    : items && totalRecord < 20
                                        ? "disabled"
                                        : ""
                                }`}
                        >
                            <a
                                className="page-link"
                                href="#"
                                tabIndex="-1"
                                onClick={() =>
                                    setPageV(Math.ceil(items && totalRecord / 10) - 3)
                                }
                            >
                                Last
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <h5 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    marginBottom: '15px',
                    }}
                >
                    Total Count: {totalRecord}
                </h5>
            </div>
        </>
    );


}

export default Docs;
