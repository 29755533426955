import { combineReducers } from "redux";
import SmartContractReducer from "../containers/SmartContracts/SmartContractReducer";
import auditReducer from "./audit/Reducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  createFilter,
} from 'redux-persist-transform-filter';
const saveUserFilter = createFilter('audit', ['logindata']);


const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['audit'],
  transforms: [saveUserFilter],
};

const appReducer = combineReducers({
  smartcontracts: SmartContractReducer,
  audit:auditReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default persistReducer(rootPersistConfig, rootReducer);

