export const columns = [
  {
    Header: "Blockchain",
    accessor: "blockchain",
  },
  {
    Header: "Complexity",
    accessor: "complexity",
  },
  {
    Header: "Contract Type",
    accessor: "contractType",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "LOC",
    accessor: "loc",
  },
  {
    Header: "Developed",
    accessor: "developed",
  },

  {
    Header: "Git Link",
    accessor: "gitLink",
  },
  {
    Header: "Created At",
    accessor: "created_at",
  },
  {
    Header: "Timeline",
    accessor: "timeline",
  },
  {
    Header: "Contact",
    accessor: "contact",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
