import React, {useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import {Form, Button} from 'react-bootstrap'
import {loginStart} from "../../redux/audit/Actions";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state => state.audit.loading));

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      email: email,
      password: password
    }
    dispatch(loginStart(data))
  }

  return (
    <div className="m-auto" style={{maxWidth: '300px'}}>
      <img className='w-100 mb-3' src="/images/logo_blue.png" alt="Logo"/>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control

            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            required
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"

            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="outline-primary" block size="lg" type="submit" disabled={!validateForm() && loading}>
          {!loading ? 'Login' : <>
            <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
          </>
          }

        </Button>
      </Form>
    </div>
  );
}

export default Login
