import React, { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { createRoleStart, deleteRoleStart } from "../../redux/audit/Actions";

function NewRole({ editRole, rollname, rollID, permissions }) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("permissions", permissions);
  }
    , []);

  const formik = useFormik({
    initialValues: {
      name: "",
      permissions: [
        {
          name: "Audit",
          read: editRole ? permissions["audit"].read : false,
          write: editRole ? permissions["audit"].write : false,
          delete: editRole ? permissions["audit"].delete : false,
        },
        {
          name: "API",
          read: editRole ? permissions["API"].read : false,
          write: editRole ? permissions["API"].write : false,
          delete: editRole ? permissions["API"].delete : false,
        },
        {
          name: "Relay",
          read: editRole ? permissions["relay"].read : false,
          write: editRole ? permissions["relay"].write : false,
          delete: editRole ? permissions["relay"].delete : false,
        },
        {
          name: "Smart Contract",
          read: editRole ? permissions["smart_contract"].read : false,
          write: editRole ? permissions["smart_contract"].write : false,
          delete: editRole ? permissions["smart_contract"].delete : false,
        },
        {
          name: "Security Check",
          read: editRole ? permissions["security"].read : false,
          write: editRole ? permissions["security"].write : false,
          delete: editRole ? permissions["security"].delete : false,
        },
        {
          name: "Listing",
          read: editRole ? permissions["listing"].read : false,
          write: editRole ? permissions["listing"].write : false,
          delete: editRole ? permissions["listing"].delete : false,
        },
        {
          name: "Static Data",
          read: editRole ? permissions["static_data"].read : false,
          write: editRole ? permissions["static_data"].write : false,
          delete: editRole ? permissions["static_data"].delete : false,
        },
        {
          name: "Hackerboard",
          read: editRole ? permissions["hackerboard"].read : false,
          write: editRole ? permissions["hackerboard"].write : false,
          delete: editRole ? permissions["hackerboard"].delete : false,
        },
        {
          name: "Audit Widget",
          read: editRole ? permissions["audit_widget"].read : false,
          write: editRole ? permissions["audit_widget"].write : false,
          delete: editRole ? permissions["audit_widget"].delete : false,
        },
        {
          name: "Headings",
          read: editRole ? permissions["headings"].read : false,
          write: editRole ? permissions["headings"].write : false,
          delete: editRole ? permissions["headings"].delete : false,
        },
        {
          name: "Careers",
          read: editRole ? permissions["careers"].read : false,
          write: editRole ? permissions["careers"].write : false,
          delete: editRole ? permissions["careers"].delete : false,
        },
        {
          name: "Media HighLights",
          read: editRole ? permissions["media_highlights"].read : false,
          write: editRole ? permissions["media_highlights"].write : false,
          delete: editRole ? permissions["media_highlights"].delete : false,
        },
        {
          name: "QA Docs",
          read: editRole ? permissions["docs"].read : false,
          write: editRole ? permissions["docs"].write : false,
          delete: editRole ? permissions["docs"].delete : false,
        }
      ]
    },
    onSubmit: (values) => {
      console.log("Dispatching")

      if (editRole) {
        formik.values.name = rollname
        dispatch(createRoleStart({ ...values, rollID }))
      }
      else dispatch(createRoleStart(values))

    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="card-body">
        {!editRole && <>
          <label htmlFor="role-name" className="form-label">
            Enter Name of Role
          </label>
          <input
            id="name"
            type="text"
            name="name"
            className="form-control"
            placeholder="Role..."
            value={formik.values.name}
            onChange={formik.handleChange}
          /></>}
        <p className='py-3'>Set Permissions</p>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Audit"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>
            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[0].read}
                name="permissions[0].read"
                checked={formik.values.permissions[0].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[0].write}
                name="permissions[0].write"
                checked={formik.values.permissions[0].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[0].delete}
                name="permissions[0].delete"
                checked={formik.values.permissions[0].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"API"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[1].read}
                name="permissions[1].read"
                checked={formik.values.permissions[1].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[1].write}
                name="permissions[1].write"
                checked={formik.values.permissions[1].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[1].delete}
                name="permissions[1].delete"
                checked={formik.values.permissions[1].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Quill Relay"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[2].read}
                name="permissions[2].read"
                checked={formik.values.permissions[2].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[2].write}
                name="permissions[2].write"
                checked={formik.values.permissions[2].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[2].delete}
                name="permissions[2].delete"
                checked={formik.values.permissions[2].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Smart Contract"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[3].read}
                name="permissions[3].read"
                checked={formik.values.permissions[3].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[3].write}
                name="permissions[3].write"
                checked={formik.values.permissions[3].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[3].delete}
                name="permissions[3].delete"
                checked={formik.values.permissions[3].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Security Check"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[4].read}
                name="permissions[4].read"
                checked={formik.values.permissions[4].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[4].write}
                name="permissions[4].write"
                checked={formik.values.permissions[4].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[4].delete}
                name="permissions[4].delete"
                checked={formik.values.permissions[4].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Quote Listing"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[5].read}
                name="permissions[5].read"
                checked={formik.values.permissions[5].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[5].write}
                name="permissions[5].write"
                checked={formik.values.permissions[5].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[5].delete}
                name="permissions[5].delete"
                checked={formik.values.permissions[5].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Static Data"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[6].read}
                name="permissions[6].read"
                checked={formik.values.permissions[6].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[6].write}
                name="permissions[6].write"
                checked={formik.values.permissions[6].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[6].delete}
                name="permissions[6].delete"
                checked={formik.values.permissions[6].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Hackerboard"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[7].read}
                name="permissions[7].read"
                checked={formik.values.permissions[7].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[7].write}
                name="permissions[7].write"
                checked={formik.values.permissions[7].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[7].delete}
                name="permissions[7].delete"
                checked={formik.values.permissions[7].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Permission
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Audit Widget"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[8].read}
                name="permissions[8].read"
                checked={formik.values.permissions[8].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[8].write}
                name="permissions[8].write"
                checked={formik.values.permissions[8].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[8].delete}
                name="permissions[8].delete"
                checked={formik.values.permissions[8].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Headings
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Headings"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[9].read}
                name="permissions[9].read"
                checked={formik.values.permissions[9].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[9].write}
                name="permissions[9].write"
                checked={formik.values.permissions[9].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[9].delete}
                name="permissions[9].delete"
                checked={formik.values.permissions[9].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Careers
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"Careers"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[10].read}
                name="permissions[10].read"
                checked={formik.values.permissions[10].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[10].write}
                name="permissions[10].write"
                checked={formik.values.permissions[10].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[10].delete}
                name="permissions[10].delete"
                checked={formik.values.permissions[10].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                Media Highlights
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"media_highlights"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[11].read}
                name="permissions[11].read"
                checked={formik.values.permissions[11].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[11].write}
                name="permissions[11].write"
                checked={formik.values.permissions[11].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[11].delete}
                name="permissions[11].delete"
                checked={formik.values.permissions[11].delete}
              />
            </div>
          </div>
        </div>
        <div className="form-group row align-items-end">
          <div className="col-md-4">
            <div className="custom-form">
              <label htmlFor="createdFor" className="form-label">
                QA Docs
              </label>
              <input
                type="text"
                className="form-control"
                name="text"
                value={"docs"}
                required
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Read
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[12].read}
                name="permissions[11].read"
                checked={formik.values.permissions[12].read}
              />
            </div>
          </div>
          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Write
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[12].write}
                name="permissions[11].write"
                checked={formik.values.permissions[12].write}
              />
            </div>
          </div>

          <div className="custom-form col-md-2 d-flex flex-column">
            <label htmlFor="password" className="form-label align-self-start">
              Delete
            </label>

            <div className="ml-1">
              <input
                type="checkbox"
                onChange={formik.handleChange}
                value={formik.values.permissions[12].delete}
                name="permissions[11].delete"
                checked={formik.values.permissions[12].delete}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <button className="btn btn-outline-primary btn-100 mx-2" type='submit'> Save </button>
          <button className="btn btn-outline-primary btn-100 mx-2" onClick={() => window.location.reload()}> Cancel </button>
        </div>
      </div>
    </form>
  );
}

export default NewRole;