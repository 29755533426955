import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { SuccessToast, ErrorToast } from '../../components/ToastMessage/SuccessToast';

function AddImage(props) {
  const [bucket, setBucket] = useState('QuillAudit');
  const [imagename, setImageName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [hackImgUrl, setHackImgUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const handleClick = () => {
    document.getElementById('file-input').click();
  };

  const handleImageUpload = (event) => {
    setImageName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    setImageName('');
  }, [props.id]);

  useEffect(() => {
    const postImageUrl = async () => {
      try {
        const response = await axios.post(
          `${base_url}/api/v1/erp_audit/hackerboard/add_img?_id=${props.Hackid}`,
          { img_url: hackImgUrl },
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (response) {
          toast.success(<SuccessToast message="Image uploaded successfully" />)
          window.location.reload();
        }
      } catch (error) {
        toast.error(<ErrorToast message="Failed to upload the Image" />)
        window.location.reload();
        console.error(error);
      } finally {
        setIsUploading(false);
      }
    };

    if (hackImgUrl !== '') {
      postImageUrl();
      setImageName('');
      setHackImgUrl('');
    }
  }, [hackImgUrl, props.Hackid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const type = bucket === 'QuillAudit' ? 'audit' : 'quill';
    const formData = new FormData();
    formData.append('image', selectedFile);
    setIsUploading(true);
    axios
      .put(
        `${base_url
        }/api/v1/erp_audit/admin/upload/${type.toLowerCase()}${bucket === 'QuillAudit' ? 'audit' : 'website'
        }`,
        formData,
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        setHackImgUrl(res.data.url);
        setIsUploading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsUploading(false);
      });
  };

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="input-group custom-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text bg-primary text-white"
                onClick={() => handleClick()}
              >
                <img src="/images/add-file.svg" alt="addfile " />
              </span>
            </div>
            <input
              type="text"
              required
              value={imagename}
              className="form-control"
              onClick={() => handleClick()}
              style={{ maxWidth: '200px' }}
            />
            <input
              type="file"
              required
              onChange={handleImageUpload}
              className="form-control"
              style={{ display: 'none' }}
              id="file-input"
            />
            <div className="input-group-append ml-1">
              <button
                className="btn "
                type="button"
                id="button-addon2"
                type="submit"
              >
                {!isUploading ? (
                  'Upload'
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddImage;
