import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup/Popup";
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";

function Testimonials() {
  const [isNew, setIsNew] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleNewTestimonial = () => {
    setIsNew((prev) => !prev);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleEdit = () => {
    togglePopup();
  };
  return (
    <>
      <Header
        title="Testimonials"
        btntitle="New Testimonial"
        newbtnclick={toggleNewTestimonial}
        isnew={isNew}
      />
      <div className="content-scroll">
        {isNew && (
          <TestimonialCard isnew={isNew} onclose={toggleNewTestimonial} />
        )}
        {Array.from({ length: 2 }).map((item, i) => (
          <TestimonialCard key={i} onedit={handleEdit} />
        ))}
      </div>
      {showPopup && (
        <Popup size={"xl"} onclose={togglePopup}>
          <TestimonialCard onedit={handleEdit} showpopup={showPopup} />
        </Popup>
      )}
    </>
  );
}

export default Testimonials;
